import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Education: "",
    Course:"",
    Employed_In: "",
    Work_Details: "",
    Monthly_Income: "",
    Languages_Known:"",
    
}

export const professionalSlice = createSlice({
    name: 'professionalInfo',
    initialState,
    reducers:{
        setEducation: (state,action)=>{
            state.Education = action.payload;
        },
        setCourse: (state,action)=>{
            state.Course = action.payload;
        },
        setEmployed_In:(state,action)=>{
           state.Employed_In = action.payload;
        },
        setWork_Details: (state,action)=>{
            state.Work_Details = action.payload;
        },
        setMonthly_Income: (state,action)=>{
            state.Monthly_Income = action.payload;
        },
        setLanguages_Known: (state,action)=>{
            state.Languages_Known = action.payload;
        },
    }
});

export const {setEducation,setCourse,setEmployed_In,setWork_Details,setMonthly_Income,setLanguages_Known}=professionalSlice.actions;
export default professionalSlice.reducer;

