import React,{useState} from "react";
import { useSelector,useDispatch } from "react-redux";
import { setName,setAge,setMarital_Status,setMother_Tongue,setProfile_Created_By,setGender,setProfile_Image} from "../slices/basicSlice";
import axios from "axios";
import marriage from "../assets/mrg.jpeg";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { useTranslation } from "react-i18next";

const BasicDetails = () => {

const {Name,Age,Gender,Mother_Tongue,Marital_Status,Profile_Created_By,Profile_Image}=useSelector((state)=>state.basicInfo);
const dispatch = useDispatch();
const navigate = useNavigate();
const [errors, setErrors] = useState({});
const {t}= useTranslation();


  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});

    const newErrors = {};

    if (!Name.trim()) {
      newErrors.Name = "Name is Required";
    }
    if (!Age.trim()) {
      newErrors.Age = "Age is Required";
    }
    if (!Gender) {
      newErrors.Gender = "Gender is required.";
    }
    if (!Marital_Status || Marital_Status === "select") {
      newErrors.Marital_Status = "Marital Status is required.";
    }
    if (!Profile_Created_By || Profile_Created_By === "select") {
      newErrors.Profile_Created_By = "Profile Created By is required.";
    }
    if (!Mother_Tongue || Mother_Tongue === "select") {
      newErrors.Mother_Tongue = "Mother Tongue is required";
    }
    if (!Profile_Image || Profile_Image === "select") {
      newErrors.Profile_Image = "Profile Image is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      const payload = {Name,Age,Gender,Mother_Tongue,Marital_Status,Profile_Created_By,Profile_Image};

      const response = await axios.post(
        "http://localhost:8000/basicDetails",
        payload
      );
      console.log("Response:", response.data);
      navigate('/personalDetails');
      dispatch(setName(""));
      dispatch(setAge(""));
      dispatch(setMother_Tongue(""));
      dispatch(setMarital_Status(""));
      dispatch(setProfile_Created_By(""));
      dispatch(setProfile_Image(""));

    } catch (error) {
      console.error("Error:", error);
      alert('Form submission failed');
    }
  };

  return (
    <>

<Navbar/>
        <div
        className="form-container vh-100 col-md-12"
        style={{
          backgroundImage: `url(${marriage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
   
 
        <form className="form w-80 p-5" onSubmit={handleSubmit}>
          <h4 className="heading bg-danger mb-4">Basic Details</h4>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="name" className="col-md-4 col-lg-4 col-sm-4">
              {t('Name')}
            </label>
            <input
              type="text"
              placeholder="Enter your Name"
              className="w-100"
              name="Name"
              id="name"
              value={Name}
              onChange={(e)=>dispatch(setName(e.target.value))}
            />
            {errors.Name && <div style={{ color: "red" }}>{errors.Name}</div>}
          </div>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Age" className="col-md-4 col-lg-4 col-sm-4">
              {t('Age')}
            </label>
            <input
              type="number"
              placeholder="Enter your Age"
              className="w-100"
              name="Age"
              id="Age"
              value={Age}
              onChange={(e)=>dispatch(setAge(e.target.value))}
            />
            {errors.Age && <div style={{ color: "red" }}>{errors.Age}</div>}
          </div>
          <div className="form-group mb-3 col-xs-1">
            <label
              htmlFor="Gender"
              className="col-md-4 col-lg-4 col-sm-4 w-100 mb-1"
            >
              {t('Gender')}
            </label>
            <div className="d-flex">
              <div>
                <input
                  type="radio"
                  className="w-40 male"
                  name="Gender"
                  id="GenderMale"
                  value="male"
                  checked={Gender === "male"}
                  onChange={(e)=>dispatch(setGender(e.target.value))}
                  />
                {t('Male')}
              </div>
              <div className="seperator"></div>
              <div></div>
              <input
                type="radio"
                className="w-40 female"
                name="Gender"
                id="GenderFemale"
                value="female"
                checked={Gender === "female"}
                onChange={(e)=>dispatch(setGender(e.target.value))}
                />
              {t('Female')}
            </div>
            {errors.Gender && (
              <div style={{ color: "red" }}>{errors.Gender}</div>
            )}
          </div>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Mother_Tongue" className="w-100">
              {t('Mother Tongue')}
            </label>
            <select
              name="Mother_Tongue"
              id="Mother_Tongue"
              className="w-100 p-1"
              value={Mother_Tongue}
              onChange={(e)=>dispatch(setMother_Tongue(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Tamil">{t('Tamil')}</option>
              <option value="English">{t('English')}</option>
              <option value="Telugu">Telugu</option>
            </select>
            {errors.Mother_Tongue && (
              <div style={{ color: "red" }}>{errors.Mother_Tongue}</div>
            )}
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Marital_Status" className="w-100">
              {t('Marital Status')}
            </label>
            <select
              name="Marital_Status"
              id="Marital_Status"
              className="w-100 p-1"
              value={Marital_Status}
              onChange={(e)=>dispatch(setMarital_Status(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="UnMarried">{t('UnMarried')}</option>
              <option value="Widow">{t('Widow')}</option>
              <option value="Divorced">{t('Divorced')}</option>
              <option value="Awaiting Divorce">{t('Awaiting Divorce')}</option>
            </select>
            {errors.Marital_Status && (
              <div style={{ color: "red" }}>{errors.Marital_Status}</div>
            )}
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Profile_Created_By" className="w-100">
              {t('Profile Created By')}
            </label>
            <select
              name="Profile_Created_By"
              id="Profile_Created_By"
              className="w-100 p-1"
              value={Profile_Created_By}
              onChange={(e)=>dispatch(setProfile_Created_By(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Self">{t('Self')}</option>
              <option value="Parent">{t('Parent')}</option>
              <option value="Sister">{t('Sister')}</option>
              <option value="Brother">{t('Brother')}</option>
              <option value="Relative">{t('Relative')}</option>
              <option value="Friend">{t('Friend')}</option>
            </select>
            {errors.Profile_Created_By && (
              <div style={{ color: "red" }}>{errors.Profile_Created_By}</div>
            )}
          </div>

          <button
            type="submit"
            name="submit-btn"
            className="btn btn-danger w-100"
          >
            {t('Next')}
          </button>
        </form>
        </div>
    </>
  );
};

export default BasicDetails;
