import React, { useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'bootstrap/dist/css/bootstrap.min.css';
// import marriage from '../assets/mrg.jpeg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ImageStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover', 
  width: '24rem', 
  height: '23rem'
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-prev-arrow`}
      style={{
        ...style,
        display: "block",
        left: "10px",
        zIndex: 1000,
        fontSize: "24px",
        backgroundColor: "#dc3545",
        color: "white",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        lineHeight: "40px",
        textAlign: "center",
      }}
      onClick={onClick}
    >
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-next-arrow`}
      style={{
        ...style,
        display: "block",
        right: "10px",
        zIndex: 1000,
        fontSize: "24px",
        backgroundColor: "#dc3545",
        color: "white",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        lineHeight: "40px",
        textAlign: "center",
      }}
      onClick={onClick}
    >
    </div>
  );
};


const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
  const AdvancedSearch =()=>{

  const [Name, setName] = useState("");
  const [ageMin, setAgeMin] = useState('');
  const [ageMax, setAgeMax] = useState('');
  const [Body_Type, setBody_Type] = useState("");
  const [Physical_Status, setPhysical_Status] = useState("");
  const [Marital_Status, setMarital_Status] = useState("");
  const [Skin_Colour, setSkin_Colour] = useState("");
  const [Sub_Caste,setSub_Caste]=useState("");
  const [Sub_Caste_Type,setSub_Caste_Type]=useState("");
  const [Kuladeivam,setKuladeivam]=useState("");
  const [Education, setEducation] = useState("");
  const [Employed_In, setEmployed_In] = useState("");
  const [Work_Place, setWork_Place] = useState("");
  const [Country, setCountry] = useState("");
  const [State, setState] = useState("");
  const [District, setDistrict] = useState("");
  const [Lagnam,setLagnam]=useState("");
  const [Rasi,setRasi]=useState("");
  const [Nakshatra,setNakshatra]=useState("");
  const [AdvancedData,setAdvancedData]=useState([]);
  const navigate = useNavigate();
  const {t} = useTranslation();


  const fetchAdvancedData = async () =>{
    try{
     const response = await axios.get('http://localhost:8000/GetByAdvancedSearch',{
       params:{
         p_Name:Name,
         p_Age_Start:ageMin,
         p_Age_End:ageMax,
         p_Marital_Status:Marital_Status,
         p_Physical_Status:Physical_Status,
         p_Body_Type:Body_Type, 
         p_Skin_Colour: Skin_Colour,
         p_Sub_Caste: Sub_Caste,                 
         p_Sub_Caste_Type:Sub_Caste_Type,
         p_Education: Education, 
         p_Employed_In: Employed_In,
         p_Kuladeivam:Kuladeivam ,
         p_Country:Country,
         p_State:State , 
         p_District: District,
         p_Rasi: Rasi,
         p_Nakshatra:Nakshatra,
         p_Lagnam:Lagnam,}
     });
     console.log('Fetched Data:', response.data);
     const FetchedAdvancedData = response.data;
     setAdvancedData(FetchedAdvancedData);
 
 
    }
    catch (error){
     console.error("There was an error fetching the data!")
    }
 
   }

   const handleProfileInQuickSearch = (values) => {
    navigate(`/searchProfileDetails/${values.ID}`, { state: { user: values, searchResults: AdvancedData } });
  };


  //  useEffect(() => {
  //   if (initialData) {
  //     setAdvancedData(initialData);
  //   }
  // }, [initialData]);
   
  const handleAdvancedSearch = (e)=>{
    e.preventDefault();
    fetchAdvancedData();
    setName('');
    setAgeMax('');
    setAgeMin('');
    setMarital_Status('');
    setBody_Type('');
    setPhysical_Status('');
    setSkin_Colour('');
    setSub_Caste('');
    setSub_Caste_Type('');
    setEducation('');
    setEmployed_In('');
    setCountry('');
    setState('');
    setDistrict('');
    setRasi('');
    setNakshatra('');
    setKuladeivam('');
    setLagnam('');
  }
<button onClick={() => navigate('/search', { state: { searchResults: AdvancedData } })}>
  Back to Search
</button>

  return(
    <>
              <form onSubmit={handleAdvancedSearch}>
  <h4 className="heading bg-danger">{t('Personal Details')}</h4>
  <div className="row">
    <div className="col-sm-6 mb-3">
      <label className="form-label">{t('Age')} {t('From')}:</label>
      <input
        type="number"
        value={ageMin}
        placeholder='Age From'
        className="w-100 p-1"
        name="age_from"
        onChange={(e) => setAgeMin(e.target.value)}
      />
    </div>
    <div className="col-sm-6 mb-3">
      <label className="form-label">{t('Age')} {t('To')}:</label>
      <input
        type="number"
        value={ageMax}
        placeholder='Age To'
        className="w-100 p-1"
        name="age_to"
        onChange={(e) => setAgeMax(e.target.value)}
      />
    </div>
  </div>

  <div className="row">
    <div className="col-sm-6 mb-3">
      <label htmlFor="Marital_Status" className="form-label">
        {t('Marital Status')}
      </label>
      <select
        name="Marital_Status"
        className="w-100 p-1"
        value={Marital_Status}
        onChange={(e) => setMarital_Status(e.target.value)}
        required
      >
        <option value="select">Select--</option>
        <option value="unMarried">{t('UnMarried')}</option>
        <option value="widow">Widow</option>
        <option value="divorced">Divorced</option>
        <option value="awaiting">Awaiting Divorce</option>
      </select>
    </div>

    <div className='col-sm-6 mb-3'>
    <label htmlFor="Body_Type" className="form-label">
        {t('Body Type')}:
      </label>
      <select
        name="Body_Type"
        id="Body_Type"
        className="w-100 p-1"
        value={Body_Type}
        onChange={(e) => setBody_Type(e.target.value)}
        required
      >
        <option value="select">Select--</option>
        <option value="thin">Thin</option>
        <option value="normal">Normal</option>
        <option value="fat">Fat</option>
        <option value="slightlyThin">Slightly Thin</option>
        <option value="slightlyFat">Slightly Fat</option>
      </select>
    </div>
  </div>

  <div className="row">
   

    <div className="col-sm-6 mb-3">

            <label htmlFor="Physical_Status" className="w-100">
              {t('Physical Status')}
            </label>
            <select
              name="Physical_Status"
              id="Physical_Status"
              className="w-100 p-1"
              value={Physical_Status}
              onChange={(e) => setPhysical_Status(e.target.value)}
              required
            >
              <option value="select">Select--</option>
              <option value="normal">Normal</option>
              <option value="physicallyChallenged">
                Physically Challenged
              </option>
            </select>
              </div>

              <div className="col-sm-6 mb-3">
              <label htmlFor="Skin_Colour" className="w-100">
              {t('Skin Colour')}
            </label>
            <select
              name="Skin_Colour"
              id="Skin_Colour"
              className="w-100 p-1"
              value={Skin_Colour}
              onChange={(e) => setSkin_Colour(e.target.value)}
              required
            >
              <option value="select">Select--</option>
              <option value="fair">Fair</option>
              <option value="white">White</option>
              <option value="wheatishbrown">Wheatish Brown</option>
              <option value="dark">dark</option>
              <option value="dusky">Dusky</option>
            </select>
      </div>
  </div>

  <h4 className="heading bg-danger">{t('Religious, Education and Job Details')}</h4>


<div className='row'>
  <div className='col-sm-6 mb-3'>
  <label htmlFor="Sub_Caste" className="w-100">
              {t('Sub Sect')}
            </label>
            <select
              name="Sub_Caste"
              id="Sub_Caste"
              className="w-100 p-1"
              value={Sub_Caste}
              onChange={(e) => setSub_Caste(e.target.value)}
              required
            >
              <option value="select">Select--</option>
              <option value="Kootam"> Kootam </option>
              <option value="Kulam"> Kulam</option>
              <option value="Gothram">Gothram</option>
            </select>
    </div>
    <div className='col-sm-6 mb-3'>
    {Sub_Caste === "Kootam" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Sub_Caste" className="w-100">
                {Sub_Caste}
              </label>
              <select
                name="Sub_Caste_Type"
                id="Sub_Caste_Type"
                className="w-100 p-1"
                value={Sub_Caste_Type}
                onChange={(e) => setSub_Caste_Type(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Kothukaatan_Kootam">Kothukaatan Kootam </option>
                <option value="Kannimaar_Kootam">Kannimaar Kootam</option>
                <option value="Thadiveeran_Kootam">Thadiveeran Kootam</option>
                <option value="KottaiAnnan_Kootam">KottaiAnnan Kootam</option>
                <option value="Pattakaara_Kootam">Pattakaara Kootam</option>
                <option value="KottaiMaari_Kootam">KottaiMaari Kootam</option>
                <option value="Undikaarar_Kootam">Undikaarar Kootam</option>
                <option value="OyaaMaari_Kootam">OyaaMaari Kootam</option>
                <option value="Poomudhali_Kootam">Poomudhali Kootam</option>
                <option value="ThavudhraMudhali_Kootam">
                  ThavudhraMudhali Kootam
                </option>
                <option value="ThaandavaMudhali_Kootam">
                  ThaandavaMudhali Kootam
                </option>
                <option value="ChidambaraMudhali_Kootam">
                  ChidambaraMudhali Kootam
                </option>
                <option value="NaagaMudhali_Kootam">NaagaMudhali Kootam</option>
                <option value="Ellamma_Kootam">Ellamma Kootam</option>
                <option value="ThattaiNaatuThadimaaran_Kootam">
                  ThattaiNaatuThadimaaran Kootam
                </option>
                <option value="Erumaikaarar_Kootam">Erumaikaarar Kootam</option>
                <option value="TheerthaMudhali_Kootam">
                  TheerthaMudhali Kootam
                </option>
                <option value="Alangarang_Kootam">Alangarang Kootam</option>
                <option value="Sokkanaadhan_Kootam">Sokkanaadhan Kootam</option>
                <option value="Aandi_Kootam">Aandi Kootam</option>
                <option value="Appaai_Aravaai_Kootam">
                  Appaai Aravaai Kootam
                </option>
                <option value="Rathnagiri_Kootam">Rathnagiri Kootam</option>
                <option value="Sandhiyappar_Kootam">Sandhiyappar Kootam</option>
                <option value="Thambiannan_Kootam">Thambiannan Kootam</option>
                <option value="Paramashivam_Kootam">Paramashivam Kootam</option>
                <option value="SadaThevar_Kootam">SadaThevar Kootam</option>
                <option value="ChozhaMudhali_Kootam">
                  ChozhaMudhali Kootam
                </option>
                <option value="Pomparappi_Kootam">Pomparappi Kootam</option>
                <option value="Chettisaai_Kootam">Chettisaai Kootam</option>
              </select>
              
            </div>
          )}
          {Sub_Caste === "Gothram" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Sub_Caste" className="w-100">
                {Sub_Caste}
              </label>
              <select
                name="Sub_Caste_Type"
                id="Sub_Caste_Type"
                className="w-100 p-1"
                value={Sub_Caste_Type}
                onChange={(e) => setSub_Caste_Type(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Vetrivel_Gothram">Vetrivel Gothram </option>
                <option value="Veeravel_Gothram">Veeravel Gothram</option>
              </select>
            
            </div>
          )}

          {Sub_Caste === "Kulam" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Sub_Caste" className="w-100">
                {Sub_Caste}
              </label>
              <select
                name="Sub_Caste_Type"
                id="Sub_Caste_Type"
                className="w-100 p-1"
                value={Sub_Caste_Type}
                onChange={(e) => setSub_Caste_Type(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Kulam_1">Kulam 1</option>
                <option value="Kulam_2">Kulam 2</option>
              </select>
            </div>
          )}

    </div>
    </div>
  <div className='row'>
    <div className='col-sm-6 mb-3'>
    <label htmlFor="Kuladeivam" className="w-100">
              {t('Kuladeivam')}
            </label>
            <select
              name="Kuladeivam"
              id="Kuladeivam"
              className="w-100 p-1"
              value={Kuladeivam}
              onChange={(e) => setKuladeivam(e.target.value)}
              required
            >
              <option value="select">Select--</option>
              <option value="Munniyappan">Munniyappan</option>
              <option value="Karuparayan">Karuparayan</option>
              <option value="Angaalamman">Angaalamman</option>
              <option value="Periyaandichi">Periyaandichi</option>
              <option value="Kannimar">Kannimar</option>
            </select>
</div>
<div className='col-sm-6 mb-3'>
    <label htmlFor="Education" className="w-100">
              {t('Education')}
            </label>
            <select
              name="Education"
              id="Education"
              className="w-100 p-1"
              value={Education}
              onChange={(e) => setEducation(e.target.value)}
              required
            >
              <option value="select">Select--</option>
              <option value="No_Education">No Education</option>
              <option value="Below_10th">Below 10th</option>
              <option value="10th">10th</option>
              <option value="12th">12th</option>
              <option value="Diploma_or_ITI">Diploma or ITI</option>
              <option value="Under_Graduate">Under Graduate</option>
              <option value="Post_Graduate">Post Graduate</option>
              <option value="Medical">Medical</option>
              <option value="Engineering">Engineering</option>
              <option value="Doctor">Doctor</option>
              <option value="Others">Others</option>
            </select>
    </div>
    <div className='col-sm-6 mb-3'>
    <label htmlFor="Employed_In" className="w-100">
              {t('Employed In')}
            </label>
            <select
              name="Employed_In"
              id="Employed_In"
              className="w-100 p-1"
              value={Employed_In}
              onChange={(e) => setEmployed_In(e.target.value)}
              required
            >
              <option value="select">Select--</option>
              <option value="Agriculture">Agriculture</option>
              <option value="Doctor"> Doctor</option>
              <option value="Government_Job">Government Job</option>
              <option value="Software">Software</option>
              <option value="Entrepreneur">Entrepreneur</option>
              <option value="Private_Job">Private Job</option>
              <option value="Studying">Still Studying</option>
              <option value="Not_at_work"> Not at Work</option>
            </select>
</div>
<div className='col-sm-6 mb-3'>
         <label htmlFor="Work_Place" className="w-100">
              {t('Work Place')}
            </label>
            <select
              name="Work_Place"
              id="Work_Place"
              className="w-100 p-1"
              value={Work_Place}
              onChange={(e) => setWork_Place(e.target.value)}
              required
            >
              <option value="select">Select--</option>
              <option value="Native">Native</option>
              <option value="Coimbatore">Coimbatore</option>
              <option value="Bengaluru">Bengaluru</option>
              <option value="Chennai">Chennai</option>
              <option value="Hyderabad">Hyderabad</option>
              <option value="Abroad">Abroad</option>
              <option value="Other_ State">Other State</option>
            </select>
  </div>
  </div>

<div className='row'>
  <div className='col-sm-6 mb-3'>

  </div>

</div>

<h4 className="heading bg-danger">{t('Location Details')}</h4>
<div className='row'>
  <div className='col-sm-4 mb-3'>
  <label htmlFor="Country" className="w-100">
              {t('Country')}
            </label>
            <select
              name="Country"
              id="Country"
              className="w-100 p-1"
              value={Country}
              onChange={(e) => setCountry(e.target.value)}
              required
            >
              <option value="select">Select--</option>
              <option value="India">India </option>
              <option value="AF">Afghanistan</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antarctica">Antarctica</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
            </select>
  </div>
  <div className='col-sm-4 mb-3'>
  <label htmlFor="State" className="w-100">
              {t('State')}
            </label>
            <select
              name="State"
              id="State"
              className="w-100 p-1"
              value={State}
              onChange={(e) => setState(e.target.value)}
              required
            >
              <option value="select">Select--</option>
              <option value="Andhra_Pradesh">Andhra Pradesh</option>
              <option value="Arunachal_Pradesh">Arunachal Pradesh</option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chattisgarh">Chattisgarh</option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal_Pradesh">Himachal Pradesh</option>
              <option value="Jammu_and_Kashmir">Jammu and Kashmir</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Madhya_Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil_Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="Uttar_Pradesh">Uttar Pradesh</option>
              <option value="West_Bengal">West Bengal</option>
              <option value="Andaman_and_Nicobar_Islands">
                Andaman and Nicobar Islands
              </option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="DN">Daman and Diu</option>
              <option value="Delhi">Delhi</option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Puducherry">Puducherry</option>
            </select>
    </div>
    <div className='col-sm-4 mb-3'>
    <label htmlFor="District" className="w-100">
            {t('District')}
          </label>
          <select
              name="District"
              id="District"
              className="w-100 p-1"
              value={District}
              onChange={(e) => setDistrict(e.target.value)}
              required
            >
              <option value="select">Select--</option>
              <option value="Ariyalur">Ariyalur</option>
              <option value="Chengalpattu">Chengalpattu</option>
              <option value="Chennai">Chennai</option>
              <option value="Coimbatore">Coimbatore</option>
              <option value="Cuddalore">Cuddalore</option>
              <option value="Dharmapuri">Dharmapuri</option>
              <option value="Dindigul">Dindigul</option>
              <option value="Erode">Erode</option>
              <option value="Kallakurichi">Kallakurichi</option>
              <option value="Kanchipuram">Kanchipuram</option>
              <option value="Kanyakumari">Kanyakumari</option>
              <option value="Karur">Karur</option>
              <option value="Krishnagiri">Krishnagiri</option>
              <option value="Madurai">Madurai</option>
              <option value="Nagapattinam">Nagapattinam</option>
              <option value="Namakkal">Namakkal</option>
              <option value="Nilgiris">Nilgiris</option>
              <option value="Perambalur">Perambalur</option>
              <option value="Pudukkottai">Pudukkottai</option>
              <option value="Ramanathapuram">Ramanathapuram</option>
              <option value="Salem">Salem</option>
              <option value="Sivaganga">Sivaganga</option>
              <option value="Tenkasi">Tenkasi</option>
              <option value="Thanjavur">Thanjavur</option>
              <option value="Theni">Theni</option>
              <option value="Thoothukudi">Thoothukudi</option>
              <option value="Tiruchirappalli">Tiruchirappalli</option>
              <option value="Tirunelveli">Tirunelveli</option>
              <option value="Tirupathur">Tirupathur</option>
              <option value="Tiruppur">Tiruppur</option>
              <option value="Tiruvallur">Tiruvallur</option>
              <option value="Tiruvannamalai">Tiruvannamalai</option>
              <option value="Tiruvarur">Tiruvarur</option>
              <option value="Vellore">Vellore</option>
              <option value="Viluppuram">Viluppuram</option>
              <option value="Virudhunagar">Virudhunagar</option>
            </select>
    </div>
</div>

<h4 className="heading bg-danger">{t('Astro Details')}

</h4>
<div className='row'>
  <div className='col-sm-4 mb-3'>
  <label htmlFor="Lagnam" className="w-100">
              {t('Lagnam')}
            </label>
            <select
              name="Lagnam"
              id="Lagnam"
              className="w-100 p-1"
              value={Lagnam}
              onChange={(e) => setLagnam(e.target.value)}
              required
            >
              <option value="select">Select--</option>
              <option value="Mesham(Aries)">Mesham (Aries)</option>
              <option value="Rishabam(Taurus)">Rishabam (Taurus)</option>
              <option value="Mithunam(Gemini)">Mithunam (Gemini)</option>
              <option value="Kadagam(Cancer)">Kadagam (Cancer)</option>
              <option value="Simmam(Leo)">Simmam (Leo)</option>
              <option value="Kanni(Virgo)">Kanni (Virgo)</option>
              <option value="Thulam(Libra)">Thulam (Libra)</option>
              <option value="Viruchigam(Scorpio)">Viruchigam (Scorpio)</option>
              <option value="Dhanushu(Saggitarius)">
                Dhanushu (Saggitarius)
              </option>
              <option value="Magaram(Capricorn)">Magaram (Capricorn)</option>
              <option value="Kumbam(Aquarius)">Kumbam (Aquarius)</option>
              <option value="Meenam(Pisces)">Meenam (Pisces)</option>
              <option value="dontknow">Don't Know</option>
            </select>
  </div>
  <div className='col-sm-4 mb-3'>  
    <label htmlFor="Rasi" className="w-100">
              {t('Rasi')}
            </label>
            <select
              name="Rasi"
              id="Rasi"
              className="w-100 p-1"
              value={Rasi}
              onChange={(e) => setRasi(e.target.value)}
              required
            >
              <option value="select">Select--</option>
              <option value="Aries">Aries-Mesham</option>
              <option value="Taurus">Taurus-Risabham</option>
              <option value="Gemini">Gemini-Mithunam</option>
              <option value="Cancer">Cancer-Kadagam</option>
              <option value="Leo">Leo-Simmam</option>
              <option value="Virgo">Virgo-Kanni</option>
              <option value="Libra">Libra-Thulam</option>
              <option value="Scorpio">Scorpio-Viruchigam</option>
              <option value="Sagittarius">Sagittarius-Dhanushu</option>
              <option value="Capricorn">Capricorn-Magaram</option>
              <option value="Aquarius">Aquarius-Kumbham</option>
              <option value="Pisces">Pisces-Meenam</option>
              <option value="dontknow">Don't Know</option>
            </select></div>
            <div className='col-sm-4 mb-3'>
            {Rasi === "Aries" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
                Nakshatra
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) => setNakshatra(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Ashwini">Ashwini</option>
                <option value="Bharani">Bharani</option>
                <option value="Krithigai">Krithigai</option>
              </select>
            </div>
          )}
            {Rasi === "Taurus" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
                Nakshatra
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) => setNakshatra(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Krithigai">Krithigai</option>
                <option value="Rohini">Rohini</option>
                <option value="Mirugasrisham">Mirugasrisham</option>
              </select>
            </div>
          )}

{Rasi === "Gemini" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
                Nakshatra
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) => setNakshatra(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Mirugasrisham">Mirugasrisham</option>
                <option value="Thiruvathirai">Thiruvathirai</option>
                <option value="Punarpoosam">Punarpoosam</option>
              </select>
            </div>
          )}

          {Rasi === "Cancer" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
                Nakshatra
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) => setNakshatra(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Punarpoosam">Punarpoosam</option>
                <option value="Poosam">Poosam</option>
                <option value="Ayilyam">Ayilyam</option>
              </select>
            </div>
          )}
          {Rasi === "Leo" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
                Nakshatra
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) => setNakshatra(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Magam">Magam</option>
                <option value="Pooram">Pooram</option>
                <option value="Uthiram">Uthiram</option>
              </select>
            </div>
          )}
          {Rasi === "Virgo" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
                Nakshatra
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) => setNakshatra(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Uthiram">Uthiram</option>
                <option value="Hashtham">Hashtham</option>
                <option value="Chithirai">Chithirai</option>
              </select>
            </div>
          )}

          {Rasi === "Libra" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
                Nakshatra
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) => setNakshatra(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Chithirai">Chithirai</option>
                <option value="Swathi">Swathi</option>
                <option value="Visakam">Visakam</option>
              </select>
            </div>
          )}
          {Rasi === "Scorpio" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
                Nakshatra
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) => setNakshatra(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Visakam">Visakam</option>
                <option value="Anusham">Anusham</option>
                <option
                  value="
                Kettai"
                >
                  Kettai
                </option>
              </select>
            </div>
          )}

          {Rasi === "Sagittarius" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
                Nakshatra
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) => setNakshatra(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Moolam">Moolam</option>
                <option value="Pooradam">Pooradam</option>
                <option value="Uthiradam">Uthiradam</option>
              </select>
            </div>
          )}
          {Rasi === "Capricorn" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
                Nakshatra
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) => setNakshatra(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Uthiradam">Uthiradam</option>
                <option value="Thiruvonam">Thiruvonam</option>
                <option value="Avittam">Avittam</option>
              </select>
            </div>
          )}
          {Rasi === "Aquarius" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
                Nakshatra
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) => setNakshatra(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Sathayam">Sathayam</option>
                <option value="Poorattadhi">Poorattadhi</option>
                <option value="Avittam">Avittam</option>
              </select>
            </div>
          )}
          {Rasi === "Pisces" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
                Nakshatra
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) => setNakshatra(e.target.value)}
                required
              >
                <option value="select">Select--</option>
                <option value="Uthiratadhi">Uthiratadhi</option>
                <option value="Poorattadhi">Poorattadhi</option>
                <option value="Revathi">Revathi</option>
              </select>
            </div>
          )}
            </div>

  </div>
  <button type="submit" className="btn btn-success">{t('Search')}</button>
</form>


<div>
        {AdvancedData.length > 0 && (
          AdvancedData.length > 3 ? (
            <div className="carousel-container mt-4">
              <Slider {...settings}>
                {AdvancedData.map((values) => (
                  <div key={values.ID} className="card mycard">
                    <img className="card-img-top" src={values.Profile_Image} alt="Card" style={ImageStyle}/>
                     <div className="card-body text-center">
                      <h1 className="card-title" style={{ fontSize: '23px' }}>{t('Name')}: <span style={{ color: "red" }}>{values.Name}</span></h1>
                    <p className="card-text">{t('Gender')}: {values.Gender}</p>
                      <p className='card-text'>{t('Age')}: {values.Age}</p>
                      <button className='btn btn-danger' onClick={() => handleProfileInQuickSearch(values)}>{t('Profile')}</button>

                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <div className="card-container mycards mt-4">
              {AdvancedData.map((values) => (
                <div key={values.ID} className="card mycard">
                  <img className="card-img-top" src={values.Profile_Image} alt="Card" style={ImageStyle}/>
                  <div className="card-body text-center">
                    <h1 className="card-title" style={{ fontSize: '23px' }}>{t('Name')}: <span style={{ color: "red" }}>{values.Name}</span></h1>
                    <p className="card-text">{t('Gender')}: {values.Gender}</p>
                    <p className='card-text'>{t('Age')}: {values.Age}</p>
                    <button className='btn btn-danger' onClick={() => handleProfileInQuickSearch(values)}>{t('Profile')}</button>
                    </div>
                </div>
              ))}
            </div>
          )
        )}
      </div>
    </>
  )
  }
  export default AdvancedSearch;
  