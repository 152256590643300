import React, { useState } from "react";
import marriage from "../assets/mrg.jpeg";
import { useSelector, useDispatch } from "react-redux";
import { setUsername, setEmail, setPassword } from "../slices/loginSlice";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { useTranslation } from 'react-i18next';
// import ForgotPassword from './ForgotPassword';



const Login = () => {

  const { t } = useTranslation();
  const { username, email, password } = useSelector((state) => state.loginInfo);
  const [localEmail, setLocalEmail] = useState(email); 
  const {signupEmail}=useSelector((state)=>state.signupInfo)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault(); 

    console.log("Handling submit with email:", localEmail); 

    try {
      const params = localEmail ? {email:localEmail}: {email:signupEmail};
      const checkResponse = await axios.get("http://localhost:8000/getbyEmailId", {
        params: { email: localEmail },
      });

      if (checkResponse) {
        const response = await axios.get("http://localhost:8000/getbyEmailId", {
          params: { email: localEmail },
        });
        console.log("Login Details", response.data);

        dispatch(setUsername(''));
        dispatch(setPassword(''));
        dispatch(setEmail(localEmail));
        setLocalEmail('');    
      
        console.log("Navigating to /homepage");
        navigate('/homepage',{state:{loginEmail:localEmail}});
      } else {
        console.error("Email not found or not logged in");
navigate('/signup') }
    } catch (error) {
      console.error("Failed to fetch user or check email!", error.response?.data || error.message);
      navigate('/signup')
    }
  };

  return (
    <>
      <Navbar />
      <div
        className="form-container vh-100 col-md-12"
        style={{
          backgroundImage: `url(${marriage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <form className="form w-80 p-5" onSubmit={handleSubmit}>
          <h4 className="mb-3" style={{color:"rgb(189, 0, 41)"}}>{t('Login into your Account')}</h4>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="username" className="col-md-4 col-lg-4 col-sm-4">{t('Username')}</label>
            <input
              type="text"
              className="w-100"
              name="Username"
              id="Username"
              value={username}
              onChange={(e) => dispatch(setUsername(e.target.value))}
            />
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="email" className="col-md-4 col-lg-4 col-sm-4">{t('Email')}</label>
            <input
              type="email"
              name="email"
              className="w-100"
              id="email"
              value={localEmail}
              onChange={(e) => setLocalEmail(e.target.value)} // Update local state
            />
          </div>

          <div className="form-group mb-4 col-xs-1">
            <label htmlFor="pwd" className="col-md-4 col-lg-4 col-sm-4">{t('Password')}</label>
            <input
              type="password"
              className="w-100"
              name="password"
              id="pwd"
              value={password}
              onChange={(e) => dispatch(setPassword(e.target.value))}
            />
          </div>

          <button type="submit" name="submit-btn" className="btn btn-danger w-100">
            Login
          </button>
          <Link to="/forgotPassword">Forgot Password</Link>
          <h6 className="mt-4">Don't have an Account? <span><Link to = '/signup'>{t('Register')}</Link> </span></h6>
          
          {/* <Route path="/forgot-password" element={<ForgotPassword />}></Route> */}
        </form>
      </div>
    </>
  );
};

export default Login;
