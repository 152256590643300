import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Rasi: "",
    Nakshatra: "",
    Nakshatra_Patham: "",
    Lagnam: "",
    DOB: "",
    Place_of_Birth: "",
    Time_of_Birth:"",
    Birth_Dasa: "",
  
}

export const astroSlice = createSlice({
    name: 'astroInfo',
    initialState,
    reducers:{
        
        setRasi: (state,action)=>{
            state.Rasi = action.payload;
        },
        setNakshatra: (state,action)=>{
            state.Nakshatra = action.payload;
        },
        setNakshatra_Patham:(state,action)=>{
           state.Nakshatra_Patham = action.payload;
        },
        setDOB: (state,action)=>{
            state.DOB = action.payload;
        },
        setPlace_of_Birth: (state,action)=>{
            state.Place_of_Birth = action.payload;
        },
        setTime_of_Birth:(state,action)=>{
           state.Time_of_Birth = action.payload;
        },
        setLagnam:(state,action)=>{
            state.Lagnam = action.payload;
         },
         setBirth_Dasa:(state,action)=>{
            state.Birth_Dasa = action.payload;
         },
    }
});

export const {setRasi,setNakshatra,setNakshatra_Patham,setDOB,setLagnam,setBirth_Dasa,setPlace_of_Birth,setTime_of_Birth}=astroSlice.actions;
export default astroSlice.reducer;

