import React, { useState, useContext, useEffect } from "react";
import { RecoveryContext } from "../App";
import axios from "axios";

export default function OTPinput() {
  const { email, otp, setPage } = useContext(RecoveryContext);
  const [timerCount, setTimer] = useState(90);
  const [OTPinput, setOTPinput] = useState([0, 0, 0, 0]);
  const [disable, setDisable] = useState(true);

  function resendOTP() {
    if (disable) return;
    axios
      .post("http://localhost:8000/send_recovery_email", {
        OTP: otp,
        recipient_email: email,
      })
      .then(() => setDisable(true))
      .then(() => alert("A new OTP has successfully been sent to your email."))
      .then(() => setTimer(60))
      .catch(console.log);
  }

  function verifyOTP() {
    if (parseInt(OTPinput.join("")) === otp) {
      setPage("reset");
      return;
    }
    alert("The code you have entered is not correct, try again or re-send the link");
    return;
  }

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        if (lastTimerCount <= 1) setDisable(false);
        if (lastTimerCount <= 0) return lastTimerCount;
        return lastTimerCount - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [disable]);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="card p-4 shadow-lg" style={{ maxWidth: "500px", width: "100%" }}>
        <div className="text-center">
          <h3 className="font-weight-bold">Email Verification</h3>
          <p className="text-muted">We have sent a code to your email {email}</p>
        </div>

        <form>
          <div className="d-flex justify-content-between">
            <input
              maxLength="1"
              className="form-control text-center mx-2"
              style={{ width: "60px", height: "60px", fontSize: "24px" }}
              type="text"
              onChange={(e) =>
                setOTPinput([e.target.value, OTPinput[1], OTPinput[2], OTPinput[3]])
              }
            />
            <input
              maxLength="1"
              className="form-control text-center mx-2"
              style={{ width: "60px", height: "60px", fontSize: "24px" }}
              type="text"
              onChange={(e) =>
                setOTPinput([OTPinput[0], e.target.value, OTPinput[2], OTPinput[3]])
              }
            />
            <input
              maxLength="1"
              className="form-control text-center mx-2"
              style={{ width: "60px", height: "60px", fontSize: "24px" }}
              type="text"
              onChange={(e) =>
                setOTPinput([OTPinput[0], OTPinput[1], e.target.value, OTPinput[3]])
              }
            />
            <input
              maxLength="1"
              className="form-control text-center mx-2"
              style={{ width: "60px", height: "60px", fontSize: "24px" }}
              type="text"
              onChange={(e) =>
                setOTPinput([OTPinput[0], OTPinput[1], OTPinput[2], e.target.value])
              }
            />
          </div>

          <div className="mt-4">
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={verifyOTP}
            >
              Verify Account
            </button>
          </div>

          <div className="text-center mt-3">
            <p className="text-muted">
              Didn't receive the code?{" "}
              <button
                className="btn btn-link"
                disabled={disable}
                style={{ textDecoration: disable ? "none" : "underline", color: disable ? "gray" : "blue" }}
                onClick={resendOTP}
              >
                {disable ? `Resend OTP in ${timerCount}s` : "Resend OTP"}
              </button>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}
