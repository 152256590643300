// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import signupReducer from '../slices/signupSlice';
import basicReducer from '../slices/basicSlice';
import personalReducer from '../slices/personalSlice';
import religiousReducer from '../slices/religiousSlice';
import professionalReducer from '../slices/professionalSlice';
import locationReducer from '../slices/locationSlice';
import familyReducer from '../slices/familySlice';
import assetReducer from '../slices/assetSlice';
import astroReducer from '../slices/astroSlice';
import expectationReducer from '../slices/expectationSlice';
import loginReducer from '../slices/loginSlice';
import HomepageReducer from '../slices/HomepageSlice';
import getAllReducer from '../slices/getAllSlice'
import { loadState, saveState } from '../utils/localstorage';
// import rootReducer from './reducer';

const persistedState = loadState();

export const store = configureStore({
  reducer: {
    signupInfo: signupReducer,
    basicInfo: basicReducer,
    personalInfo: personalReducer,
    religiousInfo: religiousReducer,
    professionalInfo: professionalReducer,
    locationInfo: locationReducer,
    familyInfo: familyReducer,
    assetInfo: assetReducer,
    astroInfo: astroReducer,
    expectationInfo: expectationReducer,
    loginInfo: loginReducer,
    HomepageInfo: HomepageReducer,
    getAllInfo: getAllReducer,        
  },
  preloadedState: persistedState, 
});

store.subscribe(() => {
  saveState(store.getState());
});

