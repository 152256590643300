import React from 'react'
import { useLocation } from 'react-router-dom';
// import boy from '../assets/boy.jpg';

// import { useSelector,useDispatch } from 'react-redux';
import Navbar from './Navbar';
import { useTranslation } from 'react-i18next';

const ImageStyle = {
  
  objectFit: 'cover', 
  width: '30rem', 
  height: '30rem'
};

const UserDetail = () => {
    const location = useLocation();
    const {user} = location.state || {};    
    // const navigate = useNavigate();
    const {t} = useTranslation();
    console.log('users data',user);    

  return (
    <><Navbar/>         

<div className="profile-container">
<div className="profile-left">
        <img className="card-img-top" src={`data:image/jpeg;base64,${user.Profile_Image}`} alt="Card" style={ImageStyle}/>
        <div className="card-body text-center">
  <p>
    <b className='contents text-center'>{user.Name} {t('profile')}</b>
  </p>
</div>
        </div>
          <div  className="profile-right">
            <div className="card-body">

            <h4 className="headings bg-danger mb-4">{t('Basic Details')}</h4>
            <div className='d-flex'>
              <div className='row'>
              <div className='col-xs-12 col-sm-6'>
              <p>
                <b  className="labels">{t('Name')}:</b>
                <b className='contents' >{user.Name}</b>
              </p>
              </div>
              <div className='col-xs-12 col-sm-6'>
              <p>
                <b className="labels" >{t('Marital Status')}:</b>
                <b  className='contents'>{user.Marital_Status}</b>
              </p>
              </div>
              <div className='row'>

              <div className='col-xs-12 col-sm-6'>
              <b className="labels" >{t('Age')}:</b>
              <b  className='contents'> {user.Age}</b>
              </div>
              
                <div className='col-xs-12 col-sm-6'>
              <p>
              <b className="labels" >{t('Gender')}:</b>
              <b  className='contents'> {user.Gender}</b>

              </p>
                </div>
              </div>
              <div className='row'>
              <div className='col-xs-12 col-sm-6'>
                <p>
                <b className="labels" >{t('Profile Created By')}:</b>
                <b  className='contents'>{user.Profile_Created_By}</b>
              </p>
                </div>

              </div>
                </div>
            </div>
            </div>


            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Personal Details')}</h4>
            <div className='row'>
            <div className='col-xs-12 col-sm-6'>
                  <p><b className="labels" >{t('Physical Status')}:</b>
                  <b  className='contents'> {user.Physical_Status}</b></p>
                </div>
              
                <div className='col-xs-12 col-sm-6'>
                <p>
                <b  className="labels">{t('Height')}:</b>
                <b className='contents' >{user.Height}</b></p>
                </div>
                </div>

                <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  <p>
                  <b className="labels" >{t('Weight')}:</b>
                  <b  className='contents'>{user.Weight}</b>
                  </p>
                </div>
             
               
                <div className='col-xs-12 col-sm-6'><p>
              <b className="labels" >{t('Body Type')}:</b>
              <b  className='contents'> {user.Body_Type}</b></p></div>
              </div>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                <p>
              <b className="labels" >{t('Skin Colour')}:</b>
              <b  className='contents'> {user.Skin_Colour}</b></p>
                </div>
                <div className='col-xs-12 col-sm-6'>
                <p>
              <b className="labels" >{t('Blood Group')}:</b>
              <b  className='contents'> {user.Blood_Group}</b></p>
                </div>
              </div>
 
            </div>

            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Professional Details')}</h4>

            <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                <p>
                <b  className="labels">{t('Degree')}:</b>
                <b className='contents' >{user.Education}</b>
              </p>
                </div>
                <div className='col-xs-12 col-sm-6'>
                <p>
                <b className="labels" >{t('Course')}:</b>
                <b  className='contents'>{user.Course}</b>
              </p>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                <p>
              <b className="labels" >{t('Work Details')}:</b>
              <b  className='contents'> {user.Employed_In}</b>

              </p></div>
              <div className='col-xs-12 col-sm-6'>
              <p>
              <b className="labels" >{t('Work Place')}:</b>
              <b  className='contents'> {user.Work_Place}</b>
              </p>
              </div>
              </div>

              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                <p>
              <b className="labels" >{t('Monthly Income')}:</b>
              <b  className='contents'> {user.Monthly_Income}</b>
              </p>
                </div>
              </div>
  
            </div>


            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Religious Details')}</h4>

            <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                <p>
                <b  className="labels">{t('Sub Caste')}:</b>
                <b className='contents' >{user.Sub_Caste}</b>
              </p>
                </div>
                <div className='col-xs-12 col-sm-6'>
                <p>
                <b className="labels" >{t('Kuladeivam')}:</b>
                <b  className='contents'>{user.Kuladeivam}</b>
              </p>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                <p>
              <b className="labels" >{t('Sub Caste Type')}:</b>
              <b  className='contents'> {user.Sub_Caste_Type  }</b>

              </p>
                </div>
                <div className='col-xs-12 col-sm-6'>  
              <p>
              <b className="labels" >{t('Place')}:</b>
              <b  className='contents'> {user.Place }</b>

              </p></div>
              </div>  
            </div>

            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Location Details')}</h4>

            <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  
              <p>
                <b  className="labels">{t('Country')}: </b>
                <b className='contents' >{user.Country}</b>
              </p>
                </div>
                <div className='col-xs-12 col-sm-6'>
                  
              <p>
                <b className="labels" >{t('State')}:</b>
                <b  className='contents'>{user.State}</b>
              </p>
                </div>
              </div>

              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                <p>
              <b className="labels" >{t('District')}:</b>
              <b  className='contents'> {user.District  }</b>

              </p>
                </div>
              </div>

            </div>



            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Family Details')}</h4>

            <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  
              <p>
                <b  className="labels">{t('Father Status')}: </b>
                <b className='contents' >{user.Father_Status}</b>
              </p>
                </div>
                <div className='col-xs-12 col-sm-6'>
                  
              <p>
                <b className="labels" >{t('Mother Status')}:</b>
                <b  className='contents'>{user.Mother_Status}</b>
              </p>
                </div>
              </div>

              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                <p>
              <b className="labels" >{t('Social Type')}:</b>
              <b  className='contents'> {user.Social_Type}</b>

              </p>
                </div>
                <div className='col-xs-12 col-sm-6'>
                  <p>
                  <b className="labels" >{t('Siblings')}:</b>
                  <b  className='contents'> {user.Sibilings}</b>
                  </p>

                </div>
              </div>

            </div>



            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Assets Details')}</h4>

            <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  
              <p>
                <b  className="labels">{t('Assets Value')}: </b>
                <b className='contents' >{user.Assets_Value}</b>
              </p>
                </div>
                <div className='col-xs-12 col-sm-6'>
                  
              <p>
                <b className="labels" >{t('Asset Details')}:</b>
                <b  className='contents'>{user.Assets_detail}</b>
              </p>
                </div>
              </div>

              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                <p>
              <b className="labels" >{t('Seimurai')}:</b>
              <b  className='contents'> {user.Seimurai}</b>

              </p>
                </div>
              </div>

            </div>


            
            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Astro Details')}</h4>

            <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  
              <p>
                <b  className="labels">{t('Rasi')}: </b>
                <b className='contents' >{user.Rasi}</b>
              </p>
                </div>
                <div className='col-xs-12 col-sm-6'>
                  
              <p>
                <b className="labels" >{t('Nakshatra')}:</b>
                <b  className='contents'>{user.Nakshatra}</b>
              </p>
                </div>
              </div>

              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                <p>
              <b className="labels" >{t('Lagnam')}:</b>
              <b  className='contents'> {user.Lagnam}</b>

              </p>
                </div>
                <div className='col-xs-12 col-sm-6'>
                  
                  <p>
                    <b className="labels" >{t('Date of Birth')}:</b>
                    <b  className='contents'>{user.DOB}</b>
                  </p>
                    </div>
              </div>


              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                <p>
              <b className="labels" >{t('Place of Birth')}:</b>
              <b  className='contents'> {user.Place_of_Birth}</b>

              </p>
                </div>
                {/* <div className='col-xs-12 col-sm-6'>
                  
                  <p>
                    <b className="labels" >{t('Date of Birth')}:</b>
                    <b  className='contents'>{user.DOB}</b>
                  </p>
                    </div> */}
              </div>

            </div>


            
            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Expectation')}</h4>

            <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                  
              <p>
                <b  className="labels">{t('Jathagam')}: </b>
                <b className='contents' >{user.Jathagam}</b>
              </p>
                </div>
                <div className='col-xs-12 col-sm-6'>
              <p>
                <b className="labels" >{t('Marital Status')}:</b>
                <b  className='contents'>{user.Marital_Status}</b>
              </p>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12 col-sm-6'>
                <p>
              <b className="labels" >{t('Work_Place')}:</b>
              <b  className='contents'> {user.Work_Place}</b>
              </p>
                </div>
              </div>
            </div>
          </div>
      </div>





 {/* <button onClick={fetchMyProfile}>My Profile</button> */}
    {/* <div className="card-container myprofileCards">
          <div  className="card myprofileCard" style={{height:'fit-content'}}>
            <img className="card-img-top" src={boy} alt="Card" />
            <div className="card-body">
              <p>
                <b className='contents' >{user.Name} {t('profile')}</b>
              </p>
              
            </div>
          </div>

          <div  className="card myprofileDetails">
            <div className="card-body">

            <h4 className="headings bg-danger mb-4">{t('Basic Details')}</h4>

              <p>
                <b  className="labels">{t('Name')}:</b>
                <b className='contents' >{user.Name}</b>
              </p>
              <p>
                <b className="labels" >{t('Marital Status')}:</b>
                <b  className='contents'>{user.Marital_Status}</b>
              </p>
              
              <p>
                <b className="labels" >{t('Profile Created By')}:</b>
                <b  className='contents'>{user.Profile_Created_By}</b>
              </p>
              <p>
              <b className="labels" >{t('Gender')}:</b>
              <b  className='contents'> {user.Gender}</b>

              </p>
              
            </div>


            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Personal Details')}</h4>

              <p>
                <b  className="labels">{t('Height')}:</b>
                <b className='contents' >{user.Height}</b>
              </p>
              <p>
                <b className="labels" >{t('Weight')}:</b>
                <b  className='contents'>{user.Weight}</b>
              </p>
              <p>
              <b className="labels" >{t('Physical Status')}:</b>
              <b  className='contents'> {user.Physical_Status}</b>

              </p>
              
            </div>

            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Professional Details')}</h4>

              <p>
                <b  className="labels">{t('Degree')}:</b>
                <b className='contents' >{user.Education}</b>
              </p>
              <p>
                <b className="labels" >{t('Course')}:</b>
                <b  className='contents'>{user.Course}</b>
              </p>
              <p>
              <b className="labels" >{t('Physical Status')}:</b>
              <b  className='contents'> {user.Employed_In}</b>

              </p>
              
            </div>


            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Religious Details')}</h4>

              <p>
                <b  className="labels">{t('Sub Caste')}:</b>
                <b className='contents' >{user.Sub_Caste}</b>
              </p>
              <p>
                <b className="labels" >{t('Kuladeivam')}:</b>
                <b  className='contents'>{user.Kuladeivam}</b>
              </p>
              <p>
              <b className="labels" >{t('Sub Caste Type')}:</b>
              <b  className='contents'> {user.Sub_Caste_Type  }</b>

              </p>
              <p>
              <b className="labels" >{t('Place')}:</b>
              <b  className='contents'> {user.Place }</b>

              </p>
              
              
            </div>

            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Location Details')}</h4>

              <p>
                <b  className="labels">{t('Country')}: </b>
                <b className='contents' >{user.Country}</b>
              </p>
              <p>
                <b className="labels" >{t('State')}:</b>
                <b  className='contents'>{user.State}</b>
              </p>
              <p>
              <b className="labels" >{t('District')}:</b>
              <b  className='contents'> {user.District  }</b>

              </p>
              
            </div>


          </div>
      </div> */}
    {/* </div> */}
    </>
  )
}

export default UserDetail;
