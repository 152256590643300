import React,{useState} from "react";
import { useSelector,useDispatch } from "react-redux";
import { setCountry,setState,setDistrict} from "../slices/locationSlice";
import axios from "axios";
import marriage from "../assets/mrg1.jpeg";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { useTranslation } from "react-i18next";

const LocationDetails = () => {

  const {Country,State,District}=useSelector((state)=>state.locationInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const {t}=useTranslation();


  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});

    const newErrors = {};


    if (!Country || Country === "select") {
      newErrors.Country = "Country is required";
    }

    if (!State || State === "select") {
      newErrors.State = "State is required.";
    }
    if (!District) {
      newErrors.District = "District is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try{
      const payload = {Country,State,District};
       const response = await axios.post('http://localhost:8000/locationdetails',payload);  

      console.log("Response",response.data)  
      dispatch(setCountry(""));
      dispatch(setDistrict(""));
      dispatch(setState(""));           
      navigate('/familydetails'); 
   
      }catch(error) {
      console.error('Error submitting form', error);
      alert('Form submission failed');
    } 
    setErrors({});
 
  };
  return (
    <>
    <Navbar/>
      <div
        className="form-container vh-100 col-md-12"
        style={{
          backgroundImage: `url(${marriage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <form className="form p-5" onSubmit={handleSubmit}>
          <h4 className="heading bg-danger mb-4">{t('Location Details')}</h4>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Country" className="w-100">
              {t('Country')}
            </label>
            <select
              name="Country"
              id="Country"
              className="w-100 p-1"
              value={Country}
              onChange={(e) => dispatch(setCountry(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="India">{t('India')}</option>
              <option value="AF">Afghanistan</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antarctica">Antarctica</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
            </select>
            {errors.Country && (
              <div style={{ color: "red" }}>{errors.Country}</div>
            )}
          </div>{" "}
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="State" className="w-100">
              {t('State')}
            </label>
            <select
              name="State"
              id="State"
              className="w-100 p-1"
              value={State}
              onChange={(e) => dispatch(setState(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Andhra_Pradesh">Andhra Pradesh</option>
              <option value="Arunachal_Pradesh">Arunachal Pradesh</option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chattisgarh">Chattisgarh</option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal_Pradesh">Himachal Pradesh</option>
              <option value="Jammu_and_Kashmir">Jammu and Kashmir</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">{t('Kerala')}</option>
              <option value="Madhya_Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil_Nadu">{t('Tamil Nadu')}</option>
              <option value="Telangana">{t('Telangana')}</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="Uttar_Pradesh">Uttar Pradesh</option>
              <option value="West_Bengal">West Bengal</option>
              <option value="Andaman_and_Nicobar_Islands">
                Andaman and Nicobar Islands
              </option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="DN">Daman and Diu</option>
              <option value="Delhi">{t('Delhi')}</option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Puducherry">{t('Puducherry')}</option>
            </select>
            {errors.State && <div style={{ color: "red" }}>{errors.State}</div>}
          </div>
          <label htmlFor="District" className="w-100">
            {t('District')}
          </label>
          <div className="form-group mb-3 col-xs-1">
            <select
              name="District"
              id="District"
              className="w-100 p-1"
              value={District}
              onChange={(e) => dispatch(setDistrict(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Ariyalur">{t('Ariyalur')}</option>
              <option value="Chengalpattu">{t('Chengalpattu')}</option>
              <option value="Chennai">{t('Chennai')}</option>
              <option value="Coimbatore">{t('Coimbatore')}</option>
              <option value="Cuddalore">{t('Cuddalore')}</option>
              <option value="Dharmapuri">{t('Dharmapuri')}</option>
              <option value="Dindigul">{t('Dindigul')}</option>
              <option value="Erode">{t('Erode')}</option>
              <option value="Kallakurichi">{t('Kallakurichi')}</option>
              <option value="Kanchipuram">{t('Kanchipuram')}</option>
              <option value="Kanyakumari">{t('Kanyakumari')}</option>
              <option value="Karur">{t('Karur')}</option>
              <option value="Krishnagiri">{t('Krishnagiri')}</option>
              <option value="Madurai">{t('Madurai')}</option>
              <option value="Nagapattinam">{t('Nagapattinam')}</option>
              <option value="">{t('Mayiladuthurai')}</option>
              <option value="Namakkal">{t('Namakkal')}</option>
              <option value="Nilgiris">{t('Nilgiris')}</option>
              <option value="Perambalur">{t('Perambalur')}</option>
              <option value="Pudukkottai">{t('Pudukkottai')}</option>
              <option value="Ramanathapuram">{t('Ramanathapuram')}</option>
              <option value="Salem">{t('Salem')}</option>
              <option value="Sivaganga">{t('Sivaganga')}</option>
              <option value="Tenkasi">{t('Tenkasi')}</option>
              <option value="Thanjavur">{t('Thanjavur')}</option>
              <option value="Theni">{t('Theni')}</option>
              <option value="Thoothukudi">{t('Thoothukudi')}</option>
              <option value="Tiruchirappalli">{t('Tiruchirappalli')}</option>
              <option value="Tirunelveli">{t('Tirunelveli')}</option>
              <option value="Tirupathur">{t('Tirupathur')}</option>
              <option value="Tiruppur">{t('Tiruppur')}</option>
              <option value="Tiruvallur">{t('Tiruvallur')}</option>
              <option value="Tiruvannamalai">{t('Tiruvannamalai')}</option>
              <option value="Tiruvarur">{t('Tiruvarur')}</option>
              <option value="Vellore">{t('Vellore')}</option>
              <option value="Viluppuram">{t('Viluppuram')}</option>
              <option value="Virudhunagar">{t('Virudhunagar')}</option>
            </select>
            {errors.District && (
              <div style={{ color: "red" }}>{errors.District}</div>
            )}
          </div>
          <button
            type="submit"
            name="submit-btn"
            className="btn btn-danger w-100"
          >
            {t('Next')}
          </button>
        </form>
      </div>
    </>
  );
};

export default LocationDetails;
