import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    username: "",
    signupEmail: "",
    password: "",
    confirmPassword: ""
}

export const signupSlice = createSlice({
    name: 'signupInfo',
    initialState,
    reducers:{
        setUsername: (state,action)=>{
            state.username = action.payload;
        },
        setSignupEmail: (state,action)=>{
            state.signupEmail = action.payload;
        },
        setPassword: (state,action)=>{
            state.password = action.payload;
        },
        setConfirmPassword: (state,action)=>{
            state.confirmPassword = action.payload;
        },
 

    }
});

export const {setUsername,setSignupEmail,setPassword,setConfirmPassword}=signupSlice.actions;
export default signupSlice.reducer;

