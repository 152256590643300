import React from "react";
import { useSelector } from "react-redux";
// import { setUsers } from "../slices/HomepageSlice";
import { Link, useNavigate } from "react-router-dom";
// import { logout } from "../slices/loginSlice";
import { useTranslation } from "react-i18next";

const Navbar = ()=>{
  const { t } = useTranslation();

  // const users = useSelector(state => state.HomepageInfo);
  const { email } = useSelector(state => state.loginInfo);
  const {signupEmail} = useSelector(state=>state.signupInfo);
  // console.log("signup email in navigation",signupEmail)
  const navigate = useNavigate();   

  const handleMyProfile = async () => {
    if (email || signupEmail) {
      navigate("/myProfile");
    } else {
      navigate("/login");
    }
  };

  const handleOthersProfile = async () => {
    if (email || signupEmail) {
      navigate("/homepage");
    } else {
      navigate("/login");
    }
  };
  
  

return(
  <>
  <div className="d-flex mb-1">
  <div className="d-flex justify-content-between align-items-center mb-1" style={{ width: '100%' }}>
  <div>
    <h4 className="m-0 mx-2">{t('Matrimony')} </h4>
  </div>
  <div>
    <h6 className="m-0 mx-2">{t('Do not have an Account')} <span><Link to='/signup'>{t('Register')}</Link></span></h6>
  </div>
</div>

  </div>
   <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-danger">
        <div className="container-fluid px-3" style={{ padding: "5px" }}>
          <Link className="navbar-brand" to="/" style={{ color: "white" }}>
            {t('Matrimony')}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ fontSize: "16px", color: "white" }}
          >
            <span
              className="navbar-toggler-icon toggler-icon"
              style={{ fontSize: "16px", color: "white" }}
            ></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item mx-1">
                <Link className="nav-link" to="/" style={{ color: "white" }}>
                {t('Home')} 
                </Link>  
              </li>
              <li className="nav-item mx-1">
                <button 
                  className="nav-link btn btn-link" 
                  onClick={handleMyProfile} 
                  style={{ color: "white", textDecoration: 'none' }}>
                   {t('MyProfile')} 
                </button>
              </li>
              <li className="nav-item mx-1">
                <button 
                  className="nav-link btn btn-link" 
                  style={{ color: "white", textDecoration: 'none' }}
                  onClick={handleOthersProfile}>
                   {t('ViewProfiles')}  
                </button>
              </li>
              <li className="nav-item mx-1">
                <Link className="nav-link" to="/search" style={{ color: "white" }}>
                {t('Search')} 
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link className="nav-link" to="/logout" style={{ color: "white" }}>
                {t('Logout')} 
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link className="nav-link" to="/events" style={{ color: "white" }}>
                {t('Events')} 
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </>
)
}
export default Navbar;