import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    users: [],
    
  
}

export const HomepageSlice = createSlice({
    name: 'HomepageInfo',
    initialState,
    reducers:{
        
        setUsers: (state,action)=>{
            state.users = action.payload;
        },
    }
});

export const {setUsers}=HomepageSlice.actions;
export default HomepageSlice.reducer;

