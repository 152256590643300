import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { setSub_Caste, setSub_Caste_Type, setKuladeivam, setPlace } from '../slices/religiousSlice';
import { useNavigate } from "react-router-dom";
import marriage from "../assets/mrg.jpeg";
import Navbar from "./Navbar";
import { useTranslation } from "react-i18next";


const ReligiousDetails = () => {

  const {Sub_Caste,Sub_Caste_Type,Kuladeivam,Place} = useSelector((state) => state.religiousInfo) || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const {t}=useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});

    const newErrors = {};

    if (!Sub_Caste || Sub_Caste === "select") {
      newErrors.Sub_Caste = "Sub Sect is required.";
    }

    if (Sub_Caste !== "Unknown" && (!Sub_Caste_Type || Sub_Caste_Type === "select")) {
      newErrors.Sub_Caste_Type = "Sub Sect Type is required.";
    }

    if (!Kuladeivam || Kuladeivam === "select") {
      newErrors.Kuladeivam = "Kuladeivam is required.";
    }

    if (!Place) {
      newErrors.Place = "Place is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const payload = { Sub_Caste, Sub_Caste_Type, Kuladeivam, Place };

      const response = await axios.post(
        "http://localhost:8000/religiousDetails",
        payload
      );
      console.log("Response:", response.data);
      navigate('/professionaldetails');
      
      dispatch(setSub_Caste(""));
      dispatch(setSub_Caste_Type(""));
      dispatch(setKuladeivam(""));
      dispatch(setPlace(""));
    } catch (error) {
      console.error("Error:", error);
      alert('Form submission failed');
    }
  };

  return (
<>
    <Navbar/>
    <div
    className="form-container vh-100 col-md-12"
    style={{
      backgroundImage: `url(${marriage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}
  >
    <form className="form p-5" onSubmit={handleSubmit}>
      <h4 className="heading bg-danger mb-4">{t('Religious Details')}</h4>

      <div className="form-group mb-3 col-xs-1">
        <label htmlFor="name" className="col-md-4 col-lg-4 col-sm-4">
          {t('Religion')}
        </label>
        <input
          type="text"
          className="w-100"
          name="Name"
          id="name"
          value="Hindu"
          readOnly
        />
      </div>

      <div className="form-group mb-3 col-xs-1">
        <label htmlFor="name" className="col-md-4 col-lg-4 col-sm-4">
          {t('Caste')}
        </label>
        <input
          type="text"
          className="w-100"
          name="Sengunthar_Kaikolar"
          id="Sengunthar_Kaikolar"
          value="Sengunthar Kaikolar"
          readOnly
        />
      </div>

      <div className="form-group mb-3 col-xs-1">
        <label htmlFor="Sub_Caste" className="w-100">
          {t('Sub Sect')}
        </label>
        <select
          name="Sub_Caste"
          id="Sub_Caste"
          className="w-100 p-1"
          value={Sub_Caste}
          onChange={(e) => dispatch(setSub_Caste(e.target.value))}
          required
        >
          <option value="select">Select--</option>
          <option value="Kootam">{t('Kootam')}</option>
          <option value="Kulam">{t('Kulam')}</option>
          <option value="Gothram">{t('Gothram')}</option>
          <option value="Others">{t('Others')}</option>
          <option value="Unknown">{t('Unknown')}</option>
        </select>
        {errors.Sub_Caste && <div style={{ color: "red" }}>{errors.Sub_Caste}</div>}
      </div>

      {Sub_Caste === "Kootam" && (
        <div className="form-group mb-3 col-xs-1">
          <label htmlFor="Sub_Caste_Type" className="w-100">
            {Sub_Caste}
          </label>
          <select
            name="Sub_Caste_Type"
            id="Sub_Caste_Type"
            className="w-100 p-1"
            value={Sub_Caste_Type}
            onChange={(e) => dispatch(setSub_Caste_Type(e.target.value))}
            required
          >
            <option value="select">Select--</option>
            <option value="Kothukaatan_Kootam">Kothukaatan Kootam </option>
                <option value="Kannimaar_Kootam">Kannimaar Kootam</option>
                <option value="Thadiveeran_Kootam">Thadiveeran Kootam</option>
                <option value="KottaiAnnan_Kootam">KottaiAnnan Kootam</option>
                <option value="Pattakaara_Kootam">Pattakaara Kootam</option>
                <option value="KottaiMaari_Kootam">KottaiMaari Kootam</option>
                <option value="Undikaarar_Kootam">Undikaarar Kootam</option>
                <option value="OyaaMaari_Kootam">OyaaMaari Kootam</option>
                <option value="Poomudhali_Kootam">Poomudhali Kootam</option>
                <option value="ThavudhraMudhali_Kootam">
                  ThavudhraMudhali Kootam
                </option>
                <option value="ThaandavaMudhali_Kootam">
                  ThaandavaMudhali Kootam
                </option>
                <option value="ChidambaraMudhali_Kootam">
                  ChidambaraMudhali Kootam
                </option>
                <option value="NaagaMudhali_Kootam">NaagaMudhali Kootam</option>
                <option value="Ellamma_Kootam">Ellamma Kootam</option>
                <option value="ThattaiNaatuThadimaaran_Kootam">
                  ThattaiNaatuThadimaaran Kootam
                </option>
                <option value="Erumaikaarar_Kootam">Erumaikaarar Kootam</option>
                <option value="TheerthaMudhali_Kootam">
                  TheerthaMudhali Kootam
                </option>
                <option value="Alangarang_Kootam">Alangarang Kootam</option>
                <option value="Sokkanaadhan_Kootam">Sokkanaadhan Kootam</option>
                <option value="Aandi_Kootam">Aandi Kootam</option>
                <option value="Appaai_Aravaai_Kootam">
                  Appaai Aravaai Kootam
                </option>
                <option value="Rathnagiri_Kootam">Rathnagiri Kootam</option>
                <option value="Sandhiyappar_Kootam">Sandhiyappar Kootam</option>
                <option value="Thambiannan_Kootam">Thambiannan Kootam</option>
                <option value="Paramashivam_Kootam">Paramashivam Kootam</option>
                <option value="SadaThevar_Kootam">SadaThevar Kootam</option>
                <option value="ChozhaMudhali_Kootam">
                  ChozhaMudhali Kootam
                </option>
                <option value="Pomparappi_Kootam">Pomparappi Kootam</option>
                <option value="Chettisaai_Kootam">Chettisaai Kootam</option>       </select>
          {errors.Sub_Caste_Type && <div style={{ color: "red" }}>{errors.Sub_Caste_Type}</div>}
        </div>
      )}

      {Sub_Caste === "Gothram" && (
        <div className="form-group mb-3 col-xs-1">
          <label htmlFor="Sub_Caste_Type" className="w-100">
            {Sub_Caste}
          </label>
          <select
            name="Sub_Caste_Type"
            id="Sub_Caste_Type"
            className="w-100 p-1"
            value={Sub_Caste_Type}
            onChange={(e) => dispatch(setSub_Caste_Type(e.target.value))}
            required
          >
            <option value="select">Select--</option>
            <option value="Vetrivel_Gothram">Vetrivel Gothram</option>
            <option value="Veeravel_Gothram">Veeravel Gothram</option>
          </select>
          {errors.Sub_Caste_Type && <div style={{ color: "red" }}>{errors.Sub_Caste_Type}</div>}
        </div>
      )}

      {Sub_Caste === "Others" && (
        <div className="form-group mb-3 col-xs-1">
          <label htmlFor="Sub_Caste_Type" className="w-100">
            {Sub_Caste}
          </label>
          <input
            type="text"
            className="w-100"
            name="Sub_Caste_Type"
            id="Sub_Caste_Type"
            value={Sub_Caste_Type}
            onChange={(e) => dispatch(setSub_Caste_Type(e.target.value))}
            placeholder="Type Other Sub Sect"
          />
          {errors.Sub_Caste_Type && <div style={{ color: "red" }}>{errors.Sub_Caste_Type}</div>}
        </div>
      )}

      {Sub_Caste === "Kulam" && (
        <div className="form-group mb-3 col-xs-1">
          <label htmlFor="Sub_Caste_Type" className="w-100">
            {Sub_Caste}
          </label>
          <select
            name="Sub_Caste_Type"
            id="Sub_Caste_Type"
            className="w-100 p-1"
            value={Sub_Caste_Type}
            onChange={(e) => dispatch(setSub_Caste_Type(e.target.value))}
            required
          >
            <option value="select">Select--</option>
            <option value="Kulam_1">Kulam 1</option>
            <option value="Kulam_2">Kulam 2</option>
          </select>
          {errors.Sub_Caste_Type && <div style={{ color: "red" }}>{errors.Sub_Caste_Type}</div>}
        </div>
      )}

      <div className="form-group mb-3 col-xs-1">
        <label htmlFor="Kuladeivam" className="w-100">
          {t('Kuladeivam')}
        </label>
        <select
          name="Kuladeivam"
          id="Kuladeivam"
          className="w-100 p-1"
          value={Kuladeivam}
          onChange={(e) => dispatch(setKuladeivam(e.target.value))}
          required
        >
          <option value="select">Select--</option>
          <option value="Muniyappan">{t('Muniyappan')}</option>
          <option value="Karuparayan">{t('Karuparayan')}</option>
          <option value="Angaalamman">{t('Angaalamman')}</option>
          <option value="Periyaandichi">{t('Periyaandichi')}</option>
          <option value="Kannimar">{t('Kannimar')}</option>
        </select>
        {errors.Kuladeivam && <div style={{ color: "red" }}>{errors.Kuladeivam}</div>}
      </div>

      <div className="form-group mb-3 col-xs-1">
        <label htmlFor="Place" className="col-md-4 col-lg-4 col-sm-4">
          {t('Place')}
        </label>
        <input
          type="text"
          className="w-100"
          name="Place"
          id="Place"
          value={Place}
          placeholder="Place"
          onChange={(e) => dispatch(setPlace(e.target.value))}
        />
        {errors.Place && <div style={{ color: "red" }}>{errors.Place}</div>}
      </div>

      <button
        type="submit"
        name="submit-btn"
        className="btn btn-danger w-100"
      >
        {t('Next')}
      </button>
    </form>
    </div>
    </>
  );
};

export default ReligiousDetails;
