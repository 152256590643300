import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Country: "",
    State:"",
    District: "", 
}

export const locationSlice = createSlice({
    name: 'locationInfo',
    initialState,
    reducers:{
        setCountry: (state,action)=>{
            state.Country = action.payload;
        },
        setState: (state,action)=>{
            state.State = action.payload;
        },
        setDistrict:(state,action)=>{
           state.District = action.payload;
        },
    }
});

export const {setCountry,setState,setDistrict}=locationSlice.actions;
export default locationSlice.reducer;

