import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
// import marriage from '../assets/mrg.jpeg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ImageStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover', 
  width: '24rem', 
  height: '23rem'
};

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-prev-arrow`}
      style={{
        ...style,
        display: "block",
        left: "10px",
        zIndex: 1000,
        fontSize: "24px",
        backgroundColor: "#dc3545",
        color: "white",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        lineHeight: "40px",
        textAlign: "center",
      }}
      onClick={onClick}
    />
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-next-arrow`}
      style={{
        ...style,
        display: "block",
        right: "10px",
        zIndex: 1000,
        fontSize: "24px",
        backgroundColor: "#dc3545",
        color: "white",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        lineHeight: "40px",
        textAlign: "center",
      }}
      onClick={onClick}
    />
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const QuickSearch = ({ onSearchResults, initialData }) => {
  const [Name, setName] = useState("");
  const [ageMin, setAgeMin] = useState('');
  const [ageMax, setAgeMax] = useState('');
  const [Gender, setGender] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [Data, setData] = useState(initialData || []); 
  const { t } = useTranslation();

  const fetchData = async () => {
    try {
      const response = await axios.get('http://localhost:8000/quicksearch', {
        params: { ageMin, ageMax, Gender },
      });
      const FetchedData = response.data;
      setData(FetchedData);
      console.log("Fetched Datas in Quicksearch",FetchedData)
      localStorage.setItem('searchData', JSON.stringify(FetchedData)); 
      onSearchResults(FetchedData); 
    } catch (error) {
      console.error('There was an error fetching the data!');
    }
  };

  const handleQuickSearch = (event) => {
    event.preventDefault();
    setError('');
    if (!Gender || Gender === 'select') {
      setError('Gender is required');
      return;
    }
    fetchData();
  };

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  const handleProfileInQuickSearch = (values) => {
    navigate(`/searchProfileDetails/${values.ID}`, { state: { user: values, searchResults: Data } });
  };

  return (
    <>
      <form onSubmit={handleQuickSearch}>
        <div className="row">
          <div className="col-sm-4 mb-3">
            <label className="form-label">{t('Name')}:</label>
            <input
              type="text"
              value={Name}
              className="form-control"
              name="Name"
              placeholder='Name'
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-sm-4 mb-3">
            <label className="form-label">{t('Age')} {t('From')}:</label>
            <input
              type="number"
              value={ageMin}
              className="form-control"
              name="age_from"
              placeholder='Age from'
              onChange={(e) => setAgeMin(e.target.value)}
            />
          </div>
          <div className="col-sm-4 mb-3">
            <label className="form-label">{t('Age')} {t('To')}:</label>
            <input
              type="number"
              value={ageMax}
              className="form-control"
              name="age_to"
              onChange={(e) => setAgeMax(e.target.value)}
            />
          </div>
        </div>
        <div className="col-sm-6 mb-3">
          <label className="form-label">{t('Looking For')}:</label>
          <select
            value={Gender}
            onChange={(e) => setGender(e.target.value)}
            name="Looking_For"
            className="form-control">
            <option value="select">Select--</option>
            <option value="male">Bride</option>
            <option value="female">Groom</option>
          </select>
          {error && <div style={{ color: 'red' }}>{error}</div>}
        </div>
        <button type="submit" className="btn btn-success">{t('Search')}</button>
      </form>

      {Data.length > 0 && (
        <div className="carousel-container mt-4">
          {Data.length > 3 ? (
            <Slider {...settings}>
              {Data.map((values) => (
                <div key={values.email} className="card mycard">
                  <img className="card-img-top" src={values.Profile_Image} alt="Card" style={ImageStyle} />
                  <div className="card-body text-center">
                    <h1 className="card-title" style={{ fontSize: '23px' }}>
                      <span style={{ color: 'red' }}>{values.Name}</span>
                    </h1>
                    <p className="card-text">{t('Gender')}: {values.Gender}</p>
                    <p className="card-text">{t('Age')}: {values.Age}</p>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleProfileInQuickSearch(values)}
                    >
                      {t('Profile')}
                    </button>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="row">
              {Data.map((values) => (
                <div key={values.email} className="col-sm-4 mb-3">
                  <div className="card">
                    <img className="card-img-top" src={values.Profile_Image} alt="Card" />
                    <div className="card-body text-center">
                      <h1 className="card-title" style={{ fontSize: '23px' }}>
                        <span style={{ color: 'red' }}>{values.Name}</span>
                      </h1>
                      <p className="card-text">{t('Gender')}: {values.Gender}</p>
                      <p className="card-text">{t('Age')}: {values.Age}</p>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleProfileInQuickSearch(values)}
                      >
                        {t('Profile')}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default QuickSearch;
