import React from "react";
import { useContext } from "react";
import { RecoveryContext } from "../App";

export default function Reset() {
  const { setPage } = useContext(RecoveryContext);
  function changePassword() {
    setPage("recovered");
  }

  return (
    <div>
      <section className="bg-light vh-100">
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
          <div className="card shadow-lg p-4 w-100" style={{ maxWidth: "400px" }}>
            <h2 className="mb-4 text-center text-dark">
              Change Password
            </h2>
            <form className="mb-4">
              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="form-label"
                >
                  New Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="form-control"
                  required
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="confirm-password"
                  className="form-label"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  name="confirm-password"
                  id="confirm-password"
                  placeholder="••••••••"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-check mb-3">
                <input
                  id="newsletter"
                  aria-describedby="newsletter"
                  type="checkbox"
                  className="form-check-input"
                  required
                />
                <label
                  htmlFor="newsletter"
                  className="form-check-label"
                >
                  I accept the{" "}
                  <a href="./" className="text-primary">
                    Terms and Conditions
                  </a>
                </label>
              </div>
            </form>
            <button
              onClick={() => changePassword()}
              className="btn btn-primary w-100"
            >
              Reset Password
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
