import React,{useState} from "react";
import { useSelector,useDispatch } from "react-redux";
import { setPhysical_Status,setHeight,setWeight,setBody_Type,setSkin_Colour,setBlood_Group} from "../slices/personalSlice";
import axios from "axios";
import marriage from "../assets/mrg.jpeg";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { useTranslation } from "react-i18next";

const PersonalDetails = () => {

const {Physical_Status,Height,Weight,Body_Type,Skin_Colour,Blood_Group}=useSelector((state)=>state.personalInfo);
const dispatch = useDispatch();
const navigate = useNavigate();
const [errors, setErrors] = useState({});
const {t}=useTranslation();


  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});

    const newErrors = {};

    if (!Physical_Status || Physical_Status === "select") {
      newErrors.Physical_Status = "Physical Status is required.";
    }
    if (!Height || Height === "select") {
      newErrors.Height = "Height is required";
    }
    if (!Weight || Weight === "select") {
      newErrors.Weight = "Weight In Kg is required";
    }
    if (!Body_Type || Body_Type === "select") {
      newErrors.Body_Type = "Body Type is required";
    }
    if (!Skin_Colour || Skin_Colour === "select") {
      newErrors.Skin_Colour = "Skin Colour is required";
    }
    if (!Blood_Group || Blood_Group === "select") {
      newErrors.Blood_Group = "Blood Group is required";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } 
    try {
      const payload = {Physical_Status,Height,Weight,Body_Type,Skin_Colour,Blood_Group};

      const response = await axios.post(
        "http://localhost:8000/PersonalDetails",
        payload
      );
      console.log("Response:", response.data);
      navigate('/religiousDetails');
      dispatch(setPhysical_Status(""));
      dispatch(setHeight(""));
      dispatch(setWeight(""));
      dispatch(setBody_Type(""));
      dispatch(setSkin_Colour(""));
      dispatch(setBlood_Group(""));


    } catch (error) {
      console.error("Error:", error);
      alert('Form submission failed');
    }
  };

  return (
    <>

<Navbar/>
        <div
        className="form-container vh-100 col-md-12"
        style={{
          backgroundImage: `url(${marriage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
 
 <form className="form w-80  p-5" onSubmit={handleSubmit}>
          <h4 className="heading bg-danger mb-4">{t('Personal Details')}</h4>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Physical_Status" className="w-100">
              {t('Physical Status')}
            </label>
            <select
              name="Physical_Status"
              id="Physical_Status"
              className="w-100 p-1"
              value={Physical_Status}
              onChange={(e) => dispatch(setPhysical_Status(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Normal">{t('Normal')}</option>
              <option value="Physically Challenged">
                {t('Physically Challenged')}
              </option>
            </select>
            {errors.Physical_Status && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Physical_Status}
              </div>
            )}
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Height" className="w-100">
              {t('Height')}
            </label>
            <select
              name="Height"
              id="Height"
              className="w-100 p-1"
              value={Height}
              onChange={(e) => dispatch(setHeight(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="4ft 6in /137cms">4ft 6in /137cms</option>
              <option value="4ft 7in /138cms">4ft 7in /138cms</option>
              <option value="4ft 8in /139cms">4ft 8in /139cms</option>
              <option value="4ft 9in /140cms">4ft 9in /140cms</option>
            </select>
            {errors.Height && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Height}
              </div>
            )}
          </div>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Weight" className="w-100">
              {t('Weight')} in Kg{" "}
            </label>
            <select
              name="Weight_In_Kg"
              id="Weight"
              className="w-100 p-1"
              value={Weight}
              onChange={(e) => dispatch(setWeight(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
              <option value="46">46</option>
              <option value="47">47</option>
              <option value="48">48</option>
              <option value="49">49</option>
              <option value="50">50</option>
            </select>
            {errors.Weight && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Weight}
              </div>
            )}
          </div>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Body_Type" className="w-100">
              {t('Body Type')}
            </label>
            <select
              name="Body_Type"
              id="Body_Type"
              className="w-100 p-1"
              value={Body_Type}
              onChange={(e) => dispatch(setBody_Type(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Thin">{t('Thin')}</option>
              <option value="Normal">{t('Normal')}</option>
              <option value="Fat">{t('Fat')}</option>
              <option value="Slightly Thin">{t('Slightly Thin')}</option>
              <option value="Slightly Fat">{t('Slightly Fat')}</option>
            </select>
            {errors.Body_Type && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Body_Type}
              </div>
            )}
          </div>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Skin_Colour" className="w-100">
              {t('Skin Colour')}
            </label>
            <select
              name="Skin_Colour"
              id="Skin_Colour"
              className="w-100 p-1"
              value={Skin_Colour}
              onChange={(e) => dispatch(setSkin_Colour(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Fair">{t('Fair')}</option>
              <option value="White">{t('White')}</option>
              <option value="Wheatish Brown">{t('Wheatish Brown')}</option>
              <option value="Dark">{t('Dark')}</option>
              <option value="Dusky">{t('Dusky')}</option>            
            </select>
            {errors.Skin_Colour && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Skin_Colour}
              </div>
            )}
          </div>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Blood_Group" className="w-100">
              {t('Blood Group')}
            </label>
            <select
              name="Blood_Group"
              id="Blood_Group"
              className="w-100 p-1"
              value={Blood_Group}
              onChange={(e) => dispatch(setBlood_Group(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
              <option value="A1+">A1+</option>
              <option value="A1-">A1-</option>
              <option value="A2+">A2+</option>
              <option value="A2-">A2-</option>
              <option value="A1B+">A1B+</option>
              <option value="A1B-">A1B</option>
              <option value="A2B+">A2B+</option>
              <option value="A2B-">A2B-</option>
            </select>
            {errors.Blood_Group && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Blood_Group}
              </div>
            )}
          </div>

          <button
            type="submit"
            name="submit-btn"
            className="btn btn-danger w-100"
          >
            {t('Next')}
          </button>
        </form>
        </div>

    </>
  );
};

export default PersonalDetails;
