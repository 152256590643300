import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Father_Name: "",
    Father_Status: "",
    Mother_Name: "",
    Mother_Status: "",
    Social_Type:"",
    Native:"",
    Siblings:"",
}

export const familySlice = createSlice({
    name: 'familyInfo',
    initialState,
    reducers:{
        setFather_Name: (state,action)=>{
            state.Father_Name = action.payload;
        },
        setFather_Status: (state,action)=>{
            state.Father_Status = action.payload;
        },
        setMother_Name:(state,action)=>{
           state.Mother_Name = action.payload;
        },
        setMother_Status: (state,action)=>{
            state.Mother_Status = action.payload;
        }, 
        setSocial_Type: (state,action)=>{
            state.Social_Type = action.payload;
        }, 
        setNative: (state,action)=>{
            state.Native = action.payload;
        },      
        setSiblings: (state,action)=>{
            state.Siblings = action.payload;
        },                
    }
});

export const {setFather_Name,setFather_Status,setMother_Name,setMother_Status,setSocial_Type,setNative,setSiblings}=familySlice.actions;
export default familySlice.reducer;

