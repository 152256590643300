import React, { useState } from "react";
import marriage from "../assets/mrg1.jpeg";
import { useSelector,useDispatch } from "react-redux";
import { setExp_Marital_Status,setWork_Place,setMatching_Nakshatras,setExpectation,setJathagam} from "../slices/expectationSlice";
import {  useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import Navbar from "./Navbar";
import { useTranslation } from "react-i18next";

const Expectation = () => {

  const {Exp_Marital_Status,Work_Place,Matching_Nakshatras,Expectation,Jathagam}=useSelector((state)=>state.expectationInfo);
  // const Gender= useSelector((state)=>state.basicInfo.Gender);
  // console.log('gender value from store',Gender)
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  // const {signupEmail}=useSelector(state => state.signupInfo);
  const dispatch = useDispatch();
  const {t}=useTranslation();
  
  const options = [
    { label: "Sevvai Jathagam", value: "Sevvai_Jathagam" },
    { label: "Parigara Sevvai Jathagam", value: "Parigara_Sevvai_Jathagam" },
    { label: "Ragu Kedhu Jathagam", value: "Ragu_Kedhu_Jathagam" },
    { label: "Ragu Kedhu Sevvai", value: "Ragu_Kedhu_Sevvai" },
    { label: "No Jathagam Match", value: "No_Jathagam_Match" },
    { label: "Sevvai Jathagam Match", value: "Sevvai_Jathagam_Match" },
    { label: "Ragu Kedhu Jathagam Match", value: "Ragu_Kedhu_Jathagam_Match" },
    { label: "Ragu Kedhu, Sevvai Match", value: "Ragu_Kedhu_Sevvai_Match" },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});

    const newErrors = {};
    if (Jathagam.length === 0) {
      newErrors.Jathagam = "Jathagam is Required";
    }
    if (!Exp_Marital_Status || Exp_Marital_Status === "select") {
      newErrors.Exp_Marital_Status = "Marital Status is required";
    }
    if (!Work_Place || Work_Place === "select") {
      newErrors.Work_Place = "Work Place is required";
    }
    if (!Matching_Nakshatras) {
      newErrors.Matching_Nakshatras = "Matching Nakshatras is required";
    }
    if (!Expectation) {
      newErrors.Expectation = "Expectation is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const payload = {Exp_Marital_Status,
        Work_Place,
        Matching_Nakshatras,
        Expectation,
        Jathagam: Jathagam.map(option => option.value)}
      const response = await axios.post('http://localhost:8000/expectation',payload);

      alert('Expectation Information submitted successfully');

      navigate('/homepage');
      setErrors({});
      dispatch(setJathagam([]));
      dispatch(setExp_Marital_Status(""));
      dispatch(setWork_Place(""));
      dispatch(setMatching_Nakshatras(""));
      dispatch(setExpectation(""));

      // fetchOppositeGenderProfiles();
      // fetchUseremail();

    } catch (error) {
      console.error('Error submitting form', error);
      alert('Form submission failed');
    }
  };
  
  // const fetchOppositeGenderProfiles = async () => {
  //   const oppositeGender = Gender === 'male' ? 'male' : 'female';
  //   try {
  //     const response = await axios.get('http://localhost:8000/fetchOppositeGender', {
  //       params: { gender: oppositeGender }
  //   });
  //     console.log("Fetched opposite gender profiles:", response.data);
  //     navigate('/otherProfiles', { state: { gender: oppositeGender, users: response.data } });
  //   } catch (error) {
  //     console.error('Error fetching opposite gender profiles:', error);
  //     alert('Failed to fetch opposite gender profiles');
  //   }
  // };

  return (
    <>
    <Navbar/>
      <div
        className="personal-details vh-100 col-md-12"
        style={{
          backgroundImage: `url(${marriage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <form className="form p-5" onSubmit={handleSubmit}>
          <h4 className="heading bg-danger mb-4">{t('Expectation')}</h4>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Jathagam" className="w-100">
              {t('Jathagam')}
            </label>
            <div>
            <MultiSelect
                  options={options}
                value={Jathagam}
                onChange={(selectedOptions) => dispatch(setJathagam(selectedOptions))}
                labelledBy="Select"
/>

              {errors.Jathagam && (
                <div style={{ color: "red" }}>{errors.Jathagam}</div>
              )}
            </div>
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Exp_Marital_Status" className="w-100">
              {t('Marital Status')}
            </label>
            <select
              name="Exp_Marital_Status"
              id="Exp_Marital_Status"
              className="w-100 p-1"
              value={Exp_Marital_Status}
              onChange={(e) => dispatch(setExp_Marital_Status(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="unMarried">UnMarried</option>
              <option value="widow">Widow</option>
              <option value="divorced">Divorced</option>
              <option value="awaiting">Awaiting Divorce</option>
            </select>
            {errors.Exp_Marital_Status && (
              <div style={{ color: "red" }}>{errors.Exp_Marital_Status}</div>
            )}
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Work_Place" className="w-100">
              {t('Work Place')}
            </label>
            <select
              name="Work_Place"
              id="Work_Place"
              className="w-100 p-1"
              value={Work_Place}
              onChange={(e) => dispatch(setWork_Place(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Native">Native</option>
              <option value="Coimbatore">Coimbatore</option>
              <option value="Bengaluru">Bengaluru</option>
              <option value="Chennai">Chennai</option>
              <option value="Hyderabad">Hyderabad</option>
              <option value="Abroad">Abroad</option>
              <option value="Other_ State">Other State</option>
            </select>
            {errors.Work_Place && (
              <div style={{ color: "red" }}>{errors.Work_Place}</div>
            )}
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label
              htmlFor="Matching_Nakshatras"
              className="col-md-12 col-lg-12 col-sm-12"
            >
              {t('Matching Nakshatras')}
            </label>
            <textarea
              type="text"
              className="w-100"
              name="Matching_Nakshatras"
              id="Matching_Nakshatras"
              placeholder="Matching Nakshatras"
              value={Matching_Nakshatras}
              onChange={(e) => dispatch(setMatching_Nakshatras(e.target.value))}
            />
            {errors.Matching_Nakshatras && (
              <div style={{ color: "red" }}>{errors.Matching_Nakshatras}</div>
            )}
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label
              htmlFor="Expectation"
              className="col-md-12 col-lg-12 col-sm-12"
            >
              {t('Expectation')}
            </label>
            <textarea
              type="text"
              className="w-100"
              name="Expectation"
              id="Expectation"
              placeholder="Expectation"
              value={Expectation}
              onChange={(e) => dispatch(setExpectation(e.target.value))}
            />
            {errors.Expectation && (
              <div style={{ color: "red" }}>{errors.Expectation}</div>
            )}
          </div>

          <button
            type="submit"
            name="submit-btn"
            className="btn btn-danger w-100"
          >
            {t('Next')}
          </button>
        </form>
      </div>
    </>
  );
};

export default Expectation;
