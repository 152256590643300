import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Exp_Marital_Status: "",
    Work_Place: "",
    Matching_Nakshatras: "",
    Expectation: "",
    Jathagam:[],
  
}

export const expectationSlice = createSlice({
    name: 'expectationInfo',
    initialState,
    reducers:{
        
        setExp_Marital_Status: (state,action)=>{
            state.Exp_Marital_Status = action.payload;
        },
        setWork_Place: (state,action)=>{
            state.Work_Place = action.payload;
        },
        setMatching_Nakshatras:(state,action)=>{
           state.Matching_Nakshatras = action.payload;
        },
        setExpectation: (state,action)=>{
            state.Expectation = action.payload;
        },
        setJathagam: (state,action)=>{
            state.Jathagam = action.payload;
        },
    }
});

export const {setExp_Marital_Status,setWork_Place,setMatching_Nakshatras,setExpectation,setJathagam}=expectationSlice.actions;
export default expectationSlice.reducer;

