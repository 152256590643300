import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Navbar';
import QuickSearch from './QuickSearch';
import AdvancedSearch from './AdvancedSearch';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Search = () => {
  const [activeTab, setActiveTab] = useState('quick');
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();
  const {t} = useTranslation();

  useEffect(() => {
    if (location.state && location.state.searchResults) {
      setSearchResults(location.state.searchResults);
    } else {
      const storedData = localStorage.getItem('searchData');
      if (storedData) {
        setSearchResults(JSON.parse(storedData));
      }
    }
  }, [location.state]);

  const handleSearchResults = (results) => {
    setSearchResults(results);
    localStorage.setItem('searchData', JSON.stringify(results));
  };

  // const handleTabChange = (tab) => {
  //   setActiveTab(tab);
  //   setSearchResults([]);  // Clear the search results when tab changes
  //   localStorage.removeItem('searchData');  // Optionally remove stored search data
  // };

  const handleIDSearch = (e) => {
    e.preventDefault();
    const userId = e.target.user_id.value;
    // Logic to handle ID search goes here
    console.log(`Searching for user ID: ${userId}`);
    // You could update searchResults with the data you fetch
    // setSearchResults(fetchedResults);
  };

  const renderForm = () => {
    switch (activeTab) {
      case 'quick':

        return (
          <>
            <QuickSearch onSearchResults={handleSearchResults} initialData={searchResults} />
          </>
        );
      case 'id':
        return (
          <form onSubmit={handleIDSearch}>
            <div className="mb-3">
              <label className="form-label">{t('User ID')}:</label>
              <input type="text" className="form-control" name="user_id" />
            </div>
            <button type="submit" className="btn btn-success">{t('Search')}</button>
          </form>
        );
      case 'advance':
        return (
          <>
          <AdvancedSearch/>          
            {/* <AdvancedSearch onSearchResults={handleSearchResults} initialData={searchResults} /> */}
          </>
        );
        
  
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />
      <div className="d-flex align-items-center justify-content-center">
        <div className="card p-4" style={{ width: '100%' }}>
          <div className="card-header">
            <ul className="nav nav-tabs card-header-tabs">
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'quick' ? 'active' : ''}`}
                  onClick={() => setActiveTab('quick')}
                >
                  Quick Search
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'id' ? 'active' : ''}`}
                  onClick={() => setActiveTab('id')}
                >
                  ID Search
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'advance' ? 'active' : ''}`}
                  onClick={() => setActiveTab('advance')}
                >
                  Advanced Search
                </button>
              </li>
            </ul>
          </div>
          <div className="card-body">
            {renderForm()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
