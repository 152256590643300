import React from "react";
import { useSelector,useDispatch } from "react-redux";
import { setUsername,setSignupEmail,setPassword,setConfirmPassword } from "../slices/signupSlice";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import marriage from "../assets/mrg.jpeg";
import Navbar from "./Navbar";
import { useTranslation } from "react-i18next";


const Signup = ()=>{
  
  const {username,signupEmail,password,confirmPassword}=useSelector((state)=>state.signupInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t}=useTranslation();

  const handleSubmit =async(e)=>{
    e.preventDefault();
    // console.log("signup email",signupEmail)
    const payload = {
      name: username,
      email:signupEmail,password
    }
    try {
      const response = await axios.post('http://localhost:8000/signup', payload);
      console.log('Signup successful!');
      navigate("/basicDetails");
      sessionStorage.setItem("signupEmail",signupEmail);

    } catch (error) {
      console.error('Signup failed:', error.response ? error.response.data : error.message);
      
    }

    dispatch(setUsername(""));
      // dispatch(setSignupEmail(""));
      dispatch(setPassword(""));
      dispatch(setConfirmPassword(""));

  }
  return(
    <>
    <Navbar/>
        <div
        className="form-container vh-100 col-md-12"
        style={{
          backgroundImage: `url(${marriage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
   
    <form className="form w-80 p-5" onSubmit={handleSubmit}>
 
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="username" className="col-md- col-lg-4 col-sm-4">
              {t('Username')}
            </label>
            <input
              type="text"
              className="w-100"
              name="username"
              id="username"
              value={username}
              onChange={(e)=>dispatch(setUsername(e.target.value))}
            />
            </div>
            <div className="form-group mb-3 col-xs-1">
            <label htmlFor="email" className="col-md-4 col-lg-4 col-sm-4">
              {t('Email')}
            </label>
            <input
  type="email"
  name="email"
  className="w-100"
  id="email"
  value={signupEmail} 
  onChange={(e) => {
   dispatch(setSignupEmail(e.target.value))
  }}
/>

          </div>
          
          <div className="form-group mb-4 col-xs-1">
            <label htmlFor="password" className="col-md-4 col-lg-4 col-sm-4">
              {t('Password')}
            </label>
            <input
              type="password"
              className="w-100"
              name="password"
              id="password"
              value={password}
              onChange={(e)=>dispatch(setPassword(e.target.value))}
            />
            {/* {errors.password && <div className="error">{errors.password}</div>} */}
          </div>

          <div className="form-group mb-4 col-xs-1">
            <label
              htmlFor="confirmPassword"
              className="col-md-8 col-lg-8 col-sm-8"
            >
              {t('Confirm Password')}
            </label>
            <input
              type="password"
              className="w-100"
              name="confirmPassword"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e)=>dispatch(setConfirmPassword(e.target.value))}
            />
            {/* {errors.confirmPassword && (
              <div className="error">{errors.confirmPassword}</div>
            )} */}
          </div>


          <button
            type="submit"
            name="submit-btn"
            className="btn btn-danger w-100 mb-3"
          >
            Register For Free
          </button>
       
          <h6>Already Registered? <span><Link to = '/login'>Login</Link> </span></h6>
            </form>
            </div>

    </>
  )
}
export default Signup;