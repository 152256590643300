import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Physical_Status: "",
    Height: "",
    Weight: "",
    Body_Type: "",
    Skin_Colour:"",
    Blood_Group:"",
}

export const personalSlice = createSlice({
    name: 'personalInfo',
    initialState,
    reducers:{
        setPhysical_Status: (state,action)=>{
            state.Physical_Status = action.payload;
        },
        setHeight: (state,action)=>{
            state.Height = action.payload;
        },
        setWeight:(state,action)=>{
           state.Weight = action.payload;
        },
        setBody_Type: (state,action)=>{
            state.Body_Type = action.payload;
        },
        setSkin_Colour: (state,action)=>{
            state.Skin_Colour = action.payload;
        },
        setBlood_Group: (state,action)=>{
            state.Blood_Group = action.payload;
        },
    }
});

export const {setPhysical_Status,setHeight,setWeight,setBody_Type,setSkin_Colour,setBlood_Group}=personalSlice.actions;
export default personalSlice.reducer;

