import React, { useState } from "react";
import marriage from "../assets/mrg1.jpeg";
import { useSelector,useDispatch } from "react-redux";
import { setFather_Name,setFather_Status,setMother_Name,setMother_Status,setSiblings,setSocial_Type,setNative} from "../slices/familySlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "./Navbar";
import { useTranslation } from "react-i18next";

const FamilyDetails = () => {
  const navigate = useNavigate();
  const {Father_Name,Father_Status,Mother_Name,Mother_Status,Siblings,Social_Type,Native}=useSelector((state)=>state.familyInfo);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");
  const {t}=useTranslation();


  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});

    const newErrors = {}; 

    if (!Father_Name) {
      newErrors.Father_Name = "Father Name is required";
    }
    if (!Mother_Name) {
      newErrors.Mother_Name = "Mother Name is required";
    }
    if (!Father_Status || Father_Status === "select") {
      newErrors.Father_Status = "Father Status is required";
    }
    if (!Mother_Status || Mother_Status === "select") {
      newErrors.Mother_Status = "Mother Status is required";
    }
    if (!Social_Type || Social_Type === "select") {
      newErrors.Social_Type = "Social Type is required";
    }

    if (!Native) {
      newErrors.Native = "Native is required";
    }
    if (!Siblings) {
      newErrors.Siblings = "Siblings is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if (Object.keys(newErrors).length === 0) {
      try{
        const payload = {Father_Name,
          Father_Status,
          Mother_Name,
          Mother_Status,
          Social_Type,
          Native,
          Siblings   }
        const response = await axios.post('http://localhost:8000/familydetails',payload);  

        console.log("Response",response.data) 
        dispatch(setFather_Name(""));
        dispatch(setFather_Status(""));
        dispatch(setMother_Name(""));
        dispatch(setMother_Status(""));
        dispatch(setNative(""));
        dispatch(setSiblings(""));
        dispatch(setSocial_Type(""));    
        navigate('/assetdetails');
      

        }catch(error) {
        console.log('Error submitting form', error)
        alert('Form submission failed');
      }            
    }    

  
    setErrors({});

  };
  return (
    <>
    <Navbar/>
      <div
        className="personal-details vh-100 col-md-12"
        style={{
          backgroundImage: `url(${marriage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <form className="form w-80  p-5" onSubmit={handleSubmit}>
          <h4 className="heading bg-danger mb-3">{t('Family Details')}</h4>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Father_Name" className="col-md-12 col-lg-12  col-xs-12 col-sm-12">
              {t('Father Name')}
            </label>
            <input
              type="text"
              className="w-100"
              name="Father_Name"
              id="Father_Name"
              placeholder="Name"
              value={Father_Name}
              onChange={(e) =>  dispatch(setFather_Name(e.target.value))}
            />
            {errors.Father_Name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Father_Name}
              </div>
            )}
          </div>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Father_Status" className="w-100">
              {t('Father Status')}
            </label>
            <select
              name="Father_Status"
              id="Father_Status"
              className="w-100 p-1"
              value={Father_Status}
              onChange={(e) =>  dispatch(setFather_Status(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Alive">{t('Alive')}</option>
              <option value="Late"> {t('Late')}</option>
              <option value="Dontknow">{t("Don't Know")}</option>
            </select>
            {errors.Father_Status && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Father_Status}
              </div>
            )}
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label
              htmlFor="Mother_Name"
              className="col-md-12 col-lg-12 col-sm-12"
            >
              {t('Mother Name')}
            </label>
            <input
              type="text"
              className="w-100"
              name="Mother_Name"
              id="Mother_Name"
              placeholder="Name"
              value={Mother_Name}
              onChange={(e) =>  dispatch(setMother_Name(e.target.value))}
            />
            {errors.Mother_Name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Mother_Name}
              </div>
            )}
          </div>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Mother_Status" className="w-100">
              {t('Mother Status')}
            </label>
            <select
              name="Mother_Status"
              id="Mother_Status"
              className="w-100 p-1"
              value={Mother_Status}
              onChange={(e) =>  dispatch(setMother_Status(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Alive">{t('Alive')}</option>
              <option value="Late"> {t('Late')}</option>
              <option value="Dontknow">{t("Don't Know")}</option>
            </select>
            {errors.Mother_Status && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Mother_Status}
              </div>
            )}
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Social_Type" className="w-100">
              {t('Social Type')}
            </label>
            <select
              name="Social_Type"
              id="Social_Type"
              className="w-100 p-1"
              value={Social_Type}
              onChange={(e) =>  dispatch(setSocial_Type(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Lower_Middle_Class">{t('Lower Middle Class')}</option>
              <option value="Middle">{t('Middle Class')}</option>
              <option value="V.I.P">{t('V.I.P')}</option>
              <option value="V.V.I.P">{t('V.V.I.P')}</option>
              <option value="Royal"> {t('Royal')}</option>
              <option value="dontknow">{t("Don't Know")}</option>
            </select>
            {errors.Social_Type && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Social_Type}
              </div>
            )}
          </div>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Native" className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              {t('Native')}
            </label>
            <input
              type="text"
              className="w-100"
              name="Native"
              id="Native"
              placeholder="Native Place"
              value={Native}
              onChange={(e) =>  dispatch(setNative(e.target.value))}
            />
            {errors.Native && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Native}
              </div>
            )}
          </div>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Siblings" className="col-md-12 col-lg-12 col-sm-12">
              {t('Siblings')}
            </label>
            <input
              type="text"
              className="w-100"
              name="Siblings"
              id="Siblings"
              placeholder="Siblings"
              value={Siblings}
              onChange={(e) =>  dispatch(setSiblings(e.target.value))}
            />
            {errors.Siblings && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Siblings}
              </div>
            )}
          </div>

          <button
            type="submit"
            name="submit-btn"
            className="btn btn-danger w-100"
          >
           {t('Next')}
          </button>
        </form>
      </div>
    </>
  );
};

export default FamilyDetails;
