import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { setEducation,setCourse,setEmployed_In,setWork_Details,setMonthly_Income,setLanguages_Known} from '../slices/professionalSlice';
import { useNavigate } from "react-router-dom";
import marriage from "../assets/mrg.jpeg";
import Navbar from "./Navbar";
import { useTranslation } from "react-i18next";


const ProfessionalDetails = () => {

  const {Education,Course,Employed_In,Work_Details,Monthly_Income,Languages_Known} = useSelector((state) => state.professionalInfo) || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const {t}=useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});

    const newErrors = {};

    if (!Work_Details) {
      newErrors.Work_Details = "Work Details is required";
    }

    if (!Education || Education === "select") {
      newErrors.Education = "Education is required";
    }
    if (!Employed_In || Employed_In === "select") {
      newErrors.Employed_In = "Employed In is required";
    }
    if (!Languages_Known || Languages_Known === "select") {
      newErrors.Languages_Known = "Languages Known is required";
    }
    if (!Monthly_Income || Monthly_Income === "select") {
      newErrors.Monthly_Income = "Monthly Income is required";
    }
    if (!Course || Course === "select") {
      newErrors.Course = "Course is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }


    try {
      const payload = {Education,Course,Employed_In,Work_Details,Monthly_Income,Languages_Known};

      const response = await axios.post(
        "http://localhost:8000/professionaldetails",
        payload
      );
      console.log("Response:", response.data);
      navigate('/locationdetails');
      
      dispatch(setEducation(""));
      dispatch(setCourse(""));
      dispatch(setEmployed_In(""));
      dispatch(setWork_Details(""));
      dispatch(setMonthly_Income(""));
      dispatch(setLanguages_Known(""));


    } catch (error) {
      console.error("Error:", error);
      alert('Form submission failed');
    }
  };

  return (
    <>
     <Navbar/>
        <div
        className="form-container vh-100 col-md-12"
        style={{
          backgroundImage: `url(${marriage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
    <form className="form w-80  p-5" onSubmit={handleSubmit}>
          <h4 className="heading bg-danger mb-4">{t('Professional Details')}</h4>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Education" className="w-100">
              {t('Education')}
            </label>
            <select
              name="Education"
              id="Education"
              className="w-100 p-1"
              value={Education}
              onChange={(e) => dispatch(setEducation(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="No_Education">{t('No Education')}</option>
              <option value="Below_10th">{t('Below 10th')}</option>
              <option value="10th">{t('10th')}</option>
              <option value="12th">{t('12th')}</option>
              <option value="Diploma_or_ITI">{t('Diploma or ITI')}</option>
              <option value="Under_Graduate">{t('Under Graduate')}</option>
              <option value="Post_Graduate">{t('Post Graduate')}</option>
              <option value="Medical">{t('Medical')}</option>
              <option value="Engineering">{t('Engineering')}</option>
              <option value="Doctor">{t('Doctor')}</option>
              <option value="Others">{t('Others')}</option>
            </select>
            {errors.Education && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Education}
              </div>
            )}
          </div>
          {Education === "Under_Graduate" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Course" className="w-100">
                {t('Course')}
              </label>
              <select
                name="Course"
                id="Course"
                className="w-100 p-1"
                value={Course}
                onChange={(e) => dispatch(setCourse(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Bachelor of Science">Bachelor of Science</option>
                <option value="Bachelor of Arts ">Bachelor of Arts </option>
                <option value="Bachelor of Business Administration">
                  Bachelor of Business Administration
                </option>
              </select>
              {errors.Course && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.Course}
                </div>
              )}
            </div>
          )}

          {Education === "Post_Graduate" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Course" className="w-100">
                {t('Course')}
              </label>
              <select
                name="Course"
                id="Course"
                className="w-100 p-1"
                value={Course}
                onChange={(e) => dispatch(setCourse(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Master of Science">Master of Science</option>
                <option value="Master of Arts ">Master of Arts </option>
                <option value="Master of Business Administration">
                  Master of Business Administration
                </option>
              </select>
              {errors.Course && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.Course}
                </div>
              )}
            </div>
          )}

          {Education === "Medical" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Course" className="w-100">
                {t('Course')}
              </label>
              <select
                name="Course"
                id="Course"
                className="w-100 p-1"
                value={Course}
                onChange={(e) => dispatch(setCourse(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Anatomy">Anatomy</option>
                <option value="Physiology">Physiology</option>
                <option value="Biochemistry">Biochemistry </option>
              </select>
              {errors.Course && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.Course}
                </div>
              )}
            </div>
          )}

          {Education === "Engineering" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Course" className="w-100">
                {t('Course')}
              </label>
              <select
                name="Course"
                id="Course"
                className="w-100 p-1"
                value={Course}
                onChange={(e) => dispatch(setCourse(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Civil Engineering">Civil Engineering</option>
                <option value="Mechanical Engineering">
                  Mechanical Engineering
                </option>
                <option value="Electrical Engineering">
                  Electrical Engineering
                </option>
                <option
                  value="Aerospace Engineering
"
                >
                  Aerospace Engineering
                </option>
              </select>
              {errors.Course && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.Course}
                </div>
              )}
            </div>
          )}

          {Education === "Doctor" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Course" className="w-100">
                {t('Course')}
              </label>
              <select
                name="Course"
                id="Course"
                className="w-100 p-1"
                value={Course}
                onChange={(e) => dispatch(setCourse(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="MD in Psychiatry">MD in Psychiatry</option>
                <option value="MS in Plastic Surgery">
                  MS in Plastic Surgery
                </option>
                <option value="DDS/DMD in Dentistry">
                  DDS/DMD in Dentistry
                </option>
                <option value="PharmD in Pharmacy">PharmD in Pharmacy </option>
              </select>
              {errors.Course && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.Course}
                </div>
              )}
            </div>
          )}

          {(Education === "Below_10th" ||
            Education === "10th" ||
            Education === "12th" ||
            Education === "Others" ||
            Education === "Diploma_or_ITI" ||
            Education === "No_Education") && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Course" className="w-100">
                {t('Course')}
              </label>
              <select
                name="Course"
                id="Course"
                className="w-100 p-1"
                value={Course}
                onChange={(e) => dispatch(setCourse(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="None">None</option>
              </select>
              {errors.Course && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.Course}
                </div>
              )}
            </div>
          )}

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Employed_In" className="w-100">
              {t('Employed In')}
            </label>
            <select
              name="Employed_In"
              id="Employed_In"
              className="w-100 p-1"
              value={Employed_In}
              onChange={(e) => dispatch(setEmployed_In(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Agriculture">{t('Agriculture')}</option>
              <option value="Doctor">{t('Doctor')}</option>
              <option value="Government_Job">{t('Government Job')}</option>
              <option value="Software">{t('Software')}</option>
              <option value="Entrepreneur">{t('Entrepreneur')}</option>
              <option value="Private_Job">{t('Private Job')}</option>
              <option value="Studying">{t('Still Studying')}</option>
              <option value="Not_at_work">{t('Not at Work')}</option>
            </select>
            {errors.Employed_In && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Employed_In}
              </div>
            )}
          </div>
          <div className="form-group mb-3 col-xs-4">
            <label htmlFor="Work_Details" className="col-md- col-lg-4 col-sm-4 col-xs-12">
              {t('Work Details')}
            </label>
            <input
              type="text"
              className="w-100"
              name="Work_Details"
              placeholder="Work Details"
              id="Work_Details"
              value={Work_Details}
              onChange={(e) => dispatch(setWork_Details(e.target.value))}
            />
            {errors.Work_Details && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Work_Details}
              </div>
            )}
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Annual Income" className="w-100">
              {t('Monthly Income')}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-currency-rupee"
                viewBox="0 0 16 16"
              >
                <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
              </svg>
            </label>
            <select
              name="Monthly_Income"
              id="Monthly_Income"
              className="w-100 p-1"
              value={Monthly_Income}
              onChange={(e) => dispatch(setMonthly_Income(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="10,000-15,000">10,000-15,000</option>
              <option value="15,000-30,000">15,000-30,000</option>
              <option value="30,000-50,000">30,000-50,000</option>
              <option value="50,000-1 Lakhs">50,000-1Lakhs</option>
              <option value="1Lakhs-3Lakhs">1 {t('Lakhs')}-3 {t('Lakhs')}</option>
              <option value="3Lakhs_and_Above">3 {t('Lakhs')} {t('and')} {t('Above')}</option>
            </select>
            {errors.Monthly_Income && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Monthly_Income}
              </div>
            )}
          </div>
          <div className="form-group mb-3 col-xs-1">
            <label
              htmlFor="Languages_Known"
              className="col-md-12 col-lg-12 col-sm-12"
            >
              {t('Languages Known')}
            </label>
            <input
              type="text"
              className="w-100"
              name="Languages_Known"
              id="Languages_Known"
              placeholder="Languages Known"
              value={Languages_Known}
              onChange={(e) => dispatch(setLanguages_Known(e.target.value))}
            />
            {errors.Languages_Known && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors.Languages_Known}
              </div>
            )}
          </div>

          <button
            type="submit"
            name="submit-btn"
            className="btn btn-danger w-100"
          >
            {t('Next')}
          </button>
        </form>
        </div></>

  );
};

export default ProfessionalDetails;
