import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    username: "",
    email: "",
    password: "",
  
}

export const loginSlice = createSlice({
    name: 'loginInfo',
    initialState,
    reducers:{
        
        setUsername: (state,action)=>{
            state.username = action.payload;
        },
        setEmail: (state,action)=>{
            state.email = action.payload;
        },
        setPassword:(state,action)=>{
           state.password = action.payload;
        },
        logout: (state) => {
            state.username = "";
            state.email = "";
            state.password = "";
          }
    }
});

export const {setUsername,setEmail,setPassword,logout}=loginSlice.actions;

export default loginSlice.reducer;

