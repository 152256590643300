import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import boy from '../assets/boy.jpg';
import { setUsers } from "../slices/HomepageSlice";
import axios from 'axios';
import Navbar from './Navbar';
import { useTranslation } from 'react-i18next';
import { setName, setAge, setMarital_Status, setProfile_Created_By, setProfile_Image } from "../slices/basicSlice";
import { useNavigate } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Importing Bootstrap Icons
import { setBody_Type, setHeight, setPhysical_Status, setSkin_Colour, setWeight } from '../slices/personalSlice';
import { setEducation, setEmployed_In, setMonthly_Income } from '../slices/professionalSlice';
import { setKuladeivam, setSub_Caste } from '../slices/religiousSlice';
import { setCountry, setDistrict, setState } from '../slices/locationSlice';
import { setFather_Name, setFather_Status, setMother_Name, setMother_Status, setSiblings, setSocial_Type } from '../slices/familySlice';
import { setAssetDetail, setAssetValue, setSeimurai } from '../slices/assetSlice';
import { setWork_Place } from '../slices/expectationSlice';

const MyProfile = () => {
  const { email } = useSelector(state => state.loginInfo);
  const { Name, Age, Marital_Status, Profile_Created_By } = useSelector((state) => state.basicInfo);
  const { Asset_Detail, Asset_Value, Seimurai } = useSelector((state) => state.assetInfo);
  const {Country,State,District}=useSelector((state)=>state.locationInfo);
  const {Sub_Caste,Sub_Caste_Type,Kuladeivam,Place} = useSelector((state) => state.religiousInfo) || {};
  const {Father_Name,Father_Status,Mother_Name,Mother_Status,Siblings,Social_Type,Native}=useSelector((state)=>state.familyInfo);
  const {Education,Course,Employed_In,Work_Details,Monthly_Income,Languages_Known} = useSelector((state) => state.professionalInfo) || {};
  const {Physical_Status,Height,Weight,Body_Type,Skin_Colour,Blood_Group}=useSelector((state)=>state.personalInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editableUser, setEditableUser] = useState({});
  const [isEditMode, setIsEditMode] = useState({});
  const [Profile_Image,setProfile_Created_By]=useState("");
  const [userId, setUserId] = useState(null); // State to hold userId
  const {t} = useTranslation();


  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     const storedEmail = email || sessionStorage.getItem("signupEmail");
  //     if (!storedEmail) {
  //       console.error("No email found for fetching user data.");
  //       return;
  //     }

  //     if (email) {
  //       sessionStorage.removeItem("signupEmail");
  //     }

  //     try {
  //       const response = await axios.get('http://localhost:8000/getbyEmailId', {
  //         params: { email: storedEmail }
  //       });
  //       const userData = response.data[0][0]; 
  //       dispatch(setUsers(response.data)); // Set the users in Redux state
  //       setEditableUser(userData); // Set local state with the fetched user data
  //       setUserId(userData.ID); // Set userId from fetched data
  //     } catch (error) {
  //       console.error("Error occurred while fetching users:", error);
  //     }
  //   };

  //   fetchUsers();
  // }, [email, dispatch]);
  
  useEffect(() => {
    const fetchUsers = async () => {
      const storedEmail = email || sessionStorage.getItem("signupEmail");
      if (!storedEmail) {
        console.error("No email found for fetching user data.");
        return;
      }

      if (email) {
        sessionStorage.removeItem("signupEmail");
      }
      try {
        const response = await axios.get('http://localhost:8000/getbyEmailId', {
          params: { email: storedEmail }
        });
        const userData = response.data[0][0]; 
        dispatch(setUsers(response.data)); 
        setEditableUser(userData); 
        setUserId(userData.ID); 
        console.log("users data",userData)
         
  
        } catch (error) {
          console.error("Error occurred while fetching users:", error);
        }
      };
  
      fetchUsers();
    }, [email, dispatch]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableUser({
      ...editableUser,
      [name]: value
    });
    if (name === "Marital_Status") {
      dispatch(setMarital_Status(value));
    }   

    if (name === "Age") {
      dispatch(setAge(value));
    }
    if (name === "Basic_Name") {
      dispatch(setName(value));
    }

    if(name === "Physical_Status"){
      dispatch(setPhysical_Status(value));
    }

    if(name === "Height"){
      dispatch(setHeight(value))
    }

    if(name === "Weight"){
      dispatch(setWeight(value))
    }

    if(name === "Body_Type"){
      dispatch(setBody_Type(value))
    }

    if(name === "Skin_Colour"){
      dispatch(setSkin_Colour(value))
    }

    if(name === "Education"){
      dispatch(setEducation(value))
    }
    if(name === "Work_Place"){
      dispatch(setWork_Place(value))
    }
    if(name === "Sub_Caste"){
      dispatch(setSub_Caste(value))
    }

    if(name === "Kuladeivam"){
      dispatch(setKuladeivam(value))
    }

    if(name === "Employed_In"){
      dispatch(setEmployed_In(value))
    }
   
  
    if(name === "Country"){
      dispatch(setCountry(value))
    }

    if(name === "Work_Place"){
      dispatch(setWork_Place(value))
    }

    if(name === "State"){
      dispatch(setState(value))
    }
    if(name === "District"){
      dispatch(setDistrict(value))
    }
    if(name === "Father_Name"){
      dispatch(setFather_Name(value))
    }
    if(name === "Father_Status"){
      dispatch(setFather_Status(value))
    }
    if(name === "Mother_Status"){
      dispatch(setMother_Status(value))
    }
    if(name === "Mother_Name"){
      dispatch(setMother_Name(value))
    }
    if(name === "Social_Type"){
      dispatch(setSocial_Type(value))
    }
    if(name === "Siblings"){
      dispatch(setSiblings(value))
    }
    if(name === "Assets_detail"){
      dispatch(setAssetDetail(value))
    }
    if(name === "Assets_Value"){
      dispatch(setAssetValue(value))
    }
    if(name === "Seimurai"){
      dispatch(setSeimurai(value))
    }
    if(name === "Monthly_Income"){
      dispatch(setMonthly_Income(value))
    }
    if(name === "Profile_Image"){
      dispatch(setProfile_Image(value))
    }
  };

  const saveChanges = async (field) => {
    const fieldsToUpdate = {
      'Basic_Name': 'Name',
      'Age': 'Age',
      'Marital_Status': 'Marital_Status',
      'Profile_Created_By': 'Profile_Created_By',
      'Physical_Status': 'Physical_Status',
      'Height':'Height',
      'Weight':'Weight',
      'Body_Type':'Body_Type',
      'Skin_Colour':'Skin_Colour',
      'Education':'Education',
      'Work_Place':'Work_Place',
      'Sub_Caste':'Sub_Caste',
      'Kuladeivam':'Kuladeivam',
      'Place':'Place',
      'Country':'Country',
      'State':'State',
      'District':'District',
      'Father_Name':'Father_Name',
      'Father_Status':'Father_Status',
      'Mother_Status':'Mother_Status',
      'Mother_Name':'Mother_Name',
      'Social_Type':'Social_Type',
      'Siblings':'Siblings',
      'Assets_detail':'Assets_detail',
      'Assets_Value':'Assets_Value',
      'Seimurai':'Seimurai',
      'Employed_In':'Employed_In',
      'Monthly_Income':'Monthly_Income',
      'Profile_Image':'Profile_Image',
    };

    const newValue = editableUser[field];  // Only update the field that was edited

    try {
      console.log(`Updating ${fieldsToUpdate[field]} to ${newValue} for userId: ${userId}`);

      const response = await axios.put('http://localhost:8000/UpdateAllInOneTable', {
        fieldName: fieldsToUpdate[field],
        newValue: newValue,
        idField: 'ID',
        idValue: userId
      });

      if (response.status === 200) {
        switch (fieldsToUpdate[field]) {
          case 'Name':
            dispatch(setName(newValue));
            break;
          case 'Age':
            dispatch(setAge(newValue));
            break;
          case 'Marital_Status':
            dispatch(setMarital_Status(newValue));
            break;
          case 'Profile_Created_By':
            dispatch(setProfile_Created_By(newValue));
            break;
          case 'Height':
            dispatch(setHeight(newValue));
            break;
            case 'Physical_Status':
            dispatch(setPhysical_Status(newValue));
            break;
          case 'Weight':
            dispatch(setWeight(newValue));
            break;
          case 'Body_Type':
            dispatch(setBody_Type(newValue));
            break;
          case 'Skin_Colour':
            dispatch(setSkin_Colour(newValue));
            break;
          case 'Education':
            dispatch(setEducation(newValue));
            break;
            case 'Employed_In':
              dispatch(setEmployed_In(newValue));
              break;
          case 'Sub_Caste':
            dispatch(setSub_Caste(newValue));
            break;
            case 'Monthly_Income':
              dispatch(setMonthly_Income(newValue));
              break;
          case 'Kuladeivam':
            dispatch(setKuladeivam(newValue));
            break;
          case 'Country':
             dispatch(setCountry(newValue));
             break;
          case 'State':
            dispatch(setState(newValue));
            break;
          case 'District':
            dispatch(setDistrict(newValue));
            break;
          case 'Father_Name':
            dispatch(setFather_Name(newValue));
            break;
          case 'Father_Status':
            dispatch(setFather_Status(newValue));
            break;
          case 'Mother_Name':
            dispatch(setMother_Name(newValue));
            break;
          case 'Mother_Status':
            dispatch(setMother_Status(newValue));
            break;
          case 'Social_Type':
            dispatch(setSocial_Type(newValue));
            break;
          case 'Siblings':
            dispatch(setSiblings(newValue));
            break;
          case 'Assets_detail':
            dispatch(setAssetDetail(newValue));
            break;
          case 'Assets_Value':
            dispatch(setAssetValue(newValue));
            break;
          case 'Seimurai':
            dispatch(setSeimurai(newValue));
            break;  
          default:
            break;
        }
        setIsEditMode({ ...isEditMode, [field]: false });
        alert(`${fieldsToUpdate[field]} updated successfully!`);
      } else {
        console.error(`Failed to update ${fieldsToUpdate[field]}:, response.data`);
      }
    } catch (error) {
      console.error("Error updating field:", error.response ? error.response.data : error.message);
      alert(`Failed to update the profile. Error: ${error.response ? error.response.data.error : error.message}`);
    }
  };

  const handleEditToggle = (field) => {
    setIsEditMode({
      ...isEditMode,
      [field]: !isEditMode[field]
    });
  };

  const users = useSelector(state => state.HomepageInfo.users);
  const user = users && users.length > 0 ? users[0][0] : null;

  if (!user) {
    navigate('/signup');
    return null;
  }

  return (
    <>
      <Navbar />
      <div className="profile-container">
        <div className="profile-left">
        {editableUser.Profile_Image && (
        <img
          src={`data:image/jpeg;base64,${editableUser.Profile_Image}`} alt="Profile"
          style={{ width: '150px', height: '150px',textAlign:'center' }} 
        />
      )}
          <div className="card-body text-center">
            <p>
              <b className='contents text-center'>{editableUser.Basic_Name} Profile</b>
            </p>
          </div>
        </div>

        <div className="profile-right">
          <div className="card-body">
            <h4 className="headings bg-danger mb-4">Basic Details</h4>

            <div className='row'>
              <div className='col-xs-12 col-sm-6'>
                <p><b className="labels">{t('Name')}:</b>
                  {isEditMode.Basic_Name ? (
                    <input
                      type="text"
                      className="contents"
                      name="Basic_Name"
                      value={editableUser.Basic_Name || ''}
                      onChange={handleChange}
                      onBlur={() => saveChanges("Basic_Name")}  
                    />
                  ) : (
                    <span className='contents'>{editableUser.Basic_Name}</span>
                  )}
                  <i
                    className={`bi ${isEditMode.Basic_Name ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                    onClick={() => handleEditToggle("Basic_Name")}
                  ></i>
                </p>
              </div>

              <div className='col-xs-12 col-sm-6'>
                <p><b className="labels">{t('Marital Status')}:</b>
                  {isEditMode.Marital_Status ? (
                    <select
                      name="Marital_Status"
                      className="w-100 p-1"
                      value={editableUser.Marital_Status}
                      onChange={handleChange}
                      onBlur={() => saveChanges("Marital_Status")}
                    >
                      <option value="select">Select--</option>
                      <option value="UnMarried">UnMarried</option>
                      <option value="Widow">Widow</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Awaiting Divorce">Awaiting Divorce</option>
                    </select>
                  ) : (
                    <span className='contents'>{editableUser.Marital_Status}</span>
                  )}
                  <i
                    className={`bi ${isEditMode.Marital_Status ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                    onClick={() => handleEditToggle("Marital_Status")}
                  ></i>
                </p>
              </div>

              <div className='col-xs-12 col-sm-6'>
                <p><b className="labels">{t('Age')}:</b>
                  {isEditMode.Age ? (
                    <input
                      type="number"
                      className="contents"
                      name="Age"
                      value={editableUser.Age || ''}
                      onChange={handleChange}
                      onBlur={() => saveChanges("Age")}
                    />
                  ) : (
                    <span className='contents'>{editableUser.Age}</span>
                  )}
                  <i
                    className={`bi ${isEditMode.Age ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                    onClick={() => handleEditToggle("Age")}
                  ></i>
                </p>
              </div>
              <div className='col-xs-12 col-sm-6'>
                <p><b className="labels">{t('Profile Created By')}:</b>
                  {isEditMode.Profile_Created_By ? (
                    <select
                      name="Profile_Created_By"
                      className="w-100 p-1"
                      value={editableUser.Profile_Created_By}
                      onChange={handleChange}
                      onBlur={() => saveChanges("Profile_Created_By")}
                    >
                      <option value="select">Select--</option>
                      <option value="Self">Self</option>
                      <option value="Sibling">Sibling</option>
                      <option value="Friend">Friend</option>
                      <option value="Parents">Parents</option>
                      <option value="Others">Others</option>
                    </select>
                  ) : (
                    <span className='contents'>{editableUser.Profile_Created_By}</span>
                  )}
                  <i
                    className={`bi ${isEditMode.Profile_Created_By ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                    onClick={() => handleEditToggle("Profile_Created_By")}
                  ></i>
                </p>
              </div>
            </div>
          </div>
        </div>       
       </div> 
        <div className="profile-right">
          <div className="card-body">
          <h4 className="headings bg-danger mb-4">{t('Personal Details')}</h4>
              <div className="card-body">
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('Physical Status')}: </b>
              {isEditMode.Physical_Status ? (
                      <select
                      name="Physical_Status"
                      id="Physical_Status"
                      className="w-100 p-1"
                      value={Physical_Status}
                      onChange={(e) => dispatch(setPhysical_Status(e.target.value))}
                      required
                    >
                      <option value="select">Select--</option>
                      <option value="Normal">{t('Normal')}</option>
                      <option value="Physically Challenged">
                        {t('Physically Challenged')}
                      </option>
                    </select>
              ) : (
                <span className='contents'>{editableUser.Physical_Status}</span>
              )}
              <i
                className={`bi ${isEditMode.Physical_Status ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("Physical_Status")}
              ></i>
            </p>
          </div>

          <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('Height')}:</b>
              {isEditMode.Height ? (
                <select
                  name="Height"
                  className="w-100 p-1"
                  value={editableUser.Height}
                  onChange={handleChange}
                  onBlur={() => saveChanges("Height")}
                >
                 <option value="select">Select--</option>
              <option value="4ft 6in /137cms">4ft 6in /137cms</option>
              <option value="4ft 7in /138cms">4ft 7in /138cms</option>
              <option value="4ft 8in /139cms">4ft 8in /139cms</option>
              <option value="4ft 9in /140cms">4ft 9in /140cms</option>
                </select>
              ) : (
                <span className='contents'>{editableUser.Height}</span>
              )}
              <i
                className={`bi ${isEditMode.Height ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("Height")}
              ></i>
            </p>
          </div>

          <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('Weight')}:</b>
              {isEditMode.Weight ? (
                <select
                  name="Weight"
                  className="w-100 p-1"
                  value={editableUser.Weight}
                  onChange={handleChange}
                  onBlur={() => saveChanges("Weight")}
                >
                   <option value="select">Select--</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
              <option value="46">46</option>
              <option value="47">47</option>
              <option value="48">48</option>
              <option value="49">49</option>
              <option value="50">50</option>
                </select>
              ) : (
                <span className='contents'>{editableUser.Weight}</span>
              )}
              <i
                className={`bi ${isEditMode.Weight ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("Weight")}
              ></i>
            </p>
          </div>

          <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('Body Type')}:</b>
              {isEditMode.Body_Type ? (
                <select
                  name="Body_Type"
                  className="w-100 p-1"
                  value={editableUser.Body_Type}
                  onChange={handleChange}
                  onBlur={() => saveChanges("Body_Type")}
                >
              <option value="select">Select--</option>
              <option value="Thin">{t('Thin')}</option>
              <option value="Normal">{t('Normal')}</option>
              <option value="Fat">{t('Fat')}</option>
              <option value="Slightly Thin">{t('Slightly Thin')}</option>
              <option value="Slightly Fat">{t('Slightly Fat')}</option>
                </select>
              ) : (
                <span className='contents'>{editableUser.Body_Type}</span>
              )}
              <i
                className={`bi ${isEditMode.Body_Type ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("Body_Type")}
              ></i>
            </p>
          </div>

          <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('Skin Colour')}:</b>
              {isEditMode.Skin_Colour ? (
                <select
                  name="Skin_Colour"
                  className="w-100 p-1"
                  value={editableUser.Skin_Colour}
                  onChange={handleChange}
                  onBlur={() => saveChanges("Skin_Colour")}
                >
            <option value="select">Select--</option>
              <option value="Fair">{t('Fair')}</option>
              <option value="White">{t('White')}</option>
              <option value="Wheatish Brown">{t('Wheatish Brown')}</option>
              <option value="Dark">{t('Dark')}</option>
              <option value="Dusky">{t('Dusky')}</option>  
                </select>
              ) : (
                <span className='contents'>{editableUser.Skin_Colour}</span>
              )}
              <i
                className={`bi ${isEditMode.Skin_Colour ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("Skin_Colour")}
              ></i>
            </p>
          </div>
        </div>
      </div>
            </div>
        </div>
        
        <div className="profile-right">
          <div className="card-body">
          <h4 className="headings bg-danger mb-4">{t('Professional Details')}</h4>
              <div className="card-body">
        <div className='row'>
        <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('Degree')}: </b>
              {isEditMode.Education ? (
                      <select
                      name="Education"
                      id="Education"
                      className="w-100 p-1"
                      value={Education}
                      onChange={(e) => dispatch(setEducation(e.target.value))}
                      required
                    >
                     <option value="select">Select--</option>
              <option value="No_Education">{t('No Education')}</option>
              <option value="Below_10th">{t('Below 10th')}</option>
              <option value="10th">{t('10th')}</option>
              <option value="12th">{t('12th')}</option>
              <option value="Diploma_or_ITI">{t('Diploma or ITI')}</option>
              <option value="Under_Graduate">{t('Under Graduate')}</option>
              <option value="Post_Graduate">{t('Post Graduate')}</option>
              <option value="Medical">{t('Medical')}</option>
              <option value="Engineering">{t('Engineering')}</option>
              <option value="Doctor">{t('Doctor')}</option>
              <option value="Others">{t('Others')}</option>
                    </select>
              ) : (
                <span className='contents'>{editableUser.Education}</span>
              )}
              <i
                className={`bi ${isEditMode.Education ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("Education")}
              ></i>
            </p>
          </div>

          <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('Employed In')}:</b>
              {isEditMode.Employed_In ? (
                <select
                  name="Employed_In"
                  className="w-100 p-1"
                  value={editableUser.Employed_In}
                  onChange={handleChange}
                  onBlur={() => saveChanges("Employed_In")}
                >
                 <option value="select">Select--</option>
              <option value="Agriculture">{t('Agriculture')}</option>
              <option value="Doctor">{t('Doctor')}</option>
              <option value="Government_Job">{t('Government Job')}</option>
              <option value="Software">{t('Software')}</option>
              <option value="Entrepreneur">{t('Entrepreneur')}</option>
              <option value="Private_Job">{t('Private Job')}</option>
              <option value="Studying">{t('Still Studying')}</option>
              <option value="Not_at_work">{t('Not at Work')}</option>
                </select>
              ) : (
                <span className='contents'>{editableUser.Employed_In}</span>
              )}
              <i
                className={`bi ${isEditMode.Employed_In ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("Employed_In")}
              ></i>
            </p>
          </div>

          <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('Work Place')}:</b>
              {isEditMode.Work_Place ? (
                <select
                  name="Work_Place"
                  className="w-100 p-1"
                  value={editableUser.Work_Place}
                  onChange={handleChange}
                  onBlur={() => saveChanges("Work_Place")}
                >
                   <option value="select">Select--</option>
              <option value="Native">Native</option>
              <option value="Coimbatore">Coimbatore</option>
              <option value="Bengaluru">Bengaluru</option>
              <option value="Chennai">Chennai</option>
              <option value="Hyderabad">Hyderabad</option>
              <option value="Abroad">Abroad</option>
              <option value="Other_ State">Other State</option>
                </select>
              ) : (
                <span className='contents'>{editableUser.Work_Place}</span>
              )}
              <i
                className={`bi ${isEditMode.Work_Place ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("Work_Place")}
              ></i>
            </p>
          </div>
          <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('Monthly Income')}:</b>
              {isEditMode.Monthly_Income ? (
                <select
                  name="Monthly_Income"
                  className="w-100 p-1"
                  value={editableUser.Monthly_Income}
                  onChange={handleChange}
                  onBlur={() => saveChanges("Monthly_Income")}
                >
              <option value="select">Select--</option>
              <option value="10,000-15,000">10,000-15,000</option>
              <option value="15,000-30,000">15,000-30,000</option>
              <option value="30,000-50,000">30,000-50,000</option>
              <option value="50,000-1 Lakhs">50,000-1Lakhs</option>
              <option value="1Lakhs-3Lakhs">1 {t('Lakhs')}-3 {t('Lakhs')}</option>
              <option value="3Lakhs_and_Above">3 {t('Lakhs')} {t('and')} {t('Above')}</option>
                </select>
              ) : (
                <span className='contents'>{editableUser.Monthly_Income}</span>
              )}
              <i
                className={`bi ${isEditMode.Monthly_Income ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("Monthly_Income")}
              ></i>
            </p>
          </div>

          <div className="card-body">
      <h4 className="headings bg-danger mb-4">{t('Location Details')}</h4>
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('Country')}
            : </b>
              {isEditMode.Country ? (
                      <select
                      name="Country"
                      id="Country"
                      className="w-100 p-1"
                      value={Country}
                      onChange={(e) => dispatch(setCountry(e.target.value))}
                      required
                    >
              <option value="select">Select--</option>
              <option value="India">{t('India')}</option>
              <option value="AF">Afghanistan</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antarctica">Antarctica</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
                    </select>
              ) : (
                <span className='contents'>{editableUser.Country}</span>
              )}
              <i
                className={`bi ${isEditMode.Country ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("Country")}
              ></i>
            </p>
          </div>

          <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('State')}:</b>
              {isEditMode.State ? (
                <select
                  name="State"
                  className="w-100 p-1"
                  value={editableUser.State}
                  onChange={handleChange}
                  onBlur={() => saveChanges("State")}
                >
              <option value="select">Select--</option>
              <option value="Andhra_Pradesh">Andhra Pradesh</option>
              <option value="Arunachal_Pradesh">Arunachal Pradesh</option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chattisgarh">Chattisgarh</option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal_Pradesh">Himachal Pradesh</option>
              <option value="Jammu_and_Kashmir">Jammu and Kashmir</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">{t('Kerala')}</option>
              <option value="Madhya_Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil_Nadu">{t('Tamil Nadu')}</option>
              <option value="Telangana">{t('Telangana')}</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="Uttar_Pradesh">Uttar Pradesh</option>
              <option value="West_Bengal">West Bengal</option>
              <option value="Andaman_and_Nicobar_Islands">
                Andaman and Nicobar Islands
              </option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="DN">Daman and Diu</option>
              <option value="Delhi">{t('Delhi')}</option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Puducherry">{t('Puducherry')}</option>
                </select>
              ) : (
                <span className='contents'>{editableUser.State}</span>
              )}
              <i
                className={`bi ${isEditMode.State ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("State")}
              ></i>
            </p>
          </div>

          <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('District')}:</b>
              {isEditMode.District ? (
                <select
                  name="District"
                  className="w-100 p-1"
                  value={editableUser.District}
                  onChange={handleChange}
                  onBlur={() => saveChanges("District")}
                >
            <option value="select">Select--</option>
              <option value="Ariyalur">{t('Ariyalur')}</option>
              <option value="Chengalpattu">{t('Chengalpattu')}</option>
              <option value="Chennai">{t('Chennai')}</option>
              <option value="Coimbatore">{t('Coimbatore')}</option>
              <option value="Cuddalore">{t('Cuddalore')}</option>
              <option value="Dharmapuri">{t('Dharmapuri')}</option>
              <option value="Dindigul">{t('Dindigul')}</option>
              <option value="Erode">{t('Erode')}</option>
              <option value="Kallakurichi">{t('Kallakurichi')}</option>
              <option value="Kanchipuram">{t('Kanchipuram')}</option>
              <option value="Kanyakumari">{t('Kanyakumari')}</option>
              <option value="Karur">{t('Karur')}</option>
              <option value="Krishnagiri">{t('Krishnagiri')}</option>
              <option value="Madurai">{t('Madurai')}</option>
              <option value="Nagapattinam">{t('Nagapattinam')}</option>
              <option value="">{t('Mayiladuthurai')}</option>
              <option value="Namakkal">{t('Namakkal')}</option>
              <option value="Nilgiris">{t('Nilgiris')}</option>
              <option value="Perambalur">{t('Perambalur')}</option>
              <option value="Pudukkottai">{t('Pudukkottai')}</option>
              <option value="Ramanathapuram">{t('Ramanathapuram')}</option>
              <option value="Salem">{t('Salem')}</option>
              <option value="Sivaganga">{t('Sivaganga')}</option>
              <option value="Tenkasi">{t('Tenkasi')}</option>
              <option value="Thanjavur">{t('Thanjavur')}</option>
              <option value="Theni">{t('Theni')}</option>
              <option value="Thoothukudi">{t('Thoothukudi')}</option>
              <option value="Tiruchirappalli">{t('Tiruchirappalli')}</option>
              <option value="Tirunelveli">{t('Tirunelveli')}</option>
              <option value="Tirupathur">{t('Tirupathur')}</option>
              <option value="Tiruppur">{t('Tiruppur')}</option>
              <option value="Tiruvallur">{t('Tiruvallur')}</option>
              <option value="Tiruvannamalai">{t('Tiruvannamalai')}</option>
              <option value="Tiruvarur">{t('Tiruvarur')}</option>
              <option value="Vellore">{t('Vellore')}</option>
              <option value="Viluppuram">{t('Viluppuram')}</option>
              <option value="Virudhunagar">{t('Virudhunagar')}</option>
                </select>
              ) : (
                <span className='contents'>{editableUser.District}</span>
              )}
              <i
                className={`bi ${isEditMode.District ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("District")}
              ></i>
            </p>
          </div> 
          </div>
          </div>

          <div className="card-body">
      <h4 className="headings bg-danger mb-4">{t('Family Details')}</h4>

        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
                <p><b className="labels">{t('Father Name')}:</b>
                  {isEditMode.Father_Name ? (
                    <input
                      type="text"
                      className="contents"
                      name="Father_Name"
                      value={editableUser.Father_Name || ''}
                      onChange={handleChange}
                      onBlur={() => saveChanges("Father_Name")}  
                    />
                  ) : (
                    <span className='contents'>{editableUser.Father_Name}</span>
                  )}
                  <i
                    className={`bi ${isEditMode.Father_Name ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                    onClick={() => handleEditToggle("Father_Name")}
                  ></i>
                </p>
              </div>


              <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('Father Status')}:</b>
              {isEditMode.Father_Status ? (
                <select
                  name="Father_Status"
                  className="w-100 p-1"
                  value={editableUser.Father_Status}
                  onChange={handleChange}
                  onBlur={() => saveChanges("Father_Status")}
                >
               <option value="select">Select--</option>
              <option value="Alive">{t('Alive')}</option>
              <option value="Late"> {t('Late')}</option>
              <option value="Dontknow">{t("Don't Know")}</option>
                </select>
              ) : (
                <span className='contents'>{editableUser.Father_Status}</span>
              )}
              <i
                className={`bi ${isEditMode.Father_Status ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("Father_Status")}
              ></i>
            </p>
          </div>


          <div className='col-xs-12 col-sm-6'>
                <p><b className="labels"> {t('Mother Name')}
                :</b>
                  {isEditMode.Mother_Name ? (
                    <input
                      type="text"
                      className="contents"
                      name="Mother_Name"
                      value={editableUser.Mother_Name || ''}
                      onChange={handleChange}
                      onBlur={() => saveChanges("Mother_Name")}  
                    />
                  ) : (
                    <span className='contents'>{editableUser.Mother_Name}</span>
                  )}
                  <i
                    className={`bi ${isEditMode.Mother_Name ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                    onClick={() => handleEditToggle("Mother_Name")}
                  ></i>
                </p>
              </div>



              <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('Mother Status')}:</b>
              {isEditMode.Mother_Status ? (
                <select
                  name="Mother_Status"
                  className="w-100 p-1"
                  value={editableUser.Mother_Status}
                  onChange={handleChange}
                  onBlur={() => saveChanges("Mother_Status")}
                >
               <option value="select">Select--</option>
              <option value="Alive">{t('Alive')}</option>
              <option value="Late"> {t('Late')}</option>
              <option value="Dontknow">{t("Don't Know")}</option>
                </select>
              ) : (
                <span className='contents'>{editableUser.Mother_Status}</span>
              )}
              <i
                className={`bi ${isEditMode.Mother_Status ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("Mother_Status")}
              ></i>
            </p>
          </div>


          <div className='col-xs-12 col-sm-6'>
            <p><b className="labels">{t('Social Type')}
            :</b>
              {isEditMode.Social_Type ? (
                <select
                  name="Social_Type"
                  className="w-100 p-1"
                  value={editableUser.Social_Type}
                  onChange={handleChange}
                  onBlur={() => saveChanges("Social Type")}
                >
                <option value="select">Select--</option>
              <option value="Lower_Middle_Class">{t('Lower Middle Class')}</option>
              <option value="Middle">{t('Middle Class')}</option>
              <option value="V.I.P">{t('V.I.P')}</option>
              <option value="V.V.I.P">{t('V.V.I.P')}</option>
              <option value="Royal"> {t('Royal')}</option>
              <option value="dontknow">{t("Don't Know")}</option>
                </select>
              ) : (
                <span className='contents'>{editableUser.Social_Type}</span>
              )}
              <i
                className={`bi ${isEditMode.Social_Type ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                onClick={() => handleEditToggle("Social_Type")}
              ></i>
            </p>
          </div>


          <div className='col-xs-12 col-sm-6'>
                <p><b className="labels">{t('Siblings')}
                :</b>
                  {isEditMode.Siblings ? (
                    <input
                      type="text"
                      className="contents"
                      name="Siblings"
                      value={editableUser.Siblings || ''}
                      onChange={handleChange}
                      onBlur={() => saveChanges("Siblings")}  
                    />
                  ) : (
                    <span className='contents'>{editableUser.Siblings}</span>
                  )}
                  <i
                    className={`bi ${isEditMode.Siblings ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                    onClick={() => handleEditToggle("Siblings")}
                  ></i>
                </p>
              </div>

            
          </div>


            </div>



            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Assets Details')}</h4>

        <div className='row'>
        <div className='col-xs-12 col-sm-6'>
                <p><b className="labels"> {t('Assets Value')}
                :</b>
                  {isEditMode.Assets_Value ? (
                    <input
                      type="text"
                      className="contents"
                      name="Assets_Value"
                      value={editableUser.Assets_Value || ''}
                      onChange={handleChange}
                      onBlur={() => saveChanges("Assets_Value")}  
                    />
                  ) : (
                    <span className='contents'>{editableUser.Assets_Value}</span>
                  )}
                  <i
                    className={`bi ${isEditMode.Assets_Value ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                    onClick={() => handleEditToggle("Assets_Value")}
                  ></i>
                </p>
              </div>

              <div className='col-xs-12 col-sm-6'>
                <p><b className="labels">{t('Asset Details')}:</b>
                  {isEditMode.Assets_detail ? (
                    <input
                      type="text"
                      className="contents"
                      name="Assets_detail"
                      value={editableUser.Assets_detail || ''}
                      onChange={handleChange}
                      onBlur={() => saveChanges("Assets_detail")}  
                    />
                  ) : (
                    <span className='contents'>{editableUser.Assets_detail}</span>
                  )}
                  <i
                    className={`bi ${isEditMode.Assets_detail ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                    onClick={() => handleEditToggle("Assets_detail")}
                  ></i>
                </p>
              </div>
              <div className='col-xs-12 col-sm-6'>
                <p><b className="labels"> {t('Seimurai')}
                 :</b>
                  {isEditMode.Asset_Detail ? (
                    <input
                      type="text"
                      className="contents"
                      name="Seimurai"
                      value={editableUser.Seimurai || ''}
                      onChange={handleChange}
                      onBlur={() => saveChanges("Seimurai")}  
                    />
                  ) : (
                    <span className='contents'>{editableUser.Seimurai}</span>
                  )}
                  <i
                    className={`bi ${isEditMode.Seimurai ? 'bi-check-all' : 'bi-pencil-square'} ms-2`}
                    onClick={() => handleEditToggle("Seimurai")}
                  ></i>
                </p>
              </div>                      
          </div>
            </div>
        </div>
        </div>
        </div>
        </div>
    </>
  );
};

export default MyProfile;