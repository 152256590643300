import React from "react";
import marriage from "../assets/mrg1.jpeg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setAssetDetail, setAssetValue, setSeimurai } from "../slices/assetSlice";
import Navbar from "./Navbar";
import { useTranslation } from "react-i18next";

const AssetDetails = () => {
  const { Asset_Detail, Asset_Value, Seimurai } = useSelector((state) => state.assetInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = (event) => {
    event.preventDefault();
    const assetValue = Asset_Value.trim() === "" ? "null" : Asset_Value;
    const assetDetail = Asset_Detail.trim() === "" ? "null" : Asset_Detail;
    const seimurai = Seimurai.trim() === "" ? "null" : Seimurai;

    dispatch(setAssetValue(assetValue));
    dispatch(setAssetDetail(assetDetail));
    dispatch(setSeimurai(seimurai));

    console.log("Assets Values", assetValue);
    console.log("Assets Details", assetDetail);
    console.log("Seimurai", seimurai);
    navigate('/astrodetails');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const assetValue = Asset_Value.trim() === "" ? "null" : Asset_Value;
    const assetDetail = Asset_Detail.trim() === "" ? "null" : Asset_Detail;
    const seimurai = Seimurai.trim() === "" ? "null" : Seimurai;

    try {
      const payload = { Asset_Value: assetValue, Asset_Detail: assetDetail, Seimurai: seimurai };
      const response = await axios.post('http://localhost:8000/assetsdetails', payload);
      console.log("Response", response.data);

      navigate('/astrodetails');
      dispatch(setAssetDetail("null"));
      dispatch(setAssetValue("null"));
      dispatch(setSeimurai("null"));

    } catch (error) {
      console.error('Error submitting form', error);
      alert('Form submission failed');
    }
  };

  return (
    <>
      <Navbar />
      <div
        className="form-container vh-100 col-md-12"
        style={{
          backgroundImage: `url(${marriage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <form className="form w-80 p-5" onSubmit={handleSubmit}>
          <h4 className="heading bg-danger mb-4">Assets Details</h4>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Asset_Value" className="col-md-12 col-lg-12 col-sm-12">
              {t('Assets Value')}
            </label>
            <input
              type="text"
              className="w-100"
              name="Asset_Value"
              id="Asset_Value"
              placeholder="Assets Value"
              value={Asset_Value}
              onChange={(e) => dispatch(setAssetValue(e.target.value))}
            />
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Asset_Detail" className="col-md-12 col-lg-12 col-sm-12">
              {t('Asset Details')}
            </label>
            <textarea
              className="w-100"
              name="Asset_Detail"
              id="Asset_Detail"
              placeholder="Assets Details"
              value={Asset_Detail}
              onChange={(e) => dispatch(setAssetDetail(e.target.value))}
            />
          </div>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Seimurai" className="col-md-12 col-lg-12 col-sm-12">
              {t('Seimurai')}
            </label>
            <textarea
              className="w-100"
              name="Seimurai"
              id="Seimurai"
              placeholder="Seimurai Details"
              value={Seimurai}
              onChange={(e) => dispatch(setSeimurai(e.target.value))}
            />
          </div>

          <button type="submit" name="submit-btn" className="btn btn-danger w-30">
            {t('Next')}
          </button>
          <button type="button" name="btn" className="btn btn-danger w-30 mx-3" onClick={handleClick}>
            <Link
              to="/astrodetails"
              className="skip ms-2"
              style={{ textDecoration: "none", color: "white" }}
            >
              {t('Skip')}
            </Link>
          </button>
        </form>
      </div>
    </>
  );
};

export default AssetDetails;
