import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Home from './Components/Home';

const resources = {
  en: {
    translation: {
      welcome: "Welcome", 
      "Looking For": "Looking For",
      "User ID":"User ID",
      "Martial Status":"Marital Status",
      "Body Type":"Body Type",
      "Physical Status":"Physical Status",
      "Colour":"Skin Colour",
      "Personal ": "Personal Details",
      "Religious, Education and Job Details":"Religious, Education and Job Details",
      "Employed In":"Employed In",
      "Work Place":"Work Place",
      "Location ":"Location Details",
      "Astro " : "Astro Details",
      "Basic ":"Basic Details",
      "ProfileCB":"Profile Created By",
      "Education":"Degree",
      "Sub Caste":"Sub Caste",
      "Sub Caste Type":"Sub Caste Type",
      "Confirm Password":"Confirm Password",
      "Mother Tongue":"Mother Tongue",
      "Blood Group":"Blood Group",
      "Sub Sect" :"Sub Sect",
      "Languages Known":"Languages Known",
      "Work Details":"Work Details",
      "Professional Details":"Professional Details",
      "Monthly Income" : "Monthly Income",
      "Father Name" : "Father Name",
      "Father Status" : "Father Status",
      "Mother Name" : "Mother Name",
      "Mother Status" : "Mother Status",
      "Social Type":"Social Type",
      "Assets Details" : "Assets Details",
      "Assets Value" : "Assets Value",
      "Asset Details"  :"Asset Details",
      "Awaiting Divorce" : "Awaiting Divorce",
      "Physically Challenged" : "Physically Challenged",
      "Slightly Fat" : "Slightly Fat",
      "Slightly Thin" : "Slightly Thin",
      "Wheatish Brown" : "Wheatish Brown",
      "No Education" :"No Education",
      "Below 10th" : "Below 10th",
      "10th":"10th",
      "12th" : "12th",
      "Under Graduate" : "Under Graduate",
      "Post Graduate" : "Post Graduate",
      "Government Job" :"Government Job",
      "Private Job" :"Private Job",
      "Not at Work":"Not at Work",
      "Still Studying":"Still Studying",
      "Tamil Nadu":"Tamil Nadu",
      "Family Details": "Family Details",
     "Don't Know":"Don't Know",
     "Diploma or ITI" :"Diploma or ITI",
      "Lower Middle Class" :"Lower Middle Class",
      "Middle Class" :"Middle Class",
      "Taurus-Risabham" :"Taurus-Risabham",


    }
  },
  ta: {
    translation: {
      welcome: "வரவேற்கிறோம்", 
      matrimony :"திருமண தகவலஂ மையமஂ",
      Age:"வயது",
      Education:"படிபஂபு",
      "Degree":"படிபஂபு",
      Rasi:"ராசி",
      District:"மாவட்டம்",
      Country: "நாடு",
      State:"மாநிலம்",
      Profile:"சுயவிவரம்",
      New:"புதிய",
      Members:"உறுப்பினர்கள்",
      From: "முதலஂ",
      To: "வரை",
      "Looking For" : "தேடுமஂ வரனஂ",
      Name: "பெயர்",
      Search :"தேடு",
      Gender :"பாலினம்",
      profile:"ப்ரொபைல்",
      "User ID" :"உறுப்பினர் எண்",
      "Marital Status":"திருமண நிலை",
      "Body Type":"உடல் நிலை",
      "Physical Status":"உடல் அமைபஂபு",
      "Skin Colour":"நிறம்",
      "Personal Details":"தனிப்பட்ட விவரங்கள்",
     "Religious, Education and Job Details": "மதம், படிப்பு மற்றும் தொழில் விவரங்கள்",
     Kuladeivam:"குலதெய்வம்",
     "Employed In" : "பணி",
     "Work Place":"பணியிடம்",
     "Location Details":"இருப்பிட விவரங்கள்",
      "Astro Details": "ஜாதக விவரங்கள்",
      Lagnam:"லக்னம்",
      "Basic Details":"அடிப்படை விவரங்கள்",
      "Profile Created By":"பதிவு செய்தவர்",
      Height:"உயரம்",
      Weight:"எடை",
      "Sub Caste":"துணை சாதி",
      "Sub Sect":"துணை சாதி",
      "Sub Caste Type":"துணை சாதி வகை",
      Course:"படிப்பு விவரங்கள்",
      Place: "இடம்",
      Email :"மின்னஞ்சல்",
      Username: "பெயர்",
      Register:"பதிவு செய்க",
      Password:"கடவுச்சொல்",
      "Confirm Password" : "கடவுச்சொல்லை உறுதிப்படுத்து",
      Male: "ஆண்",
      Female:"பெண்",
      "Mother Tongue":"தாய்மொழி",
      "Blood Group":"இரத்த வகை",
      Next : "அடுத்து",
      Caste :"சாதி",
      Religion : "மதம்",
      "Languages Known":"தெரிந்த மொழிகள்",
      "Work Details" :"பணி விவரங்கள்",
      "Professional Details" : "படிப்பு மற்றும் தொழில் விவரங்கள்",
      "Monthly Income": "மாத வருமானம்",
      // Course : "படிப்பு விவரங்கள்",
      "Father Name" : "தந்தை பெயர்",
      "Father Status":"தந்தை நிலை",
      "Mother Name":"தாய் பெயர்",
      "Mother Status": "தாய் நிலை",
      "Social Type":"சமூக நிலை",
      Native : "சொந்த ஊர் / பூர்வீகம்",
      Siblings  : "உடன் பிறந்தோர்",
      "Assets Details" :"சொத்து மற்றும் பவுன் விவரங்கள்",
      "Assets Value"  :"சொத்துக்கள் மதிப்பு",
      "Asset Details" : "சொத்து விபரம் (பூமி வீடு வாடகை)",
      Seimurai : "செய்முறை (பவுன், கார் விவரம்)",
      Skip  : "தவிர்",
      Patham  : "பதம்",
      Nakshatra  : "நட்சத்திரம்" , 
      "Time of Birth" : "பிறந்த நேரம்",
      "Date of Birth" : "பிறந்த தேதி",
      "Place of Birth" : "பிறந்த இடம்",
      "Birth Dasa":"பிறந்த தசா",
      Jathagam : "ஜாதகம்",
      "Matching Nakshatras":"பொருந்தும் நட்சத்திரங்கள்",
      Expectation: "எதிர்பார்ப்பு",
      Unmarried : "முதல் மணம்", 
      Tamil  :"தமிழ்",
      English : "ஆங்கிலம்",
      Telugu : "தெலுங்கு",
      UnMarried : "முதல் மணம்",
      Divorced: "விவாகரத்து ஆனவர்",
      Widow : "விதவை",
      "Awaiting Divorce":"விவாகரத்துக்காக காத்திருப்பவர்",
      Self : "நான்",
      Parent : "பெற்றோர்",
      Sister : "சகோதரி",
      Brother : "சகோதரன்",
      Relative : "உறவினர்",
      Friend : "நண்பர்",
      // Normal : "சாதாரணம்",
      "Physically Challenged": "மாற்றுத்திறனாளி",
      Thin : "மெல்லிய உடல் அமைப்பு",
      Fat : "பருமனான உடல் அமைப்பு",
      Normal : "சராசரி உடல் அமைப்பு",
      "Slightly Fat" : "லேசான பருமனான உடல் அமைப்பு",
      "Slightly Thin" : "லேசான மெல்லிய உடல் அமைப்பு",
      Fair : "சிகப்பு",
      White : "வெள்ளை",
      "Wheatish Brown" : "மாநிறம்",
      Dark : "கருப்பு",
      Dusky : "டஸ்கி",
      Kootam : "கூட்டம்",
      Kulam : "குலம்",
      Gothram : "கோத்திரம்",
      Others : "மற்றவை",
      Unknown : "தெரியவில்லை",
      Muniyappan : "முனியப்பன்",
      Karuparayan : "கருப்பராயன்",
      Angaalamman : "அங்காளம்மன்",
      Periyaandichi : "பெரியாண்டிச்சி",
      Kannimar : "கன்னிமார்",
      "No Education" :"படிக்கவில்லை",
      "Below 10th" :"உயர்நிலைக்கு கீழ்",
      "10th" :"உயர்நிலை (10th)",
      "12th":"மேல்நிலை (12th)",
      Diploma:"டிப்லமோ",
      or :"அல்லது",
      "Under Graduate": "இளநிலை பட்டபடிப்பு",
      "Post Graduate" : "மேல்நிலை பட்டபடிப்பு",
      Doctor:"மருத்துவம்",
      Medical : "மருத்துவ சார்ந்த படிப்புகள்",
      Engineering : "பொறியியல் படிப்பு",
      Agriculture: "விவசாயம்",
      "Government Job":"அரசு வேலை",
      Software : "மென்பொருள்",
      Entrepreneur : "சுயதொழில்",      
      "Private Job" :"தனியார் வேலை",
      "Not at Work":"பணியில் இல்லை",
      "Still Studying" : "படித்து கொண்டிருக்கிறேன்",
      India: "இந்தியா",
      "Tamil Nadu":"தமிழ்நாடு",
      Delhi : "டெல்லி",
      Puducherry: "புதுச்சேரி",
      Ariyalur : "அரியலூர்",
      Chengalpattu : "செங்கல்பட்டு",
      Chennai  :"சென்னை",
      Coimbatore : "கோயம்புத்தூர்",
      Cuddalore : "கடலூர்",
      Dharmapuri : "தர்மபுரி",
      Dindigul : "திண்டுக்கல்",
      Erode : "ஈரோடு",
      Kallakurichi:"கள்ளக்குறிச்சி",
      Kanchipuram :"காஞ்சிபுரம்",
      Kanyakumari :"நாகர்கோயில்",
      Karur:"கரூர்",
      Krishnagiri :"கிருஷ்ணகிரி",
      Madurai :"மதுரை",
      Nagapattinam :"நாகப்பட்டினம்",
      Nilgiris :"உதகமண்டலம்",
      Perambalur :"பெரம்பலூர்",
      Pudukkottai :"புதுக்கோட்டை",
      Ramanathapuram:"ராமநாதபுரம்",
      Salem :"சேலம்",
      Sivaganga :"சிவகங்கை",
      Tenkasi:"தென்காசி",
      Thanjavur:"தஞ்சாவூர்",
      Theni:"தேனி",
      Thoothukudi:"தூத்துக்குடி",
      Tiruchirappalli:"திருச்சி",
      Tirunelveli:"திருநெல்வேலி",
      Tirupathur :"திருப்பத்தூர்",
      Tiruppur:"திருப்பூர்",
      Tiruvallur:"திருவள்ளூர்",
      Tiruvannamalai:"திருவண்ணாமலை",
      Tiruvarur:"திருவாரூர்",
      Vellore:"வேலூர்",
      Viluppuram:"விழுப்புரம்",
      Kerala : "கேரளா",
      Virudhunagar:"விருதுநகர்",
      Mayiladuthurai:"மயிலாடுதுறை",
      Telangana:"தெலுங்கானா",
      Namakkal:"நாமக்கல்",
      Alive :"உண்டு",
      "Family Details":"குடும்ப விவரங்கள்",
      Late:"லேட்",
      "Don't Know":"தெரியவில்லை",
      "Diploma or ITI":"பட்டயப்படிப்பு (Diploma or ITI)",
      Lakhs :"லட்சம்",
      and:"மற்றும்",
      Above : "மேலே",
      "Lower Middle Class":"வசதி குறைவு",
      "Middle Class" : "நடுத்தரம்",
      "V.I.P" :"வி.ஐ.பி",
      "V.V.I.P":"வி.வி.ஐ.பி",
      Royal : "ராயல்",
      Aries:"மேஷம்",
      "Aries-Mesham":"மேஷம்",
      Taurus:"ரிஷபம்",
      "Taurus-Risabham":"ரிஷபம்",
      Gemini :"மிதுனம்",
      "Gemini-Mithunam":"மிதுனம்",
      Cancer :"கடகம்",
      "Cancer-Kadagam" :"கடகம்",
      Leo :"சிம்மம்",
      "Leo-Simmam" :"சிம்மம்",
      Virgo :"கன்னி",
      "Virgo-Kanni":"கன்னி",
      Libra :"துலாம்",
      "Libra-Thulam":"துலாம்",
      Scorpio :"விருச்சிகம்",
      "Scorpio-Viruchigam" :"விருச்சிகம்",
      Sagittarius :"தனுசு",
      "Sagittarius-Dhanushu" :"தனுசு",
      Capricorn :"மகரம்",
      "Capricorn-Magaram" :"மகரம்",
      Aquarius :"கும்பம்",
      "Aquarius-Kumbham" :"கும்பம்",
      Pisces:"மீனம்",
      "Pisces-Meenam":"மீனம்",
      padham :"பாதம்",
      Ashwini:"அஸ்வினி",
      Bharani :"பரணி",
      Krithigai:"கிருத்திகை",
      Rohini :"ரோகிணி",
      Mirugasirisham :"மிருகசீரிஷம்",
      Thiruvathirai:"திருவாதிரை",
      Punarpoosam :"புனர்பூசம்",
      Poosam :"பூசம்",
      Ayilyam :"ஆயில்யம்",
      Magam :"மகம்",
      Pooram :"பூரம்",
      Uthiram:"உத்திரம்",
      Hashtham:"ஹஷ்தம்",
      Chithirai :"சித்திரை",
      Swathi :"சுவாதி",
      Visakam :"விசாகம்",
      Anusham :"அனுஷம்",
      Moolam:"மூலம்",
      Pooradam :"",

      Matrimony :"திருமண தகவல் மையம்",
      Home : "முகப்பு",      
      MyProfile : "என் சுயவிவரம்",
      ViewProfiles : "மற்ற சுயவிவரம்",
      Logout : "வெளியேறு",
      Login : "உள்நுழைய",
      Events : "நிகழ்ச்சி",
      // Register : "பதிவு செய்யுங்கள்",
      "Do not have an Account" : "கணக்கு இல்லையா ?",




      
      







    

















    }
  }
};

// Initialize i18n
i18n
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources, // Set language resources
    lng: "en", // Default language
    fallbackLng: "en", // Fallback language if a translation is missing
    interpolation: {
      escapeValue: false // React already escapes values
    }
  });

  


export default i18n;
