import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Male: [],
    Female: [],
    
}

export const getAllSlice = createSlice({
    name: 'getAllInfo',
    initialState,
    reducers:{
        setMale: (state,action)=>{
            state.Male = action.payload;
        },
        setFemale: (state,action)=>{
            state.Female = action.payload;
        },
    }
});

export const {setMale,setFemale}=getAllSlice.actions;
export default getAllSlice.reducer;

