import React from "react";

export default function Recovered() {
  return (
    <div>
      <section className="vh-100">
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                className="img-fluid"
                alt="Sample "
              />
            </div>
            <div className="col-md-7 col-lg-6 col-xl-4 offset-xl-1">
              <form>
                <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                  <h1 className="h4 mb-4 fw-bold">Password successfully set</h1>
                </div>

                <div className="d-flex align-items-center my-4">
                  <hr className="flex-fill border border-dark" />
                  <h2 className="mx-2">Welcome HOME</h2>
                  <hr className="flex-fill border border-dark" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
