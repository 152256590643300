import React, { useEffect } from 'react';
import marriage from "../assets/mrg1.jpeg";
import axios from 'axios';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import girl from '../assets/girl.jpg';
// import boy from '../assets/boy.jpg'
import { useDispatch, useSelector } from 'react-redux';
import {setFemale, setMale} from '../slices/getAllSlice';
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// import i18n from '../i18n';
import "../../src/App.css";

const cardStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '220px',
  height: '350px',
  textAlign: 'center',
};

const ImageStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  objectFit: 'cover', 
  width: '24rem', 
  height: '23rem'
};


const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        style={{
          ...style,
          // display: "block",
          left: "10px",
          zIndex: 1000,
          fontSize: "24px",
          backgroundColor: "#dc3545",
          color: "white",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          lineHeight: "40px",
          textAlign: "center"
        }}
        onClick={onClick}
      />
    );
  };
  
  // Custom Next Arrow
  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        style={{
          ...style,
          // display: "block",
          right: "10px",
          zIndex: 1000,
          fontSize: "24px",
          backgroundColor: "#dc3545",
          color: "white",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          lineHeight: "40px",
          textAlign: "center"
        }}
        onClick={onClick}
      />
    );
  };

const Home = () => {

    const {Male,Female}=useSelector(state=>state.getAllInfo);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4, // Show 4 slides by default on large screens
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow />,
      responsive: [
        {
          breakpoint: 1024, // For screens 1024px and below
          settings: {
            slidesToShow: 3, // Show 3 slides
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600, // For screens 600px and below
          settings: {
            slidesToShow: 2, // Show 2 slides
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480, // For screens 480px and below
          settings: {
            slidesToShow: 1, // Show 1 slide
            slidesToScroll: 1
          }
        }
      ]
    };
    
    
    
      useEffect(() => {
        const fetchFemaleData = async () => {
          try {
            const response = await axios.get('http://localhost:8000/GetTop10FemaleDetails');
            console.log('Data fetched:', response.data);
            dispatch(setFemale(response.data))           
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchFemaleData();
      }, [dispatch]);

      const FemaleLists = Female && Female.length > 0 ? Female : [];

      useEffect(() => {
        const fetchMaleData = async () => {
          try {
            const response = await axios.get('http://localhost:8000/GetTop10MaleDetails');
            console.log('Data fetched:', response.data);
            dispatch(setMale(response.data))
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchMaleData();
      }, [dispatch]);

      const MaleLists = Male && Male.length > 0 ? Male : [];

      const handleProfiledetails = (user)=>{
        navigate('/userDetail',{state:{user}});
      }
  
    
  return (
    <>
    <Navbar/>
       <div
        className="personal-details  col-md-12"
        style={{
          backgroundImage: `url(${marriage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height:"350px"
        }}
      >
      </div>
      <div className='py-5'>
      <h2 className='text-center' >{t('New')} {t('Members')}</h2>
      </div>
      <div>
          {Array.isArray(FemaleLists) && FemaleLists.length > 0 ? (
          <Slider {...settings}>
            {FemaleLists.map((user, index) => (
              <div key={user.ID || index} className="card mycard mx-2" style={cardStyle}>
                <div className="card-body text-center">
                  <img
                    src={`data:image/jpeg;base64,${user.Profile_Image}`}
                    width='100'
                    height='100'
                    alt="Marriage"
                    className="img-fluid rounded"
                    style={ImageStyle}
                  />
                  <h1 className="card-title" style={{ fontSize: '28px' }}>
                    <span style={{ color: "red" }}>{user.Name}</span>
                  </h1>
                  <p className='card-text' >{`Age: ${user.Age}`}</p>
                  <p className='card-text'>{`Education: ${user.Education}`}</p>
                  <p className='card-text'>{`Rasi: ${user.Rasi}`}</p>
                  <p className='card-text'>{`District: ${user.District}`}</p>
                  <button className='btn btn-danger mt-2' onClick={() => handleProfiledetails(user)}>Profile</button>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <p>No user profiles available.</p>
        )}

        <div className='my-5'>
            {Array.isArray(MaleLists) && MaleLists.length > 0 ? (
          <Slider {...settings}>
            {MaleLists.map((user) => (
              <div key={user.ID} className="card mycard mx-2 " style={cardStyle}>
                <div className="card-body text-center">
                  <img src={`data:image/jpeg;base64,${user.Profile_Image}`} alt="Marriage" className="img-fluid rounded"  style={ImageStyle}/>
                  <h1 className="card-title" style={{ fontSize: '28px' }}> <span style={{ color: "red" }}>{user.Name}</span></h1>
                  <p className='card-text' >{t('Age')}: {user.Age}</p>
                  <p className='card-text'>{t('Education')}: {user.Education}</p>
                  <p className='card-text'>{t('Rasi')}:  {user.Rasi}</p>
                  {/* <p className='card-text'>Jathagam: {user.Jathagam}</p> */}
                  <p className='card-text'>{t('District')}: {user.District}</p>
                  <button className='btn btn-danger mt-2' onClick={() => handleProfiledetails(user)}>{t('Profile')}</button>
                </div>
              </div>
            ))}
          </Slider>
        ) :("")}
        </div>
      </div>
      <div>
        
      </div>
      </> )
}

export default Home;