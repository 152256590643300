import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../slices/loginSlice';
import { useNavigate } from 'react-router-dom';
import { setSignupEmail } from '../slices/signupSlice';
import {setNakshatra} from '../slices/astroSlice'

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logout());
    dispatch(setSignupEmail(""));
    dispatch(setNakshatra(""));
    
    navigate('/login');
  }, [dispatch, navigate]);

  return (
    <div>
        <h6>Wait for Logout...........</h6>
    </div>
  );
};

export default Logout;
