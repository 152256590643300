import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Name: "",
    Age: "",
    Gender:"",
    Mother_Tongue: "",
    Marital_Status: "",
    Profile_Created_By:"",
    Profile_Image: null,
}

export const basicSlice = createSlice({
    name: 'basicInfo',
    initialState,
    reducers:{
        setName: (state,action)=>{
            state.Name = action.payload;
        },
        setAge: (state,action)=>{
            state.Age = action.payload;
        },
        setGender:(state,action)=>{
           state.Gender = action.payload;
        },
        setMother_Tongue: (state,action)=>{
            state.Mother_Tongue = action.payload;
        },
        setMarital_Status: (state,action)=>{
            state.Marital_Status = action.payload;
        },
        setProfile_Created_By: (state,action)=>{
            state.Profile_Created_By = action.payload;
        },
        setProfile_Image: (state, action) => {
            state.Profile_Image = action.payload;  // Add the image to the state
          },
    }
});

export const {setName,setAge,setMother_Tongue,setMarital_Status,setProfile_Created_By,setGender,setProfile_Image }=basicSlice.actions;
export default basicSlice.reducer;