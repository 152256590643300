import React, {useState } from "react";
import marriage from "../assets/mrg1.jpeg";
import axios from "axios";
import { useSelector,useDispatch} from "react-redux";
import { setRasi,setNakshatra,setDOB,setLagnam,setPlace_of_Birth,setTime_of_Birth,setBirth_Dasa } from "../slices/astroSlice";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { useTranslation } from "react-i18next";

const AstroDetails = () => {
  const {Rasi,Lagnam,DOB,Nakshatra,Place_of_Birth,Time_of_Birth,Birth_Dasa}=useSelector(state=>state.astroInfo)
  const [errors, setErrors] = useState({});
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const {t}= useTranslation();


  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});

    const newErrors = {};

    if (!Rasi || Rasi === "select") {
      newErrors.Rasi = "Rasi is required.";
    }

    if (!Nakshatra || Nakshatra === "select") {
      newErrors.Nakshatra = "Nakshatra is required";
    }
   
    if (!Lagnam || Lagnam === "select") {
      newErrors.Lagnam = "Lagnam is required";
    }
    if (!DOB) {
      newErrors.DOB = "DOB is required";
    }
    if (!Place_of_Birth) {
      newErrors.Place_of_Birth = "Place of Birth is required";
    }
    if (!Time_of_Birth) {
      newErrors.Time_of_Birth = "Time of Birth is required";
    }

    if (!Birth_Dasa || Birth_Dasa === "select") {
      newErrors.Birth_Dasa = "Birth Dasa is required";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }      try{
      const payload = {
        Rasi, 
        Nakshatra, 
        Lagnam, 
        DOB, 
        Place_of_Birth, 
        Time_of_Birth, 
        Birth_Dasa  
      }
      const response = await axios.post('http://localhost:8000/astrodetails',payload);  
      sessionStorage.setItem("Nakshatra" , Nakshatra);
      sessionStorage.setItem("Rasi",Rasi);
      console.log("Response: ",response.data);
      console.log("Nakhsatra detail", Nakshatra);
      
      navigate("/expectation");
      setErrors({});
      dispatch(setRasi(""));
      dispatch(setNakshatra(""));
     
      }catch(error) {
      console.error('Error submitting form', error);
      alert('Form submission failed');
    } 
    dispatch(setLagnam(""));
    dispatch(setDOB(""));
    dispatch(setPlace_of_Birth(""));
    dispatch(setTime_of_Birth(""));   
    dispatch(setBirth_Dasa(""));
  };
  return (
    <>
    <Navbar/>
      <div
        className="personal-details vh-100 col-md-12"
        style={{
          backgroundImage: `url(${marriage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <form className="form p-5" onSubmit={handleSubmit}>
          <h4 className="heading bg-danger mb-4">{t('Astro Details')}</h4>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Rasi" className="w-100">
              {t('Rasi')}
            </label>
            <select
              name="Rasi"
              id="Rasi"
              className="w-100 p-1"
              value={Rasi}
              onChange={(e) =>   dispatch(setRasi(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Aries">{t('Aries-Mesham')}</option>
              <option value="Taurus">{t('Taurus-Risabham')}</option>
              <option value="Gemini">{t('Gemini-Mithunam')}</option>
              <option value="Cancer">{t('Cancer-Kadagam')}</option>
              <option value="Leo">{t('Leo-Simmam')}</option>
              <option value="Virgo">{t('Virgo-Kanni')}</option>
              <option value="Libra">{t('Libra-Thulam')}</option>
              <option value="Scorpio">{t('Scorpio-Viruchigam')}</option>
              <option value="Sagittarius">{t('Sagittarius-Dhanushu')}</option>
              <option value="Capricorn">{t('Capricorn-Magaram')}</option>
              <option value="Aquarius">{t('Aquarius-Kumbham')}</option>
              <option value="Pisces">{t('Pisces-Meenam')}</option>
              <option value="dontknow">{t("Don't Know")}</option>
            </select>
            {errors.Rasi && <div style={{ color: "red" }}>{errors.Rasi}</div>}
          </div>
          {Rasi === "Aries" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
                {t('Nakshatra')}
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) =>   dispatch(setNakshatra(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Ashwini(1-4 padham)">{t('Ashwini')} 1-4 {t('padham')}</option>
                <option value="Bharani(1-4 padham)">{t('Bharani')} 1-4 {t('padham')}</option>
                <option value="Krithigai 1st padham">{t('Krithigai')} 1st {t('padham')}</option>
              </select>
              {errors.Nakshatra && (
                <div style={{ color: "red" }}>{errors.Nakshatra}</div>
              )}
            </div>
          )}
          {Rasi === "Taurus" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
              {t('Nakshatra')}
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) =>   dispatch(setNakshatra(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Krithigai(2-4 padham)">{t('Krithigai')} 2-4 {t('padham')}</option>
                <option value="Rohini(1-4 padham)">{t('Rohini')} 1-4 {t('padham')}</option>
                <option value="Mirugasrisham(1-2 padham)">{t('Mirugasirisham')} 1-2 {t('padham')}</option>
              </select>
              {errors.Nakshatra && (
                <div style={{ color: "red" }}>{errors.Nakshatra}</div>
              )}
            </div>
          )}

          {Rasi === "Gemini" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
              {t('Nakshatra')}
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) =>   dispatch(setNakshatra(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Mirugasrisham(3-4 padham)">{t('Mirugasirisham')} 3-4 {t('padham')}</option>
                <option value="Thiruvathirai(1-4 padham)">{t('Thiruvathirai')} 1-4 {t('padham')}</option>
                <option value="Punarpoosam(1-3 padham)">{t('Punarpoosam')} 1-3 {t('padham')}</option>
              </select>
              {errors.Nakshatra && (
                <div style={{ color: "red" }}>{errors.Nakshatra}</div>
              )}
            </div>
          )}

          {Rasi === "Cancer" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
              {t('Nakshatra')}
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) =>   dispatch(setNakshatra(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Punarpoosam 4th padham">{t('Punarpoosam')} 4th {t('padham')}</option>
                <option value="Poosam(1-4 padham)">{t('Poosam')} 1-4 {t('padham')}</option>
                <option value="Ayilyam(1-4 padham)">{t('Ayilyam')} 1-4 {t('padham')}</option>
              </select>
              {errors.Nakshatra && (
                <div style={{ color: "red" }}>{errors.Nakshatra}</div>
              )}
            </div>
          )}
          {Rasi === "Leo" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
              {t('Nakshatra')}
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) =>   dispatch(setNakshatra(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Magam(1-4 padham)">{t('Magam')} 1-4 {t('padham')}</option>
                <option value="Pooram(1-4 padham)">{t('Pooram')} 1-4 {t('padham')}</option>
                <option value="Uthiram 1st padham">{t('Uthiram')} 1st {t('padham')}</option>
              </select>
              {errors.Nakshatra && (
                <div style={{ color: "red" }}>{errors.Nakshatra}</div>
              )}
            </div>
          )}
          {Rasi === "Virgo" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
              {t('Nakshatra')}
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) =>   dispatch(setNakshatra(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Uthiram(2-4 padham)">{t('Uthiram')} 2-4 {t('padham')}</option>
                <option value="Hashtham(1-4 padham)">{t('Hashtham')} 1-4 {t('padham')}</option>
                <option value="Chithirai(1-2 padham)">{t('Chithirai')} 1-2 {t('padham')}</option>
              </select>
              {errors.Nakshatra && (
                <div style={{ color: "red" }}>{errors.Nakshatra}</div>
              )}
            </div>
          )}

          {Rasi === "Libra" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
          {t('Nakshatra')}

              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) =>   dispatch(setNakshatra(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Chithirai(3-4 padham)">{t('Chithirai')} 3-4 {t('padham')}</option>
                <option value="Swathi(1-4 padham)">{t('Swathi')} 1-4 {t('padham')}</option>
                <option value="Visakam(1-3 padham)">{t('Visakam')} 1-3 {t('padham')}</option>
              </select>
              {errors.Nakshatra && (
                <div style={{ color: "red" }}>{errors.Nakshatra}</div>
              )}
            </div>
          )}
          {Rasi === "Scorpio" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
              {t('Nakshatra')}
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) =>   dispatch(setNakshatra(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Visakam 4th padam">{t('Visakam')} 4th {t('padham')}</option>
                <option value="Anusham(1-4 padham)">{t('Anusham')} 1-4 {t('padham')}</option>
                <option
                  value="Kettai(1-4 padham)"
                >
                  Kettai 1-4 padham
                </option>
              </select>
              {errors.Nakshatra && (
                <div style={{ color: "red" }}>{errors.Nakshatra}</div>
              )}
            </div>
          )}

          {Rasi === "Sagittarius" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
              {t('Nakshatra')}
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) =>   dispatch(setNakshatra(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Moolam(1-4 padham)">{t('Moolam')} 1-4 {t('padham')}</option>
                <option value="Pooradam(1-4 padham)">{t('Pooradam')} 1-4 {t('padham')}</option>
                <option value="Uthiradam 1st padham">{t('Uthiradam')} 1st {t('padham')}</option>
              </select>
              {errors.Nakshatra && (
                <div style={{ color: "red" }}>{errors.Nakshatra}</div>
              )}
            </div>
          )}
          {Rasi === "Capricorn" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
              {t('Nakshatra')}
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"   
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) =>   dispatch(setNakshatra(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Uthiradam(2-4 padham)">{t('Uthiradam')} 2-4 {t('padham')}</option>
                <option value="Thiruvonam(1-4 padham)">{t('Thiruvonam')} 1-4 {t('padham')}</option>
                <option value="Avittam(1-2 padham)">{t('Avittam')} 1-2 {t('padham')}</option>
              </select>
              {errors.Nakshatra && (
                <div style={{ color: "red" }}>{errors.Nakshatra}</div>
              )}
            </div>
          )}
          {Rasi === "Aquarius" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
              {t('Nakshatra')}
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) =>   dispatch(setNakshatra(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Avittam(3-4 padham)">{t('Avittam')} 3-4 {t('padham')}</option>
                <option value="Sathayam(1-4 padham)">{t('Sathayam')} 1-4 {t('padham')}</option>
                <option value="Poorattadhi(1-3 padham)">{t('Poorattadhi')} 1-3 {t('padham')}</option>
              </select>
              {errors.Nakshatra && (
                <div style={{ color: "red" }}>{errors.Nakshatra}</div>
              )}
            </div>
          )}
          {Rasi === "Pisces" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
              {t('Nakshatra')}
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) =>   dispatch(setNakshatra(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="Uthiratadhi(1-4 padham)">{t('Uthiratadhi')} 1-4 {t('padham')}</option>
                <option value="Poorattadhi 4th padham">{t('Poorattadhi')} 4th {t('padham')}</option>
                <option value="Revathi(1-4 padham)">{t('Revathi')} 1-4 {t('padham')}</option>
              </select>
              {errors.Nakshatra && (
                <div style={{ color: "red" }}>{errors.Nakshatra}</div>
              )}
            </div>
          )}

          {Rasi === "dontknow" && (
            <div className="form-group mb-3 col-xs-1">
              <label htmlFor="Nakshatra" className="w-100">
              {t('Nakshatra')}
              </label>
              <select
                name="Nakshatra"
                id="Nakshatra"
                className="w-100 p-1"
                value={Nakshatra}
                onChange={(e) =>   dispatch(setNakshatra(e.target.value))}
                required
              >
                <option value="select">Select--</option>
                <option value="none">{t('None')}</option>
              </select>
              {errors.Nakshatra && (
                <div style={{ color: "red" }}>{errors.Nakshatra}</div>
              )}
            </div>
          )}

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Lagnam" className="w-100">
              {t('Lagnam')}
            </label>
            <select
              name="Lagnam"
              id="Lagnam"
              className="w-100 p-1"
              value={Lagnam}
              onChange={(e) =>   dispatch(setLagnam(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Mesham(Aries)">Mesham (Aries)</option>
              <option value="Rishabam(Taurus)">Rishabam (Taurus)</option>
              <option value="Mithunam(Gemini)">Mithunam (Gemini)</option>
              <option value="Kadagam(Cancer)">Kadagam (Cancer)</option>
              <option value="Simmam(Leo)">Simmam (Leo)</option>
              <option value="Kanni(Virgo)">Kanni (Virgo)</option>
              <option value="Thulam(Libra)">Thulam (Libra)</option>
              <option value="Viruchigam(Scorpio)">Viruchigam (Scorpio)</option>
              <option value="Dhanushu(Saggitarius)">
                Dhanushu (Saggitarius)
              </option>
              <option value="Magaram(Capricorn)">Magaram (Capricorn)</option>
              <option value="Kumbam(Aquarius)">Kumbam (Aquarius)</option>
              <option value="Meenam(Pisces)">Meenam (Pisces)</option>
              <option value="dontknow">Don't Know</option>
            </select>
            {errors.Lagnam && (
              <div style={{ color: "red" }}>{errors.Lagnam}</div>
            )}
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="DOB" className="w-100">
              {t('Date of Birth')}
            </label>
            <input
              type="date"
              className="w-100"
              name="DOB"
              id="DOB"
              value={DOB}
              onChange={(e) =>   dispatch(setDOB(e.target.value))}
            />
            {errors.DOB && <div style={{ color: "red" }}>{errors.DOB}</div>}
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Place_of_Birth" className="w-100">
              {t('Place of Birth')}
            </label>
            <input
              type="text"
              className="w-100"
              name="Place_of_Birth"
              id="Place_of_Birth"
              placeholder="Place of Birth"
              value={Place_of_Birth}
              onChange={(e) =>   dispatch(setPlace_of_Birth(e.target.value))}
            />
            {errors.Place_of_Birth && (
              <div style={{ color: "red" }}>{errors.Place_of_Birth}</div>
            )}
          </div>
          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Time_of_Birth" className="w-100">
              {t('Time of Birth')} 
            </label>
            <input
              type="time"
              className="w-100"
              name="Time_of_Birth"
              id="Time_of_Birth"
              placeholder="HH:MM"
              value={Time_of_Birth}
              onChange={(e) =>   dispatch(setTime_of_Birth(e.target.value))}
            />
            {errors.Time_of_Birth && (
              <div style={{ color: "red" }}>{errors.Time_of_Birth}</div>
            )}
          </div>

          <div className="form-group mb-3 col-xs-1">
            <label htmlFor="Birth_Dasa" className="w-100">
              {t('Birth Dasa')}
            </label>
            <select
              name="Birth_Dasa"
              id="Birth_Dasa"
              className="w-100 p-1"
              value={Birth_Dasa}
              onChange={(e) =>   dispatch(setBirth_Dasa(e.target.value))}
              required
            >
              <option value="select">Select--</option>
              <option value="Suryan">Suryan</option>
              <option value="Chandran">Chandran</option>
              <option value="Sevvai">Sevvai</option>
              <option value="Budhan">Budhan</option>
              <option value="Guru">Guru</option>
              <option value="Shukran">Shukran</option>
              <option value="Shani">Shani</option>
              <option value="Raghu"> Raghu</option>
              <option value="Kedhu">Kedhu</option>
            </select>
            {errors.Birth_Dasa && (
              <div style={{ color: "red" }}>{errors.Birth_Dasa}</div>
            )}
          </div>
          <button
            type="submit"
            name="submit-btn"
            className="btn btn-danger w-100"
          >
            {t('Next')}
          </button>
        </form>
      </div>
    </>
  );
};

export default AstroDetails;
