import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Asset_Value: "",
    Asset_Detail: "",
    Seimurai: "",
  
}

export const assetSlice = createSlice({
    name: 'assetInfo',
    initialState,
    reducers:{
        
        setAssetDetail: (state,action)=>{
            state.Asset_Detail = action.payload;
        },
        setAssetValue: (state,action)=>{
            state.Asset_Value = action.payload;
        },
        setSeimurai:(state,action)=>{
           state.Seimurai = action.payload;
        },
    }
});

export const {setAssetDetail,setAssetValue,setSeimurai}=assetSlice.actions;
export default assetSlice.reducer;

