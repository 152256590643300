import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ImageDisplay = ({ id }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the image from the API
    axios.get(`http://localhost:8000/api/getImage/3`)
      .then(response => {
        setImageSrc(response.data.image); // Set the base64 image string
      })
      .catch(error => {
        setError('Error fetching the image');
        console.error(error);
      });
  }, [id]);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      {imageSrc ? (
        <img src={imageSrc} alt="Fetched from DB" />
      ) : (
        <p>Loading image...</p>
      )}
    </div>
  );
};

export default ImageDisplay;
