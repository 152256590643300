import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import boy from '../assets/boy.jpg';
import marriage from "../assets/mrg1.jpeg";
import Navbar from './Navbar';
import { useTranslation } from 'react-i18next';

const SearchProfileDetails = () => {
  const location = useLocation();
  const { user, searchResults } = location.state || {}; 
  console.log("user",user);
  console.log("search results",searchResults)
  // const { id } = useParams();
  const navigate = useNavigate();
  const {t}=useTranslation();


  if (!user) {
    return <p>No user data found.</p>;
  }

  return (
    <div>
      <Navbar/>
      <div
        className="col-md-12"
        style={{
          backgroundImage: `url(${marriage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
<button onClick={() => navigate('/search', { state: { searchResults: searchResults } })}>Back to Search</button>
<div className="card-container myprofileCards">
          <div  className="card myprofileCard" style={{height:'fit-content'}}>
            <img className="card-img-top" src={boy} alt="Card" />
            <div className="card-body">
              <p>
                <b className='contents' >{user.Name} {t('profile')}</b>
              </p>
              
            </div>
          </div>

          <div  className="card myprofileDetails">
            <div className="card-body">

            <h4 className="headings bg-danger mb-4">{t('Basic Details')}</h4>

              <p>
                <b  className="labels">{t('Name')}:</b>
                <b className='contents' >{user.Name}</b>
              </p>
              <p>
                <b className="labels" >{t('Marital Status')}:</b>
                <b  className='contents'>{user.Marital_Status}</b>
              </p>
              
              <p>
                <b className="labels" >{t('Profile Created By')}:</b>
                <b  className='contents'>{user.Profile_Created_By}</b>
              </p>
              <p>
              <b className="labels" >{t('Gender')}:</b>
              <b  className='contents'> {user.Gender}</b>

              </p>
              
            </div>


            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Personal Details')}</h4>

              <p>
                <b  className="labels">{t('Height')}:</b>
                <b className='contents' >{user.Height}</b>
              </p>
              <p>
                <b className="labels" >{t('Weight')}:</b>
                <b  className='contents'>{user.Weight}</b>
              </p>
              <p>
              <b className="labels" >{t('Physical Status')}:</b>
              <b  className='contents'> {user.Physical_Status}</b>

              </p>
              
            </div>

            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Professional Details')}</h4>

              <p>
                <b  className="labels">{t('Degree')}:</b>
                <b className='contents' >{user.Education}</b>
              </p>
              <p>
                <b className="labels" >{t('Course')}:</b>
                <b  className='contents'>{user.Course}</b>
              </p>
              <p>
              <b className="labels" >{t('Physical Status')}:</b>
              <b  className='contents'> {user.Employed_In}</b>

              </p>
              
            </div>


            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Religious Details')}</h4>

              <p>
                <b  className="labels">{t('Sub Caste')}:</b>
                <b className='contents' >{user.Sub_Caste}</b>
              </p>
              <p>
                <b className="labels" >{t('Kuladeivam')}:</b>
                <b  className='contents'>{user.Kuladeivam}</b>
              </p>
              <p>
              <b className="labels" >{t('Sub Caste Type')}:</b>
              <b  className='contents'> {user.Sub_Caste_Type  }</b>

              </p>
              <p>
              <b className="labels" >{t('Place')}:</b>
              <b  className='contents'> {user.Place }</b>

              </p>
              
              
            </div>

            <div className="card-body">
            <h4 className="headings bg-danger mb-4">{t('Location Details')}</h4>

              <p>
                <b  className="labels">{t('Country')}: </b>
                <b className='contents' >{user.Country}</b>
              </p>
              <p>
                <b className="labels" >{t('State')}:</b>
                <b  className='contents'>{user.State}</b>
              </p>
              <p>
              <b className="labels" >{t('District')}:</b>
              <b  className='contents'> {user.District  }</b>

              </p>
              
            </div>


          </div>
      </div>
    </div>
    </div>
  );
};

export default SearchProfileDetails;
