import React, { useEffect } from 'react';
import Slider from 'react-slick';
// import mrg from "../assets/marriage.jpeg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import marriage from "../assets/mrg1.jpeg";
import { useSelector, useDispatch } from "react-redux";
import { setUsers } from "../slices/HomepageSlice";
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-prev-arrow`}
      style={{
        ...style,
        display: "block",
        left: "10px",
        zIndex: 1000,
        fontSize: "24px",
        backgroundColor: "#dc3545",
        color: "white",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        lineHeight: "40px",
        textAlign: "center"
      }}
      onClick={onClick}
    />
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-next-arrow`}
      style={{
        ...style,
        display: "block",
        right: "10px",
        zIndex: 1000,
        fontSize: "24px",
        backgroundColor: "#dc3545",
        color: "white",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        lineHeight: "40px",
        textAlign: "center"
      }}
      onClick={onClick}
    />
  );
};

const Homepage = () => {
  const dispatch = useDispatch();
  const { email } = useSelector(state => state.loginInfo); 
  const { users } = useSelector(state => state.HomepageInfo); 
  // const Gender = useSelector((state) => state.basicInfo.Gender);
  const { t } = useTranslation();
  const navigate = useNavigate();  

  // Retrieve signupEmail from sessionStorage
  const signupEmail = sessionStorage.getItem("signupEmail");  

    useEffect(() => {   
  
    if (!email && !signupEmail) {
      console.log("Both email and signupEmail are missing or undefined");
    }

    if (email) {
      sessionStorage.removeItem("signupEmail");
    }
  
    if ((email || signupEmail)) {
      const fetchUsers = async () => {
        try {
          const params = {
            email: signupEmail || email,
          };
  
          console.log("Request parameters:", params);
  
          const response = await axios.get('http://localhost:8000/GetMatchingStarByEmail', { params });
          console.log("API Response:", response.data);
          
          if (response.data) {
            dispatch(setUsers(response.data));
          } else {
            console.log("No data received from API");
          }
        } catch (error) {
          console.error("Error occurred while fetching users:", error);
        }
      };
      fetchUsers();
    } else {
      navigate('/login');
    }
  }, [ email, signupEmail, dispatch, navigate]);
  

  const handleProfiledetails = (user) => {
    navigate('/userDetail', { state: { user } });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <Navbar />
      <div
        className="personal-details col-md-12"
        style={{
          backgroundImage: `url(${marriage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "350px"
        }}
      ></div>
      <div className="carousel-container position-relative my-5">
        {Array.isArray(users) && users.length > 0 ? (
          users.length > 3 ? (
            <Slider {...settings}>
              {users.map((user) => (
                <div key={user.UserID} className="card mycard mx-2">
                  <div className="card-body text-center">
                    <img src={`data:image/jpeg;base64,${user.Profile_Image}`} style ={{width:'200px', height:'200px'}} alt="Marriage" className="img-fluid rounded" />
                    <h1 className="card-title" style={{ fontSize: '23px' }}><span style={{ color: "red" }}>{user.Name}</span></h1>
                    {/* <p className="card-text">{t('Email')}: {user.UserEmail}</p> */}
                    <p className='card-text'>{t('Gender')}: {user.Gender}</p>
                    <p className='card-text'>{t('Age')}: {user.Age}</p>
                    <p className='card-text'>{t('Degree')}: {user.Education}</p>
                    <p className='card-text'>{t('Rasi')}: {user.Rasi}</p>
                    <button className='btn btn-danger' onClick={() => handleProfiledetails(user)}>{t('Profile')}</button>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="row">
              {users.map((user) => (
                <div key={user.UserID} className="col-md-4 mb-4">
                  <div className="card mycard mx-2">
                    <div className="card-body text-center">
                      <img src={`data:image/jpeg;base64,${user.Profile_Image}`} style ={{width:'300px', height:'300px'}} alt="Marriage" className="img-fluid rounded" />
                      <h1 className="card-title" style={{ fontSize: '23px' }}><span style={{ color: "red" }}>{user.Name}</span></h1>
                      {/* <p className="card-text">{t('Email')}: {user.UserEmail}</p> */}
                      <p className='card-text'>{t('Gender')}: {user.Gender}</p>
                      <p className='card-text'>{t('Age')}: {user.Age}</p>
                    <p className='card-text'>{t('Degree')}: {user.Education}</p>
                    <p className='card-text'>{t('Rasi')}: {user.Rasi}</p>
                      <button className='btn btn-danger' onClick={() => handleProfiledetails(user)}>{t('Profile')}</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        ) : (
          navigate('/signup')
        )}
      </div>
    </>
  );
};

export default Homepage;






  // useEffect(() => {
  //   console.log("Nakshatra in useEffect: ", Nakshatra);
  //   console.log("Email in useEffect: ", email);
  //   console.log("Signup Email in useEffect: ", signupEmail);
  
  //   // Check if Nakshatra is present
  //   if (!Nakshatra) {
  //     console.log("Nakshatra is missing or undefined");
  //   }
  
  //   // Check if either email or signupEmail is present
  //   if (!email && !signupEmail) {
  //     console.log("Both email and signupEmail are missing or undefined");
  //   }
  
  //   // Proceed with fetching users if Nakshatra is present and at least one of email or signupEmail is available
  //   if (Nakshatra && (email || signupEmail)) {
  //     const fetchUsers = async () => {
  //       try {
  //         const params = {
  //           nakshatra: Nakshatra,
  //           email: signupEmail || email,
  //         };
  
  //         console.log("Request parameters:", params);
  
  //         const response = await axios.get('http://localhost:8000/GetMatchingNakshatraAndEmail', { params });
  //         console.log("API Response:", response.data);
          
  //         if (response.data) {
  //           dispatch(setUsers(response.data));
  //         } else {
  //           console.log("No data received from API");
  //         }
  //       } catch (error) {
  //         console.error("Error occurred while fetching users:", error);
  //       }
  //     };
  //     fetchUsers();
  //   } else {
  //     // Navigate to login if conditions are not met
  //     navigate('/login');
  //   }
  // }, [Nakshatra, email, signupEmail, dispatch, navigate]);



// useEffect(() => {
  //   if (!Nakshatra || (!email && !signupEmail)) {
  //     navigate('/login');
  //   } else {
  //     const fetchUsers = async () => {
  //       try {
  //         const params = {
  //           nakshatra: Nakshatra,
  //           email: signupEmail || email,
  //         };
  
  //         const response = await axios.get('http://localhost:8000/GetMatchingNakshatraAndEmail', { params });
  //         dispatch(setUsers(response.data));
  //       } catch (error) {
  //         console.error("Error occurred while fetching users:", error);
  //       }
  //     };
  //     fetchUsers();
  //   }
  // }, [Nakshatra, email, signupEmail, dispatch, navigate]);