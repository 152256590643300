import Login from "./Components/Login";
import SignUp from "./Components/Signup";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import BasicDetails from "./Components/Basic";
import PersonalDetails from "./Components/Personal";
import ReligiousDetails from "./Components/Religious";
import ProfessionalDetails from "./Components/Professional";
import LocationDetails from "./Components/Location";
import FamilyDetails from "./Components/Family";
import AssetDetails from "./Components/Assets";
import AstroDetails from "./Components/Astrology";
import Expectation from "./Components/Expectation";
import Homepage from "./Components/Homepage";
import UserDetail from "./Components/UserDetail";
import MyProfile from "./Components/Myprofile";
import Home from "./Components/Home";
import SearchProfileDetails from './Components/SearchProfileDetails';
// import Photos from "./Components/Photos.js"
import Images from "./Components/images.js"
import ForgotPassword  from "./Components/ForgotPassword.js";
import ResetPassword from "./Components/ResetPassword.js";


import NewLogin from "./Components/NewLogin.js";
import OTPinput from "./Components/OTPinput.js";
import Recovered from "./Components/Recovered.js";
import Reset from "./Components/Reset.js";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";

import Search from "./Components/Search";
import QuickSearch from "./Components/QuickSearch";
import AdvancedSearch from "./Components/AdvancedSearch";
import Logout from "./Components/Logout";
import LanguageSwitcher from './Components/LanguageSwitcher';
import { useState } from "react";
import { createContext } from "react";

export const RecoveryContext = createContext();
function App(){

  const [email, setEmail] = useState();
  const [otp, setOTP] = useState();

  return(
    <>
          <LanguageSwitcher />

          <RecoveryContext.Provider value={{ otp, setOTP, setEmail, email }}>
         <BrowserRouter>
         <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/signup" element={<SignUp/>}/>
              <Route path="/login" element={<Login/>}/>
              <Route path="/basicDetails" element={<BasicDetails/>} />
              <Route path="/personalDetails" element={<PersonalDetails/>} />
              <Route path="/religiousDetails" element={<ReligiousDetails/>} />
              <Route path="/professionaldetails" element={<ProfessionalDetails/>} />
              <Route path="/locationDetails" element={<LocationDetails/>} />
              <Route path="/familydetails" element={<FamilyDetails/>} />
              <Route path="/assetDetails" element={<AssetDetails/>} />
              <Route path="/astrodetails" element={<AstroDetails/>} />
              <Route path="/expectation" element={<Expectation/>} />
              <Route path="/homepage" element={<Homepage/>} />
              <Route path="/userDetail" element={<UserDetail/>}/>
              <Route path="/myProfile" element={<MyProfile/>}/>
              <Route path="/search" element={<Search/>}/>
              <Route path="/quickSearch" element={<QuickSearch/>}/>
              <Route path="/advancedSearch" element={<AdvancedSearch/>}/>
              <Route path="/searchProfileDetails/:id" element={<SearchProfileDetails/>}/>
              <Route path="/logout" element={<Logout />} />
              {/* <Route path="/Photos" element={<Photos />} /> */}
              <Route path="/images" element={<Images />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/resetPassword/:id/:token" element={<ResetPassword />}></Route>


              <Route path="/newLogin" element={<NewLogin />} />
              <Route path="/OTPinput" element={<OTPinput />} />
              <Route path="/recovered" element={<Recovered />} />
              <Route path="/reset" element={<Reset />} />

          </Routes>
          </BrowserRouter>
          </RecoveryContext.Provider>
    </>
  )
}
export default App;