import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Sub_Caste: "",
    Sub_Caste_Type: "",
    Kuladeivam: "",
    Place: "",
}

export const religiousSlice = createSlice({
    name: 'religiousInfo',
    initialState,
    reducers:{
        setSub_Caste: (state,action)=>{
            state.Sub_Caste = action.payload;
        },
        setSub_Caste_Type: (state,action)=>{
            state.Sub_Caste_Type = action.payload;
        },
        setKuladeivam:(state,action)=>{
           state.Kuladeivam = action.payload;
        },
        setPlace: (state,action)=>{
            state.Place = action.payload;
        },
    }
});

export const {setSub_Caste,setSub_Caste_Type,setKuladeivam,setPlace}=religiousSlice.actions;
export default religiousSlice.reducer;

